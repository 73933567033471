import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useQuiz } from '../../contexts/QuizContext';
import {
  PopupWrapper,
  PopupOverlay,
  PopupContent,
  PopupText,
  ButtonContainer,
  PopupButton
} from './styles';

const QuestionPopup = ({ isOpen, onClose }) => {
  const navigate = useNavigate();
  const { quizProgress } = useQuiz();

  // Lock body scroll when popup is open
  useEffect(() => {
    if (isOpen) {
      document.body.style.overflow = 'hidden';
      document.body.style.height = '100%';
    } else {
      document.body.style.overflow = 'unset';
      document.body.style.height = 'auto';
    }

    return () => {
      document.body.style.overflow = 'unset';
      document.body.style.height = 'auto';
    };
  }, [isOpen]);

  if (!isOpen) return null;

  const getMessage = () => {
    if (quizProgress?.quiz1_progress === quizProgress?.quiz1_total && quizProgress?.quiz2_progress !== 10) {
      return 'Encore 10 questions avant de voir tes premiers résultats';
    }
    return "Tes premiers résultats sont disponibles !";
  };

  const handleContinueSwipe = (e) => {
    e.stopPropagation();
    onClose();
  };

  const handleViewResults = (e) => {
    e.stopPropagation();
    navigate('/classement');
    onClose();
  };

  const message = getMessage();
  const showTwoButtons = message === "Tes premiers résultats sont disponibles !";

  return (
    <PopupWrapper isOpen={isOpen}>
      <PopupOverlay onClick={(e) => e.stopPropagation()}>
        <PopupContent onClick={(e) => e.stopPropagation()}>
          <PopupText>{message}</PopupText>
          {showTwoButtons ? (
            <ButtonContainer>
              <PopupButton
                secondary
                onClick={handleContinueSwipe}
              >
                Continuer le swipe
              </PopupButton>
              <PopupButton
                onClick={handleViewResults}
              >
                Voir mes premiers résultats
              </PopupButton>
            </ButtonContainer>
          ) : (
            <PopupButton onClick={handleContinueSwipe}>
              OK
            </PopupButton>
          )}
        </PopupContent>
      </PopupOverlay>
    </PopupWrapper>
  );
};

export default QuestionPopup;

