// components/FirstPage/index.js
import React from 'react';
import { useNavigate } from 'react-router-dom';
import useDeviceType from '../useDeviceType';  // Updated import path to match App.js structure
import Meo from '../../assets/meo.png';
import {
  Container,
  TextContainer,
  Title,
  Subtitle,
  BottomActionsContainer,
  StyledText,
  ContentWrapper,
  PrimaryButton,
  LogoImage,
  TextButton
} from './styles';

const FirstPage = () => {
  const { isMobile } = useDeviceType();  // Using the same hook as App.js
  const navigate = useNavigate();

  console.log('isMobile:', isMobile);


  
  const handleCreateAccount = () => {
    navigate('/presentation-meo');
  };

  const handleExistingAccount = () => {
    navigate('/login');
  };

  return (
    <Container>
      <ContentWrapper>
          <LogoImage src={Meo} alt="Compass Logo" />

        <TextContainer>
                  <Title $isMobile={isMobile}>
                    MEORIA
                  </Title>
                  <Subtitle $isMobile={isMobile}>
                    Le meilleur de l'orientation avec<br />
                     l'intelligence artificielle.
                  </Subtitle>
                </TextContainer>
          <PrimaryButton onClick={handleCreateAccount}>
                    C'est parti !
                  </PrimaryButton>
                  <BottomActionsContainer>
                    <StyledText>Déjà inscrit ?</StyledText>
                    <TextButton onClick={handleExistingAccount} isMobile={isMobile}>
                      Se connecter
                    </TextButton>
                  </BottomActionsContainer>
      </ContentWrapper>
    </Container>
  );
};

export default FirstPage;