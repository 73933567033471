
import React, { useState } from 'react';
import { useAuth } from '../../contexts/AuthContext';
import { useNavigate, useLocation } from 'react-router-dom';
import { useQuiz } from '../../contexts/QuizContext';
import FeatureVoting from '../FeatureVoting';
import {
  FaTiktok,
  FaInstagram,
  FaYoutube,
  FaEnvelope,
  FaRedo,
  FaSignOutAlt,
  FaArrowLeft,
  FaUserTimes,
  FaFileAlt
} from 'react-icons/fa';
import apiCall from '../../utils/api';
import {
  Container,
  ContentWrapper,
  Title,
  ReturnButton,
  SettingsSection,
  SectionTitle,
  UserInfo,
  SettingsItem,
  SettingsLabel,
  SettingsValue,
  RestartQuizButton,
  LogoutButton,
  DeleteAccountButton,
  CGUButton,
  ModalOverlay as ConfirmationModal,
  ModalContent,
  ModalButtons,
  ConfirmButton,
  CancelButton,
  WarningText,
  SocialLinksContainer,
  SocialLink,
  SupportForm,
  TextArea,
  MessageStatus
} from './styles';

const Settings = () => {
  // State management
  const { user, logout } = useAuth();
  const { resetQuiz } = useQuiz();
  const navigate = useNavigate();
  const location = useLocation();

  // Modal states
  const [showLogoutConfirmation, setShowLogoutConfirmation] = useState(false);
  const [showRestartConfirmation, setShowRestartConfirmation] = useState(false);
  const [showDeleteConfirmation, setShowDeleteConfirmation] = useState(false);

  // Support form states
  const [supportMessage, setSupportMessage] = useState('');
  const [sendingMessage, setSendingMessage] = useState(false);
  const [messageStatus, setMessageStatus] = useState({ text: '', success: true });

  // Navigation handlers
  const handleReturn = () => {
    const previousPath = location.state?.from || '/classement';
    navigate(previousPath);
  };

  // Logout handlers
  const handleLogoutClick = () => setShowLogoutConfirmation(true);
  const handleLogoutConfirm = async () => {
    try {
      await logout();
      navigate('/');
    } catch (error) {
      console.error('Logout error:', error);
      setMessageStatus({ text: 'Erreur lors de la déconnexion', success: false });
    }
    setShowLogoutConfirmation(false);
  };
  const handleLogoutCancel = () => setShowLogoutConfirmation(false);

  // Quiz restart handlers
  const handleRestartQuiz = () => setShowRestartConfirmation(true);
  const handleRestartConfirm = async () => {
    try {
      await apiCall(`${process.env.REACT_APP_BACKEND_URL}/quiz/reset-quiz/`, 'POST');
      resetQuiz();
      navigate('/quiz');
    } catch (error) {
      console.error('Quiz reset error:', error);
      setMessageStatus({ text: 'Erreur lors de la réinitialisation du quiz', success: false });
    }
    setShowRestartConfirmation(false);
  };
  const handleRestartCancel = () => setShowRestartConfirmation(false);

  // Account deletion handlers
  const handleDeleteAccount = () => setShowDeleteConfirmation(true);
  const handleDeleteConfirm = async () => {
    try {
      await apiCall(`${process.env.REACT_APP_BACKEND_URL}/auth/delete-account/`, 'DELETE');
      await logout();
      navigate('/');
    } catch (error) {
      console.error('Account deletion error:', error);
      setMessageStatus({ text: 'Erreur lors de la suppression du compte', success: false });
    }
    setShowDeleteConfirmation(false);
  };
  const handleDeleteCancel = () => setShowDeleteConfirmation(false);

  // CGU handler
  const handleViewCGU = () => window.open("https://meoria.com/CGU.pdf", '_blank');

  // Support form handler
  const handleSupportSubmit = async (e) => {
    e.preventDefault();
    if (!supportMessage.trim()) {
      setMessageStatus({ text: 'Veuillez écrire un message.', success: false });
      return;
    }

    setSendingMessage(true);
    setMessageStatus({ text: '', success: true });

    try {
      await apiCall(`${process.env.REACT_APP_BACKEND_URL}/auth/contact/support/`, 'POST', {
        message: supportMessage,
        user_email: user.email,
        user_name: user.pseudo,
      });

      setSupportMessage('');
      setMessageStatus({ text: 'Message envoyé avec succès !', success: true });
    } catch (error) {
      console.error('Support message error:', error);
      setMessageStatus({ text: 'Une erreur est survenue. Veuillez réessayer.', success: false });
    } finally {
      setSendingMessage(false);
    }
  };

  // Modal components
  const renderLogoutModal = () => (
    <ConfirmationModal isOpen={showLogoutConfirmation}>
      <ModalContent>
        <h2>Confirmation de déconnexion</h2>
        <p>Êtes-vous sûr de vouloir vous déconnecter ?</p>
        <ModalButtons>
          <ConfirmButton onClick={handleLogoutConfirm}>Confirmer</ConfirmButton>
          <CancelButton onClick={handleLogoutCancel}>Annuler</CancelButton>
        </ModalButtons>
      </ModalContent>
    </ConfirmationModal>
  );

  const renderRestartModal = () => (
    <ConfirmationModal isOpen={showRestartConfirmation}>
      <ModalContent>
        <h2>Confirmer la réinitialisation du quiz</h2>
        <p>Êtes-vous sûr de vouloir recommencer le quiz ? Toutes vos réponses précédentes seront supprimées.</p>
        <ModalButtons>
          <ConfirmButton onClick={handleRestartConfirm}>Confirmer</ConfirmButton>
          <CancelButton onClick={handleRestartCancel}>Annuler</CancelButton>
        </ModalButtons>
      </ModalContent>
    </ConfirmationModal>
  );

  const renderDeleteModal = () => (
    <ConfirmationModal isOpen={showDeleteConfirmation}>
      <ModalContent>
        <h2>Confirmer la suppression du compte</h2>
        <WarningText>
          Attention : Cette action est irréversible. Toutes vos données seront définitivement supprimées.
        </WarningText>
        <p>Êtes-vous sûr de vouloir supprimer votre compte ?</p>
        <ModalButtons>
          <ConfirmButton onClick={handleDeleteConfirm}>Confirmer la suppression</ConfirmButton>
          <CancelButton onClick={handleDeleteCancel}>Annuler</CancelButton>
        </ModalButtons>
      </ModalContent>
    </ConfirmationModal>
  );

  return (
    <Container>
      <ReturnButton onClick={handleReturn}>
        <FaArrowLeft />
      </ReturnButton>
      <Title>Paramètres</Title>

      <ContentWrapper>
        {user && (
          <>

      <SettingsSection>
        <SectionTitle>Vote pour la prochaine fonctionnalité !</SectionTitle>
        <FeatureVoting />
      </SettingsSection>
            <SettingsSection>
              <SectionTitle>Profil</SectionTitle>
              <UserInfo>
                <SettingsItem>
                  <SettingsLabel>Pseudo:</SettingsLabel>
                  <SettingsValue>{user.pseudo}</SettingsValue>
                </SettingsItem>
                <SettingsItem>
                  <SettingsLabel>Email:</SettingsLabel>
                  <SettingsValue>{user.email}</SettingsValue>
                </SettingsItem>
              </UserInfo>
            </SettingsSection>

            
            <SettingsSection>
              <SectionTitle>Votre avis est précieux!</SectionTitle>
              <SupportForm onSubmit={handleSupportSubmit}>
                <TextArea
                  value={supportMessage}
                  onChange={(e) => setSupportMessage(e.target.value)}
                  placeholder="Un bug, une recommandation de fonctionnalité, d'affichage, un avis général, une question..."
                  disabled={sendingMessage}
                />
                {messageStatus.text && (
                  <MessageStatus success={messageStatus.success}>
                    {messageStatus.text}
                  </MessageStatus>
                )}
                <ConfirmButton type="submit" disabled={sendingMessage}>
                  <FaEnvelope />
                  {sendingMessage ? 'Envoi en cours...' : 'Envoyer le message'}
                </ConfirmButton>
              </SupportForm>
            </SettingsSection>

            <SettingsSection>
              <SectionTitle>Actions du compte</SectionTitle>
              <RestartQuizButton onClick={handleRestartQuiz}>
                <FaRedo />
                Recommencer le quiz
              </RestartQuizButton>
              <LogoutButton onClick={handleLogoutClick}>
                <FaSignOutAlt />
                Se déconnecter
              </LogoutButton>
              <DeleteAccountButton onClick={handleDeleteAccount}>
                <FaUserTimes />
                Supprimer le compte
              </DeleteAccountButton>
              <CGUButton onClick={handleViewCGU}>
                <FaFileAlt />
                Voir les conditions d'utilisation
              </CGUButton>
            </SettingsSection>

          </>
        )}

        
      <SocialLinksContainer>
        <SocialLink href="https://www.tiktok.com/@meoria_app" target="_blank" rel="noopener noreferrer">
          <FaTiktok />
        </SocialLink>
        <SocialLink href="https://www.instagram.com/meoria_app/" target="_blank" rel="noopener noreferrer">
          <FaInstagram />
        </SocialLink>
        <SocialLink href="https://www.youtube.com/@meoria_app" target="_blank" rel="noopener noreferrer">
          <FaYoutube />
        </SocialLink>
        <SocialLink href="mailto:meoria.app@gmail.com">
          <FaEnvelope />
        </SocialLink>
      </SocialLinksContainer>
      </ContentWrapper>


      {renderLogoutModal()}
      {renderRestartModal()}
      {renderDeleteModal()}
    </Container>
  );
};
export default Settings;
