import React, { createContext, useContext, useState, useCallback, useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';

export const TUTORIAL_STEPS = [
  {
    id: 'show-quiz-buttons',
    message: "First, try clicking on one of these buttons to answer!",
    position: 'bottom',
    highlightedButton: null,
    path: '/tutorial/quiz',
    isQuizStep: true,
    interactionType: 'buttons'
  },
  {
    id: 'show-quiz-swipe',
    message: "Great! Now try swiping the card in any direction to answer!",
    position: 'bottom',
    highlightedButton: null,
    path: '/tutorial/quiz',
    isQuizStep: true,
    interactionType: 'swipe'
  },
  {
    id: 'quiz-complete',
    message: "Quiz terminé!",
    position: 'bottom',
    highlightedButton: null,
    path: '/tutorial/quiz',
    isQuizStep: true,
    interactionType: 'none'
  },
  {
    id: 'show-results',
    message: "Ready to discover the last and most useful tool?",
    position: 'bottom',
    highlightedButton: 'ranking-button',
    path: '/tutorial/classement',
    isResultsStep: true,
    nextButtonText: "Let's go!"
  },
  {
    id: 'highlight-chat',
    message: "This is where you can chat with our AI to get personalized recommendations!",
    position: 'bottom',
    highlightedButton: 'chat-button',
    awaitAction: true,
    path: '/tutorial/classement'
  },
  {
    id: 'show-chat',
    message: "Try asking questions about your career or learning path!",
    position: 'bottom',
    highlightedButton: null,
    path: '/tutorial/chat',
    isChatStep: true
  },
  {
    id: 'final-step',
    message: "Perfect! You're all set to start your journey!",
    position: 'bottom',
    highlightedButton: null,
    path: '/tutorial/chat',
    isLastStep: true
  }
];


const TutorialContext = createContext(null);

export const TutorialProvider = ({ children }) => {
  const [isTutorialActive, setIsTutorialActive] = useState(false);
  const [currentStep, setCurrentStep] = useState(0);
  const [steps] = useState(TUTORIAL_STEPS);
  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    const tutorialState = sessionStorage.getItem('tutorialState');
    if (tutorialState) {
      const { isActive, step } = JSON.parse(tutorialState);
      setIsTutorialActive(isActive);
      setCurrentStep(step);
    } else if (location.pathname.startsWith('/tutorial')) {
      setIsTutorialActive(true);
      setCurrentStep(0);
      sessionStorage.setItem('tutorialState', JSON.stringify({
        isActive: true,
        step: 0
      }));
    }
  }, [location.pathname]);

 // In TutorialContext.jsx, modify the useEffect:
    useEffect(() => {
      const tutorialState = sessionStorage.getItem('tutorialState');
      if (tutorialState) {
        // Clear any existing tutorial state
        sessionStorage.removeItem('tutorialState');
      }
      // Skip tutorial initialization
      sessionStorage.setItem('tutorialCompleted', 'true');
    }, [location.pathname]);

  const startTutorial = useCallback(() => {
    console.log('Starting tutorial...');
    setIsTutorialActive(true);
    setCurrentStep(0);
    sessionStorage.setItem('tutorialState', JSON.stringify({
      isActive: true,
      step: 0
    }));
    navigate(TUTORIAL_STEPS[0].path);
  }, [navigate]);

  const endTutorial = useCallback(() => {
    console.log('Ending tutorial...');
    setIsTutorialActive(false);
    setCurrentStep(0);
    sessionStorage.removeItem('tutorialState');
    sessionStorage.setItem('tutorialCompleted', 'true');
    navigate('/first-login');
  }, [navigate]);

  const nextStep = useCallback(() => {
    console.log('Moving to next step...', { currentStep, totalSteps: steps.length });
    
    if (currentStep < steps.length - 1) {
      // First update the step
      const nextStepIndex = currentStep + 1;
      console.log('Updating to step:', nextStepIndex);
      
      // Update state first
      setCurrentStep(nextStepIndex);
      
      // Then navigate
      const nextPath = steps[nextStepIndex].path;
      console.log('Navigating to:', nextPath);
      navigate(nextPath);
      
      // Update session storage
      sessionStorage.setItem('tutorialState', JSON.stringify({
        isActive: true,
        step: nextStepIndex
      }));
    } else {
      endTutorial();
    }
  }, [currentStep, steps, navigate, endTutorial]);

  const previousStep = useCallback(() => {
    if (currentStep > 0) {
      const prevStepIndex = currentStep - 1;
      setCurrentStep(prevStepIndex);
      navigate(steps[prevStepIndex].path);
      sessionStorage.setItem('tutorialState', JSON.stringify({
        isActive: true,
        step: prevStepIndex
      }));
    }
  }, [currentStep, steps, navigate]);


  useEffect(() => {
    console.log('Tutorial State Updated:', {
      isTutorialActive,
      currentStep,
      currentStepId: steps[currentStep]?.id,
      currentPath: location.pathname,
      intendedPath: steps[currentStep]?.path,
      totalSteps: steps.length,
      nextStepWouldBe: currentStep + 1,
      nextStepId: steps[currentStep + 1]?.id
    });
  }, [isTutorialActive, currentStep, location.pathname, steps]);

  const value = {
    isTutorialActive,
    setIsTutorialActive,
    currentStep,
    steps,
    currentStepData: steps[currentStep],
    startTutorial,
    endTutorial,
    nextStep,
    previousStep
  };

  return (
    <TutorialContext.Provider value={value}>
      {children}
    </TutorialContext.Provider>
  );
};

export const useTutorial = () => {
  const context = useContext(TutorialContext);
  if (!context) {
    throw new Error('useTutorial must be used within a TutorialProvider');
  }
  return context;
};