import React from 'react';
import { useNavigate } from 'react-router-dom';
import { MobileHistoryContainer, HistoryContent, MobileMenuButtonWrapper, Overlay } from './styles';
import ChatHistory from '../ChatHistory';
import { MenuButton } from '../sharedStyles';
import { IoMenu } from "react-icons/io5";

const MobileHistoryTab = ({ isOpen, onClose }) => {
  const navigate = useNavigate();

  const handleDiscussionClick = (discussion) => {
    navigate('/chat', { state: { selectedDiscussion: discussion } });
    onClose();
  };

  const handleOverlayClick = (e) => {
    if (e.target === e.currentTarget) {
      onClose();
    }
  };

  return (
    <>
      <Overlay $isOpen={isOpen} onClick={handleOverlayClick} />
      <MobileHistoryContainer $isOpen={isOpen}>
        <HistoryContent>
          <ChatHistory onDiscussionClick={handleDiscussionClick} />
        </HistoryContent>
      </MobileHistoryContainer>
      <MobileMenuButtonWrapper $isOpen={isOpen}>
        <MenuButton onClick={onClose}>
          <IoMenu size={24} />
        </MenuButton>
      </MobileMenuButtonWrapper>
    </>
  );
};

export default MobileHistoryTab;