import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { IoIosArrowBack } from "react-icons/io";
import {
  Container,
  ContentWrapper,
  ReturnButton,
} from '../AccountCreation/styles';
import {
  PrimaryButton
} from '../SecundPage/styles';
import {
  OptionsContainer,
  ButtonsWrapper,
  OptionIcon,
  LogoImage,
  OptionButton,
  LoadingOverlay,
  OptionsScrollContainer,
  ChatBubbleContainer,
  ChatText
} from './styles';
import network_1_bar from '../../assets/network_1_bar.png';
import network_2_bar from '../../assets/network_2_bar.png';
import network_3_bar from '../../assets/network_3_bar.png';
import network_4_bar from '../../assets/network_4_bar.png';
import useDeviceType from '../useDeviceType';
import ProgressBar from '../ProgressBar';

import Meo from '../../assets/meo.png';

const ImportanceOrientation = () => {
  const navigate = useNavigate();
  const [selectedType, setSelectedType] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [isTyping, setIsTyping] = useState(true);
  const [text, setText] = useState('');
  const fullText = "Es-tu bien informé sur Parcoursup et l'orientation en général ?";
  const { isMobile } = useDeviceType();

  const typeOptions = [
    { 
      value: '1', 
      label: "Parcoursup ? C'est quoi ?",
      icon: network_1_bar
    },
    { 
      value: '2', 
      label: "J'ai une vague idée de ce que je veux faire",
      icon: network_2_bar
    },
    { 
      value: '3', 
      label: "Je connais le domaine dans lequel je veux étudier",
      icon: network_3_bar
    },
    { 
      value: '4', 
      label: "Je sais déjà la formation que je veux faire",
      icon: network_4_bar
    },
  ];

  useEffect(() => {
    let currentIndex = 0;
    const typingInterval = setInterval(() => {
      if (currentIndex <= fullText.length) {
        setText(fullText.slice(0, currentIndex));
        currentIndex++;
      } else {
        setIsTyping(false);
        clearInterval(typingInterval);
      }
    }, 15);

    return () => clearInterval(typingInterval);
  }, []);

  const handleSubmit = () => {
    if (!selectedType || isTyping) return;
    setIsLoading(true);
    try {
      localStorage.setItem('niveau_information', selectedType);
      navigate('/source-connaissance');
    } catch (error) {
      console.error('Error saving user type to localStorage:', error);
    } finally {
      setIsLoading(false);
    }
  };

  const handleBack = () => {
    navigate("/origine-city");
  };

  return (
    <Container>
      {isLoading && <LoadingOverlay isLoading={isLoading} />}
      <ReturnButton onClick={handleBack}>
        <IoIosArrowBack />
      </ReturnButton>
      <ContentWrapper>
      <ProgressBar currentStep={3} totalSteps={4} />
          <LogoImage src={Meo} alt="Compass Logo" />
          <ChatBubbleContainer isMobile={isMobile}>
            <ChatText isVisible={text.length > 0}>
              <span>{text}</span>
            </ChatText>
          </ChatBubbleContainer>

        <OptionsScrollContainer>
          <OptionsContainer>
          <ButtonsWrapper style={{ 
            opacity: isTyping ? 0 : 1,
            transition: 'opacity 0.3s ease',
            pointerEvents: isTyping ? 'none' : 'auto'
          }}>
            {typeOptions.map((option) => (
              <OptionButton
                key={option.value}
                isSelected={selectedType === option.value}
                onClick={() => setSelectedType(option.value)}
                type="button"
                aria-pressed={selectedType === option.value}
              >
                <OptionIcon 
                  src={option.icon} 
                  alt=""
                  isSelected={selectedType === option.value}
                />
                {option.label}
              </OptionButton>
            ))}
          </ButtonsWrapper>
          </OptionsContainer>
        </OptionsScrollContainer>

        <PrimaryButton
          onClick={handleSubmit}
          disabled={isLoading || isTyping || !selectedType}
          type="button"
          style={{
            opacity: (isTyping || !selectedType) ? 0.5 : 1,
            cursor: (isTyping || !selectedType) ? 'not-allowed' : 'pointer'
          }}
        >
          {isLoading ? 'Chargement...' : 'Continuer'}
        </PrimaryButton>
      </ContentWrapper>
    </Container>
  );
};

export default ImportanceOrientation;