import styled from 'styled-components';
import theme from '../../theme';

export const ProgressBarContainer = styled.div`
  position: fixed;
  top: calc(var(--vh, 1vh) * 5);
  left: 50%;
  transform: translateX(-50%);
  width: 65%;
  height: 12px;
  background-color: ${theme.colors.lightGray};
  border-radius: 4px;
  overflow: hidden;
  z-index: 3;
`;

export const ProgressBarFill = styled.div`
  height: 100%;
  width: ${props => props.width}%;
  background-color: ${theme.colors.primary};
  transition: width 0.6s ease-in-out;
  border-radius: 4px;
`;

export const StepIndicator = styled.div`
  position: absolute;
  top: -20px;
  right: 0;
  transform: translateY(-100%);
`;

export const StepText = styled.span`
  font-family: ${theme.fonts.main};
  font-size: ${theme.fontSizes.small};
  color: ${theme.colors.noir};
  font-weight: ${theme.fontWeights.medium};
`;