import React, { useState, useEffect } from 'react';
import { useAuth } from '../../contexts/AuthContext';
import useDeviceType from '../useDeviceType';
import ShareTopSchools from './ShareTopSchools';
import {
  Container,
  ScrollableRankings,
  RankingList,
  RankingItem,
  Trophy,
  SchoolInfo,
  SchoolName,
  Formation,
  Location,
  LocationIcon,
  RankNumber,
  NoResultsMessage,
  PodiumHeader,
  PodiumIcon,
  PodiumTitle,
} from './styles';
import { LoadingContainer, LoadingBar } from '../LoadingStyles';
import apiCall from '../../utils/api';

const Classement = () => {
  const { user } = useAuth();
  const [rankings, setRankings] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const { isMobile } = useDeviceType();

  useEffect(() => {
    const initializeRankings = async () => {
      if (user && user.email) {
        await fetchRankings();
      } else {
        setIsLoading(false);
      }
    };

    initializeRankings();
  }, [user]);

  const fetchRankings = async () => {
    try {
      setIsLoading(true);
      const response = await apiCall(`${process.env.REACT_APP_BACKEND_URL}/quiz/results/`, 'GET');
      if (response.rankings) {
        setRankings(response.rankings);
      }
    } catch (error) {
      console.error('Error fetching rankings:', error);
      setRankings(null);
    } finally {
      setIsLoading(false);
    }
  };

  if (isLoading) {
    return (
      <LoadingContainer>
        <LoadingBar />
      </LoadingContainer>
    );
  }

  if (!rankings) {
    return (
      <Container>
        <NoResultsMessage>
          No results available. Please complete the quiz to see your matches.
        </NoResultsMessage>
      </Container>
    );
  }

  return (
    <Container>
      <PodiumHeader>
        <PodiumIcon />
        <PodiumTitle>Classement</PodiumTitle>
        {/* Add ShareTopSchools component */}
        <ShareTopSchools rankings={rankings} />
      </PodiumHeader>
      <ScrollableRankings>
        <RankingList>
          {Object.entries(rankings).map(([key, school]) => {
            const rank = parseInt(key.replace('top', ''));
            return (
              <RankingItem 
                key={key} 
                href={school.url}
                target="_blank" 
                rel="noopener noreferrer"
                rank={rank}
              >
                {rank <= 3 ? (
                  <Trophy rank={rank} />
                ) : (
                  <RankNumber>{rank}</RankNumber>
                )}
                <SchoolInfo>
                  <SchoolName rank={rank}>{school.name}</SchoolName>
                  <Formation>{school.formation}</Formation>
                </SchoolInfo>
                <Location>
                  <LocationIcon />
                  {school.city}
                </Location>
              </RankingItem>
            );
          })}
        </RankingList>
      </ScrollableRankings>
    </Container>
  );
};

export default Classement;