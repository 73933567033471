import React, { useState, useRef, useCallback, useEffect, memo } from 'react';
import { Route, Routes, Navigate, useLocation } from 'react-router-dom';
import { ThemeProvider } from 'styled-components';

// Components
import ChatDiscussion from './components/ChatDiscussion';
import SideMenu from './components/SideMenu';
import BottomMenu from './components/BottomMenu';
import OrigineCity from './components/OrigineCity';
import LoginPage from './components/LoginPage';
import FirstLoginAccount from './components/FirstLoginAccount';
import Quiz from './components/Quiz';
import Classement from './components/Classement';
import Settings from './components/Settings';
import MobileHistoryTab from './components/MobileHistoryTab';
import FirstPage from './components/FirstPage';
import SecundPage from './components/SecundPage';
import ThirdPage from './components/ThirdPage';
import PresentationOutils from './components/PresentationOutils';
import SourceConnaissance from './components/SourceConnaissance';
import ImportanceOrientation from './components/ImportanceOrientation';
import MessagePret from './components/MessagePret';
import AccountCreation from './components/AccountCreation';
import DownloadApp from './components/DownloadApp';
import GenderSelection from './components/GenderSelection';
import UserTypeSelection from './components/UserTypeSelection';
import TutorialWrapper from './components/Tutorial/TutorialWrapper';
import LandingPage from './components/LandingPage';
// Contexts and Providers
import { AuthProviderWithNavigate, useAuth } from './contexts/AuthContext';
import { ChatProvider } from './contexts/ChatContext';
import { QuizProvider } from './contexts/QuizContext';
import { TutorialProvider, useTutorial } from './components/Tutorial/TutorialContext';
import AppLoadingHandler from './contexts/AppLoadingHandler';

// Styles and Utils
import useDeviceType from './components/useDeviceType';
import theme from './theme';
import GlobalStyles from './GlobalStyles';
import PWAInstallPrompt from './components/PWAInstallPrompt';
import { LoadingContainer, LoadingBar } from './components/LoadingStyles';
import './App.css';

function App() {
  // States
  const [isSideMenuOpen, setIsSideMenuOpen] = useState(false);
  const [isMobileHistoryOpen, setIsMobileHistoryOpen] = useState(false);
  
  // Hooks
  const { user, loading, isAuthenticated } = useAuth();
  const { isTutorialActive } = useTutorial();
  const { isMobile } = useDeviceType();
  const location = useLocation();
  const chatDiscussionRef = useRef();

  // Utility functions
  const toggleMenu = useCallback(() => {
    setIsSideMenuOpen(prevState => !prevState);
  }, []);

  const toggleMobileHistory = useCallback(() => {
    setIsMobileHistoryOpen(!isMobileHistoryOpen);
  }, [isMobileHistoryOpen]);

  const handleDiscussionSelect = useCallback((discussion) => {
    if (chatDiscussionRef.current) {
      chatDiscussionRef.current.loadDiscussion(discussion.id);
    }
  }, []);

  const handleRestartChat = useCallback(() => {
    if (chatDiscussionRef.current) {
      chatDiscussionRef.current.resetChat();
    }
  }, []);

  // Viewport height effect
  useEffect(() => {
    const updateViewportHeight = () => {
      const vh = window.innerHeight * 0.01;
      document.documentElement.style.setProperty('--vh', `${vh}px`);
    };

    updateViewportHeight();
    window.addEventListener('resize', updateViewportHeight);
    window.addEventListener('orientationchange', () => {
      setTimeout(updateViewportHeight, 100);
    });

    return () => {
      window.removeEventListener('resize', updateViewportHeight);
      window.removeEventListener('orientationchange', updateViewportHeight);
    };
  }, []);

  // Memoized ChatDiscussion component
  const MemoizedChatDiscussion = memo(({ ref, toggleMobileHistory, isSideMenuOpen, isMobile }) => (
    <ChatDiscussion
      ref={ref}
      toggleMobileHistory={toggleMobileHistory}
      isSideMenuOpen={isSideMenuOpen}
      isMobile={isMobile}
    />
  ));

  if (loading) {
    return (
      <ThemeProvider theme={theme}>
        <LoadingContainer>
          <LoadingBar />
        </LoadingContainer>
      </ThemeProvider>
    );
  }

  // Define the main content based on authentication and tutorial state
  const renderMainContent = () => {
    // If authenticated, show the main app
    if (isAuthenticated) {
      return (
        <AuthProviderWithNavigate>
          <ChatProvider>
            <QuizProvider>
              <div className="App">
                <PWAInstallPrompt />
                <SideMenu
                  isOpen={isSideMenuOpen}
                  onToggle={toggleMenu}
                  onDiscussionSelect={handleDiscussionSelect}
                  onRestartChat={handleRestartChat}
                />
                <main className={`content-area ${isMobile ? 'mobile' : 'desktop'} ${isSideMenuOpen ? 'side-menu-open' : ''}`}>
                  <Routes>
                    <Route path="/chat" element={
                      <MemoizedChatDiscussion
                        ref={chatDiscussionRef}
                        toggleMobileHistory={toggleMobileHistory}
                        isSideMenuOpen={isSideMenuOpen}
                        isMobile={isMobile}
                      />
                    } />
                    <Route path="/quiz" element={!isMobile ? <DownloadApp /> : <Quiz />} />
                    <Route path="/classement" element={!isMobile ? <DownloadApp /> : <Classement />} />
                    <Route path="/settings" element={<Settings />} />
                    <Route path="/account-creation" element={<AccountCreation />} />
                    <Route path="/" element={<Navigate to="/chat" replace />} />
                  </Routes>
                </main>
                {isMobile && (
                  <>
                    <BottomMenu/>
                    {location.pathname === '/chat' && !isTutorialActive && (
                      <MobileHistoryTab
                        isOpen={isMobileHistoryOpen}
                        onClose={toggleMobileHistory}
                      />
                    )}
                  </>
                )}
              </div>
            </QuizProvider>
          </ChatProvider>
        </AuthProviderWithNavigate>
      );
    }

    // Render public routes, onboarding, and tutorial
    return (
      <Routes>
        {/* Public Routes */}
        <Route 
          path="/" 
          element={isMobile ? <FirstPage /> : <div className="landing-page-container"><LandingPage /></div>} 
        />
        <Route path="/login" element={<LoginPage />} />

        {/* Onboarding Flow */}
        <Route path="/presentation-meo" element={<SecundPage />} />
        <Route path="/origine-city" element={<OrigineCity />} />
        <Route path="/gender-selection" element={<GenderSelection />} />
        <Route path="/user-type-selection" element={<UserTypeSelection />} />
        <Route path="/importance-orientation" element={<ImportanceOrientation />} />
        <Route path="/message-pret" element={<MessagePret />} />
        <Route path="/presentation-outils" element={<PresentationOutils />} />
        <Route path="/just-4-questions" element={<ThirdPage />} />
        <Route path="/source-connaissance" element={<SourceConnaissance />} />

        {/* Tutorial Flow - Starting with bottom menu */}
        <Route path="/tutorial/*" element={<TutorialWrapper />} />

        {/* Account Creation */}
        <Route path="/account-creation" element={<AccountCreation />} />
        <Route path="/first-login" element={<FirstLoginAccount />} />

        {/* Fallback */}
        <Route path="*" element={
          <Navigate to={location.pathname.includes('/tutorial') ? '/tutorial/quiz' : '/'} replace />
        } />
      </Routes>
    );
  };

  return (
    <ThemeProvider theme={theme}>
      <TutorialProvider>
        <AppLoadingHandler>
          <GlobalStyles />
          {renderMainContent()}
        </AppLoadingHandler>
      </TutorialProvider>
    </ThemeProvider>
  );
}

export default App;
