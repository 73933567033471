import React, { useState, useRef, useEffect, useCallback, forwardRef, useImperativeHandle } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import ReactMarkdown from 'react-markdown';
import remarkGfm from 'remark-gfm';
import axios from 'axios';
import rehypeExternalLinks from 'rehype-external-links'
import { getAuthToken } from '../../utils/auth';
import { ClipLoader } from 'react-spinners';
import WelcomeMenu from '../WelcomeMenu';
import { FaPenToSquare } from "react-icons/fa6";
import {
  Container,
  ChatContainer,
  ChatBox,
  ChatHistory,
  Message,
  InputArea,
  Input,
  LoadingMessage,
  LoadingText,
  NewChatIconButton,
  SendButton,
  MarkdownBox,
  InputAreaWrapper,
  SourceLink,
  SourcesContainer,
} from './styles';
import { FaArrowUp } from "react-icons/fa";
import { useAuth } from '../../contexts/AuthContext';
import PopUp from '../SaveLoginPopup';
import MobileHistoryTab from '../MobileHistoryTab';
import useDeviceType from '../useDeviceType';
import { useChat } from '../../contexts/ChatContext';
import { SettingsButton, SettingsIcon } from '../Classement/styles';
import styled from 'styled-components';

const TransitionWrapper = styled.div`
  opacity: ${props => props.isLoaded ? 1 : 0};
  visibility: ${props => props.isLoaded ? 'visible' : 'hidden'};
  transition: opacity 0.5s ease-in-out, visibility 0.5s ease-in-out;
  height: 100%;
  width: 100%;
`;

const REACT_APP_BACKEND_URL = process.env.REACT_APP_BACKEND_URL;

const ChatDiscussion = forwardRef(({ onStartNewChat, toggleMobileHistory, isSideMenuOpen }, ref) => {
  const location = useLocation();
  const navigate = useNavigate();
  const inputRef = useRef(null);
  const [isLoading, setIsLoading] = useState(false);
  const [isPopUpOpen, setIsPopUpOpen] = useState(false);
  const chatHistoryRef = useRef(null);
  const [isMobileHistoryOpen, setIsMobileHistoryOpen] = useState(false);
  const [, setIsHistoryOpen] = useState(false);
  const { user, isAuthenticated } = useAuth();
  const [streamingMessage, setStreamingMessage] = useState('');
  const streamingMessageRef = useRef(null);
  const { isMobile } = useDeviceType();
  const isUnmounting = useRef(false);
  const [isLoaded, setIsLoaded] = useState(true);
  let streamingCompleted = false;
  const {
    chatHistory, setChatHistory,
    discussion, setDiscussion,
    inputText, setInputText,
    showWelcomeMenu, setShowWelcomeMenu,
    setContextHistory,
    resetChat,
    loadDiscussionState
  } = useChat();

  useEffect(() => {
    isUnmounting.current = false;
    return () => {
      isUnmounting.current = true;
    };
  }, []);

  useEffect(() => {
    const hasLoaded = sessionStorage.getItem('chatHasLoaded');
    if (!hasLoaded) {
      sessionStorage.setItem('chatHasLoaded', 'true');
    }
  }, []);

  useEffect(() => {
    const scrollToBottom = () => {
      if (chatHistoryRef.current) {
        chatHistoryRef.current.scrollTop = chatHistoryRef.current.scrollHeight;
      }
    };

    scrollToBottom();

    const observer = new MutationObserver(scrollToBottom);
    if (chatHistoryRef.current) {
      observer.observe(chatHistoryRef.current, { childList: true, subtree: true });
    }

    return () => observer.disconnect();
  }, [chatHistory, streamingMessage]);

  useImperativeHandle(ref, () => ({
    resetChat: handleStartNewChat,
    loadDiscussion: loadDiscussion
  }));

  const loadDiscussion = useCallback(async (discussionId) => {
    setIsLoading(true);
    setShowWelcomeMenu(false);

    try {
      const token = getAuthToken();
      if (!token) {
        throw new Error('No authentication token found');
      }

      const response = await axios.get(
        `${process.env.REACT_APP_BACKEND_URL}/inference/get_single_discussion/`,
        {
          headers: {
            'Authorization': `Bearer ${token}`,
          },
          params: { discussion_id: discussionId },
        }
      );

      console.log('Response status:', response.status);
      console.log('Response data:', response.data);

      if (response.status === 200 && response.data) {
        const { messages, last_context, begin_date } = response.data;

        const formattedMessages = messages.map(msg => ({
          user: msg.is_user ? 'You' : 'Bot',
          message: msg.content
        }));

        loadDiscussionState({
          id: discussionId,
          messages: formattedMessages,
          contextHistory: last_context,
          beginDate: begin_date
        });
      } else {
        throw new Error('Invalid response format');
      }
    } catch (error) {
      console.error('Error loading discussion:', error);
      setChatHistory(prevHistory => [...prevHistory, {
        user: 'Bot',
        message: "Error loading discussion. Please try again."
      }]);
    } finally {
      setIsLoading(false);
    }
  }, [setChatHistory, loadDiscussionState]);

  const handleSettingsClick = () => {
      navigate('/settings', { state: { from: '/chat' } });
    };

  useEffect(() => {
    const handleLocationStateChange = async () => {
      if (location.state?.selectedDiscussion) {
        const discussionId = location.state.selectedDiscussion.id;
        await loadDiscussion(discussionId);

        // Clear the location state after loading
        navigate(location.pathname, {
          replace: true,
          state: {}
        });
      }
    };

    handleLocationStateChange();
  }, [location.state, loadDiscussion, navigate]);

  const toggleHistory = () => {
    if (isMobile) {
      setIsMobileHistoryOpen(prev => !prev);
    } else {
      setIsHistoryOpen(prev => !prev);
    }
  };

  const adjustTextareaHeight = () => {
    if (inputRef.current) {
      inputRef.current.style.height = 'auto';
      const newHeight = Math.min(inputRef.current.scrollHeight, 150);
      inputRef.current.style.height = `${newHeight}px`;

      // Adjust the textarea's position within its container
      inputRef.current.style.top = `${150 - newHeight}px`;
    }
  };



  useEffect(() => {
    adjustTextareaHeight();
  }, [inputText]);

  const handleInputChange = (e) => {
    const newValue = e.target.value;
    setInputText(newValue);
    adjustTextareaHeight();
  };

  const handleStartNewChat = useCallback(() => {
    if (!isAuthenticated) {
      setIsPopUpOpen(true);
      return;
    }
    resetChat();
  }, [isAuthenticated, resetChat]);

  useEffect(() => {
    if (location.state?.selectedDiscussion) {
      loadDiscussion(location.state.selectedDiscussion.id);
      setShowWelcomeMenu(false);
    }
  }, [location.state, loadDiscussion, setShowWelcomeMenu]);

  const saveDiscussionToLocalStorage = (newDiscussion) => {
    const savedDiscussions = JSON.parse(localStorage.getItem('chatDiscussions')) || [];
    const updatedDiscussions = [...savedDiscussions, newDiscussion];
    localStorage.setItem('chatDiscussions', JSON.stringify(updatedDiscussions));
  };

  const handleExampleClick = (example) => {
    setInputText(example);
    handleSubmit(null, example);
  };


  const extractLinks = (markdown) => {
    const linkRegex = /\[([^\]]+)\]\(([^)]+)\)/g;
    const links = [];
    let match;

    while ((match = linkRegex.exec(markdown)) !== null) {
      // Only use the text within brackets
      const displayText = match[1].match(/\[(.*?)\]/)?.[1] || match[1];
      links.push({
        text: displayText,
        url: match[2]
      });
    }

    return links;
  };

  const handleSubmit = async (e, exampleText = null) => {
    if (e && e.preventDefault) {
      e.preventDefault();
    }
    console.log('Initial chatHistory:', chatHistory);

    const messageToSend = exampleText || inputText.trim();

    if (!messageToSend) {
      alert('Please enter a valid message before sending.');
      return;
    }

    if (!isAuthenticated) {
      setIsPopUpOpen(true);
      return;
    }

    setShowWelcomeMenu(false);

    // Ensure we're working with an array and add new message
    const newUserMessage = { user: 'You', message: messageToSend };
    setChatHistory(prevHistory => {
      const currentHistory = Array.isArray(prevHistory) ? prevHistory : [];
      return [...currentHistory, newUserMessage];
    });

    setInputText('');
    setIsLoading(true);
    setStreamingMessage('');

    let streamingCompleted = false; // Move this inside function scope

    try {
      let currentDiscussion = discussion;

      if (!currentDiscussion) {
        try {
          const newDiscussionResponse = await axios.post(
            `${REACT_APP_BACKEND_URL}/inference/start_new_discussion/`,
            { user_id: user.id },
            {
              withCredentials: true,
              headers: {
                'Authorization': `Bearer ${getAuthToken()}`,
              }
            }
          );

          if (newDiscussionResponse?.status === 200 && newDiscussionResponse?.data?.new_discussion_id) {
            currentDiscussion = newDiscussionResponse.data.new_discussion_id;
            setDiscussion(currentDiscussion);
          } else {
            throw new Error('Invalid response for new discussion');
          }
        } catch (error) {
          console.error('Error creating new discussion:', error);
          throw new Error('Failed to create new discussion');
        }
      }

      const response = await fetch(`${REACT_APP_BACKEND_URL}/inference/inference_view/`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${getAuthToken()}`,
        },
        body: JSON.stringify({
          input: messageToSend,
          discussion: currentDiscussion,
          user_id: user.id,
        }),
      });

      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }

      const reader = response.body.getReader();
      const decoder = new TextDecoder();

      let fullResponse = '';
      while (true) {
        try {
          const { done, value } = await reader.read();

          if (done) {
            streamingCompleted = true;

            if (fullResponse.trim()) {
              const links = extractLinks(fullResponse.trim());
              const newBotMessage = {
                user: 'Bot',
                message: fullResponse.trim(),
                sources: links
              };

              setChatHistory(prevHistory => {
                const currentHistory = Array.isArray(prevHistory) ? prevHistory : [];
                const updatedHistory = [...currentHistory, newBotMessage];

                try {
                  const newDiscussionEntry = {
                    title: `Chat from ${new Date().toLocaleString()}`,
                    content: updatedHistory
                  };
                  saveDiscussionToLocalStorage(newDiscussionEntry);
                } catch (error) {
                  console.error('Error saving to localStorage:', error);
                }

                return updatedHistory;
              });
            }
            setStreamingMessage('');
            break;
          }

          const chunk = decoder.decode(value);
          const lines = chunk.split('\n');

          for (const line of lines) {
            if (line.startsWith('data: ')) {
              try {
                const data = JSON.parse(line.slice(5));
                if (data?.chunk) {
                  fullResponse += data.chunk;
                  setStreamingMessage(prevMessage => prevMessage + data.chunk);
                }
              } catch (error) {
                console.error('Error parsing chunk:', error);
                continue;
              }
            }
          }
        } catch (error) {
          console.error('Error reading stream:', error);
          throw error;
        }
      }

    } catch (error) {
      console.error('Error during inference:', error);
      if (!streamingCompleted) {
        setChatHistory(prevHistory => {
          const currentHistory = Array.isArray(prevHistory) ? prevHistory : [];
          return [...currentHistory, {
            user: 'Bot',
            message: "Je suis momentanément indisponible. Veuillez réessayer plus tard. Nous réparons cette erreur au plus vite.\n\nMEORIA, le staff ;)"
          }];
        });
      }
    } finally {
      setIsLoading(false);
    }
  };

  const handleKeyPress = (e) => {
    if (e.key === 'Enter' && !e.shiftKey) {
      e.preventDefault();
      handleSubmit(e);
    }
  };

  return (
    <>
      <TransitionWrapper isLoaded={isLoaded}>
        <Container isSideMenuOpen={!isMobile && isSideMenuOpen} isMobile={isMobile}>
        <SettingsButton onClick={handleSettingsClick}>
          <SettingsIcon />
        </SettingsButton>
        {isMobile && (
          <MobileHistoryTab
            isOpen={isMobileHistoryOpen}
            onClose={toggleHistory}
          />
        )}
        <NewChatIconButton onClick={handleStartNewChat}>
          <FaPenToSquare />
        </NewChatIconButton>

        <ChatContainer showWelcomeMenu={showWelcomeMenu} isMobile={isMobile} isSideMenuOpen={!isMobile && isSideMenuOpen}>
            <ChatBox>
              <ChatHistory ref={chatHistoryRef}>
                {showWelcomeMenu ? (
                  <WelcomeMenu
                    onExampleClick={handleExampleClick}
                    isSideMenuOpen={!isMobile && isSideMenuOpen}
                    isMobile={isMobile}
                  />
                ) : (
                  <>
                    {chatHistory.map((chat, index) => (
                      <React.Fragment key={index}>
                        {chat.user === 'Bot' && chat.sources && chat.sources.length > 0 && (
                          <SourcesContainer>
                            {chat.sources.map((source, sourceIndex) => (
                              <SourceLink
                                key={sourceIndex}
                                href={source.url}
                                target="_blank"
                                rel="nofollow noopener noreferrer"
                              >
                                {source.text}
                              </SourceLink>
                            ))}
                          </SourcesContainer>
                        )}
                        <Message $user={chat.user}>
                          <MarkdownBox>
                            <ReactMarkdown
                              remarkPlugins={[remarkGfm]}
                              rehypePlugins={[
                                [rehypeExternalLinks, { target: '_blank', rel: ['nofollow', 'noopener', 'noreferrer'] }]
                              ]}
                            >
                              {chat.message}
                            </ReactMarkdown>
                          </MarkdownBox>
                        </Message>
                      </React.Fragment>
                    ))}
                    {streamingMessage && (
                      <Message $user="Bot" ref={streamingMessageRef}>
                        <MarkdownBox>
                          <ReactMarkdown
                            remarkPlugins={[remarkGfm]}
                            rehypePlugins={[
                              [rehypeExternalLinks, { target: '_blank', rel: ['nofollow', 'noopener', 'noreferrer'] }]
                            ]}
                          >
                            {streamingMessage}
                          </ReactMarkdown>
                        </MarkdownBox>
                      </Message>
                    )}
                    {isLoading && !streamingMessage && (
                        <LoadingMessage>
                          <LoadingText>Meo réfléchit</LoadingText>
                        </LoadingMessage>
                      )}
                  </>
                )}
              </ChatHistory>
            </ChatBox>
          </ChatContainer>
      <InputAreaWrapper
        isMobile={isMobile}
        showWelcomeMenu={showWelcomeMenu}
        isSideMenuOpen={!isMobile && isSideMenuOpen}
      >
        <InputArea as="form" onSubmit={handleSubmit} isMobile={isMobile} isSideMenuOpen={!isMobile && isSideMenuOpen}>
          <Input
            ref={inputRef}
            as="textarea"
            value={inputText}
            onChange={handleInputChange}
            onKeyPress={handleKeyPress}
            placeholder="Message à Meo..."
            disabled={isLoading}
            rows={1}
            isSideMenuOpen={!isMobile && isSideMenuOpen}
          />
          <SendButton
              type="submit"
              disabled={isLoading}
              show={inputText.trim().length > 0}
            >
              <FaArrowUp size={16} />
            </SendButton>
        </InputArea>
      </InputAreaWrapper>
      <PopUp
        isOpen={isPopUpOpen}
        onClose={() => setIsPopUpOpen(false)}
        message="Veuillez vous connecter pour utiliser cette fonctionnalit "
        onLogin={() => navigate('/login')}
      />
    </Container>
    </TransitionWrapper>
    </>
  );
});

export default ChatDiscussion;
