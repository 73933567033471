import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import useDeviceType from '../useDeviceType';
import { useTutorial } from '../Tutorial/TutorialContext';
import Meo from '../../assets/meo.png';
import styled, { keyframes } from 'styled-components';
import TutorialPodium from '../Podium/TutorialPodium';
import {
  ScrollableRankings,
  RankingList,
  RankingItem,
  RankingNumber,
  RankingLink
} from './styles'
import theme from '../../theme';


const Container = styled.div`
  display: flex;
  justify-content: center;
  align-items: flex-start;  // Changed from center to better position content
  min-height: 100vh;
  background-color: ${theme.colors.background};
  padding: ${theme.spacing.medium};
`;

export const ContentWrapper = styled.div`
  position: relative;
  width: 100%;
  height: 100%;
  max-width: 600px;
`;

const InteractionBlocker = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 1600;
  cursor: default;
`;

const float = keyframes`
  0% {
    transform: translateY(0px) rotate(0deg);
  }
  25% {
    transform: translateY(-3px) rotate(1deg);
  }
  50% {
    transform: translateY(-5px) rotate(0deg);
  }
  75% {
    transform: translateY(-3px) rotate(-1deg);
  }
  100% {
    transform: translateY(0px) rotate(0deg);
  }
`;

export const LogoImage = styled.img`
  width: 30%;
  height: 30%;
  object-fit: contain;
  margin-left: 35%;
  margin-top: -10%;

  /* Add drop shadow */
  filter: drop-shadow(0 4px 6px rgba(0, 0, 0, 0.1));

  /* Add floating animation */
  animation: ${float} 6s ease-in-out infinite;

  /* Add smooth transition for hover effect */
  transition: all 0.3s ease;

  /* Optional: Add subtle hover effect */
  &:hover {
    transform: scale(1.05) translateY(-2px);
    filter: drop-shadow(0 6px 8px rgba(0, 0, 0, 0.15));
    animation-play-state: paused;
  }

  /* Make animation smoother on higher-end devices */
  @media (prefers-reduced-motion: no-preference) {
    will-change: transform;
  }
`;

export const ChatBubbleContainer = styled.div`
  position: absolute;
  top: 100%;
  left: 50%;
  transform: translateX(-50%);
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  max-width: 300px;
  z-index: 2;
`;

export const ChatText = styled.div`
  background-color: transparent;
  color: ${theme.colors.black};
  padding: 0.8rem 1.2rem;
  border-radius: 1rem;
  font-family: "Sour Gummy", sans-serif;
  font-size: ${theme.fontSizes.medium};
  text-align: center;
  position: relative;
  width: 250px; // Fixed width
  height: 110px; // Fixed height
  display: flex;
  align-items: center;
  justify-content: center;
  border: 2px solid ${theme.colors.lightGray};
  
  // For the text animation only
  & span {
    opacity: ${props => props.isVisible ? 1 : 0};
    transform: translateY(${props => props.isVisible ? '0' : '-10px'});
    transition: opacity 0.3s ease-out, transform 0.3s ease-out;
  }

  &:after {
  content: '';
  position: absolute;
  top: -12px; // Changed from bottom to top
  left: 50%;
  transform: translateX(-50%);
  border-left: 10px solid transparent;
  border-right: 10px solid transparent;
  border-bottom: 10px solid ${theme.colors.lightGray}; // Changed from border-top to border-bottom
}

// Additional pseudo-element for the transparent tail
&:before {
  content: '';
  position: absolute;
  top: -9px; // Changed from bottom to top
  left: 50%;
  transform: translateX(-50%);
  border-left: 8px solid transparent;
  border-right: 8px solid transparent;
  border-bottom: 8px solid transparent; // Changed from border-top to border-bottom
  z-index: 1;
}
`;

const ButtonContainer = styled.div`
  position: absolute;
  top: calc(125%);
  left: 50%;
  transform: translateX(-50%);
  z-index: 2;
`;

const PrimaryButton = styled.button`
  padding: 0.5rem 2rem;
  justify-content: center;
  border-radius: 20px;
  margin-top: 20%;
  background-color: ${theme.colors.primary}
  color: white;
  cursor: pointer;
  transition: all 0.2s ease;
`;

const TutorialResults = () => {
  const { isMobile } = useDeviceType();
  const { nextStep, currentStepData, steps } = useTutorial();
  const [showSecondMessage, setShowSecondMessage] = useState(false);
  const [displayedText, setDisplayedText] = useState('');
  const [isTyping, setIsTyping] = useState(true);

  const messages = {
    first: "C'est ici que tu retrouveras tes résultats après avoir swiper une vingtaine de questions!",
    second: "Tu es prêt pour l'étape finale ! Cliques sur l'onglet Chat juste en bas"
  };

  useEffect(() => {
    const currentMessage = showSecondMessage ? messages.second : messages.first;
    let currentIndex = 0;
    setIsTyping(true);

    const typingInterval = setInterval(() => {
      if (currentIndex <= currentMessage.length) {
        setDisplayedText(currentMessage.slice(0, currentIndex));
        currentIndex++;
      } else {
        setIsTyping(false);
        clearInterval(typingInterval);
      }
    }, 15);

    return () => clearInterval(typingInterval);
  }, [showSecondMessage]);

  const getFakeTop3 = () => [
    { formation: "La formation de tes rêves #1", link: null },
    { formation: "La formation des tes rêves #2", link: null },
    { formation: "La formation des tes rêves #3", link: null }
  ];

  const getFakeRemainingRankings = () => [
  ];

  const preventDefault = (e) => {
    e.preventDefault();
    e.stopPropagation();
  };

  const handleOkClick = () => {
    if (!isTyping) {
      console.log('OK clicked, current step:', currentStepData.id);
      setShowSecondMessage(true);
      
      // Add a small delay to ensure state updates are processed
      setTimeout(() => {
        console.log('Calling nextStep after delay');
        nextStep();
      }, 100);
    }
  };

  useEffect(() => {
    if (showSecondMessage && currentStepData.id === 'quiz-complete') {
      // Add a small delay to ensure the message change is visible
      const timer = setTimeout(() => {
        nextStep();
      }, 500);
      return () => clearTimeout(timer);
    }
  }, [showSecondMessage, currentStepData.id, nextStep]);

  return (
    <Container isMobile={isMobile} style={{ position: 'relative' }}>
      <ContentWrapper>
        <InteractionBlocker onClick={preventDefault} />
        
        <TutorialPodium results={getFakeTop3()} />
        
        <ScrollableRankings isMobile={isMobile}>
          <RankingList>
            {getFakeRemainingRankings().map(({ number, formation }) => (
              <RankingItem key={number} isMobile={isMobile}>
                <RankingNumber isMobile={isMobile}>{number}</RankingNumber>
                <RankingLink as="div">{formation}</RankingLink>
              </RankingItem>
            ))}
          </RankingList>
        </ScrollableRankings>

        {(currentStepData.id === 'quiz-complete' || currentStepData.id === 'show-results' || currentStepData.id === 'highlight-chat') && (
          <>
            <LogoImage src={Meo} alt="Compass Logo" />
            <ChatBubbleContainer>
              <ChatText isVisible={!isTyping}>{displayedText}</ChatText>
            </ChatBubbleContainer>
            <ButtonContainer>
              {(currentStepData.id === 'show-results') && (
                <PrimaryButton 
                  onClick={handleOkClick}
                  disabled={isTyping}
                >
                  Ok
                </PrimaryButton>
              )}
            </ButtonContainer>
          </>
        )}
      </ContentWrapper>
    </Container>
  );
};

export default TutorialResults;