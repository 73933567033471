import React, { useState, useRef, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { IoIosArrowBack } from "react-icons/io";
import axios from 'axios';
import compassImage from '../../assets/boussole.png';
import cartoonBubble from '../../assets/cartoon.png';
import { useAuth } from '../../contexts/AuthContext';
import {
  Container,
  Cursor,
  InputWrapper,
  ContentWrapper,
  LogoImage,
  SpeechBubbleText,
  InputContainer,
  StyledInput,
  ReturnButton,
  SubmitButton,
  TextMeasure,
} from './styles';

const REACT_APP_BACKEND_URL = process.env.REACT_APP_BACKEND_URL;

const AccountCreation = () => {
  const navigate = useNavigate();
  const { token } = useAuth();
  const [inputValue, setInputValue] = useState('');
  const [cursorPosition, setCursorPosition] = useState(0);
  const [error, setError] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const inputRef = useRef(null);
  const measureRef = useRef(null);

  const updateCursorPosition = () => {
    if (measureRef.current) {
      const textWidth = measureRef.current.getBoundingClientRect().width;
      setCursorPosition(textWidth);
    }
  };

  const handleSubmit = async () => {
    if (!inputValue.trim()) return;

    setIsLoading(true);
    setError('');

    try {
      const response = await axios.post(
        `${REACT_APP_BACKEND_URL}/auth/update_pseudonym/`,
        { pseudonym: inputValue.trim() },
        {
          withCredentials: true,
          headers: {
            'Authorization': `Bearer ${token}`,
            'Content-Type': 'application/json',
          }
        }
      );

      // Store pseudonym in localStorage and navigate to chat
      localStorage.setItem('pseudo', inputValue.trim());
      navigate('/chat');
    } catch (err) {
      const errorMessage = err.response?.data?.error || 'Failed to update pseudonym';
      setError(errorMessage);
      console.error('Error updating pseudonym:', err);
    } finally {
      setIsLoading(false);
    }
  };

  const handleKeyPress = (e) => {
    if (e.key === 'Enter' && inputValue.trim() && !isLoading) {
      handleSubmit();
    }
  };

  const handleInput = (e) => {
    setInputValue(e.target.value);
    setError('');
  };

  useEffect(() => {
    updateCursorPosition();
  }, [inputValue]);

  useEffect(() => {
    if (inputRef.current) {
      inputRef.current.focus();
    }
  }, []);

  const handleContainerClick = () => {
    if (inputRef.current) {
      inputRef.current.focus();
    }
  };

  return (
    <Container onClick={handleContainerClick}>
      <ContentWrapper>
        <LogoImage src={compassImage} alt="Compass Logo" />
        <SpeechBubbleText>
          Comment veux-tu<br /> être appelé ?
        </SpeechBubbleText>
        <InputContainer>
          <InputWrapper>
            <StyledInput
              ref={inputRef}
              type="text"
              value={inputValue}
              onChange={handleInput}
              onKeyPress={handleKeyPress}
              autoFocus
              disabled={isLoading}
            />
            <TextMeasure ref={measureRef}>{inputValue}</TextMeasure>
            <Cursor style={{ left: `${cursorPosition}px` }} />
          </InputWrapper>
          {error && <div style={{ color: 'red', marginTop: '8px' }}>{error}</div>}
        </InputContainer>
        <SubmitButton
          onClick={handleSubmit}
          show={inputValue.trim().length > 0}
          disabled={isLoading}
        >
          {isLoading ? 'Chargement...' : 'Continuer'}
        </SubmitButton>
      </ContentWrapper>
    </Container>
  );
};

export default AccountCreation;