import React, { createContext, useState, useContext, useEffect } from 'react';

const ChatContext = createContext();

export const ChatProvider = ({ children }) => {
  const [chatHistory, setChatHistory] = useState([]);
  const [discussion, setDiscussion] = useState('');
  const [inputText, setInputText] = useState('');
  const [showWelcomeMenu, setShowWelcomeMenu] = useState(true);
  const [contextHistory, setContextHistory] = useState([]);
  const [beginDate, setBeginDate] = useState('');
  const [isChatInitialized, setIsChatInitialized] = useState(false);
  const [isInitialLoadComplete, setIsInitialLoadComplete] = useState(false);

  // Initial load from localStorage
  useEffect(() => {
    const loadInitialState = async () => {
      const savedState = localStorage.getItem('chatDiscussionState');
      
      if (savedState) {
        try {
          const parsedState = JSON.parse(savedState);
          
          if (parsedState && typeof parsedState === 'object') {
            setChatHistory(parsedState.chatHistory || []);
            setDiscussion(parsedState.discussion || '');
            setInputText(parsedState.inputText || '');
            setContextHistory(parsedState.contextHistory || []);
            setBeginDate(parsedState.beginDate || '');
            setShowWelcomeMenu(
              !parsedState.chatHistory || parsedState.chatHistory.length === 0
            );
            setIsChatInitialized(true);
          }
        } catch (error) {
          console.error('Error parsing saved chat state:', error);
          resetChat();
        }
      }
      
      // Mark initial load as complete
      setIsInitialLoadComplete(true);
    };

    loadInitialState();
  }, []);

  // Persist state changes to localStorage
  useEffect(() => {
    if (isChatInitialized && isInitialLoadComplete) {
      try {
        const stateToPersist = {
          chatHistory,
          discussion,
          inputText,
          contextHistory,
          beginDate
        };
        localStorage.setItem('chatDiscussionState', JSON.stringify(stateToPersist));
      } catch (error) {
        console.error('Error saving chat state:', error);
      }
    }
  }, [chatHistory, discussion, inputText, contextHistory, beginDate, isChatInitialized, isInitialLoadComplete]);

  const resetChat = () => {
    setChatHistory([]);
    setDiscussion('');
    setContextHistory([]);
    setBeginDate('');
    setShowWelcomeMenu(true);
    setIsChatInitialized(false);
    localStorage.removeItem('chatDiscussionState');
  };

  const loadDiscussionState = (discussionData) => {
    if (!discussionData) return;

    setDiscussion(discussionData.id);
    setChatHistory(discussionData.messages || []);
    setContextHistory(discussionData.contextHistory || []);
    setBeginDate(discussionData.beginDate || '');
    setShowWelcomeMenu(false);
    setIsChatInitialized(true);
  };

  return (
    <ChatContext.Provider value={{
      chatHistory,
      setChatHistory,
      discussion,
      setDiscussion,
      inputText,
      setInputText,
      showWelcomeMenu,
      setShowWelcomeMenu,
      contextHistory,
      setContextHistory,
      beginDate,
      setBeginDate,
      isChatInitialized,
      setIsChatInitialized,
      isInitialLoadComplete,
      resetChat,
      loadDiscussionState
    }}>
      {children}
    </ChatContext.Provider>
  );
};

export const useChat = () => useContext(ChatContext);