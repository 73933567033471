import styled from 'styled-components';
import theme from '../../theme';

export const ChatHistoryContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: ${props => props.isEmpty ? 'center' : 'flex-start'};
  align-items: center;
  height: 90%;
  width: 100%;
  overflow-y: auto;
  padding: ${theme.spacing.medium};
  margin-top: 20%; // Ajout de l'espacement depuis le haut
  background-color: ${theme.colors.background};
  border-radius: ${theme.spacing.small};
  position: relative;
  z-index: 99;

  /* Custom scrollbar styles */
  &::-webkit-scrollbar {
    width: 8px;
  }

  &::-webkit-scrollbar-thumb {
    background-color: ${({ theme }) => theme.colors.mediumGray};
    border-radius: 4px;
  }

  &::-webkit-scrollbar-thumb:hover {
    background-color: ${({ theme }) => theme.colors.darkGray};
  }
`;

export const Overlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 98;
  display: ${props => props.show ? 'block' : 'none'};
`;

export const UnauthenticatedMessage = styled.p`
  margin-top: 100%;
  text-align: center;
  color: ${theme.colors.text};
  cursor: pointer;
  transition: color 0.2s ease;
  max-width: 80%;

  &:hover {
    color: ${theme.colors.primary};
  }
`;

export const HistoryTitle = styled.h2`
  font-size: ${props => props.theme.fontSizes.large};
  font-weight: bold;
  margin-bottom: ${props => props.theme.spacing.medium};
  color: ${props => props.theme.colors.text};
`;

export const HistoryItem = styled.div`
  background-color: ${props => props.theme.colors.background};
  border-radius: ${props => props.theme.spacing.small};
  padding: ${props => props.theme.spacing.medium};
  margin-bottom: ${props => props.theme.spacing.small};
  box-shadow: 0 2px 4px rgba(0,0,0,0.1);
  cursor: pointer;
  transition: background-color 0.2s ease;
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%; // Assure une largeur compl�te
  min-height: 50px; // Hauteur minimale consistante

  &:hover {
    background-color: ${props => props.theme.colors.background};
    opacity: 0.8;
  }
`;

export const RenameInput = styled.input`
  flex: 1;
  padding: ${props => props.theme.spacing.small};
  margin-right: ${props => props.theme.spacing.small};
  border: 1px solid ${props => props.theme.colors.border};
  border-radius: ${props => props.theme.spacing.small};
  width: 0; // Permet au flex-grow de fonctionner correctement
  min-width: 0; // Emp�che l'input de d�border
`;

export const IconButton = styled.button`
  background: none;
  border: none;
  cursor: pointer;
  padding: ${props => props.theme.spacing.small};
  color: ${props => props.theme.colors.primary};
  transition: color 0.2s ease;
  flex-shrink: 0; // Emp�che le bouton de r�tr�cir

  &:hover {
    color: ${props => props.theme.colors.primary};
  }
`;

export const DiscussionName = styled.span`
  flex: 1;
  margin-right: ${props => props.theme.spacing.medium};
  white-space: nowrap; // Emp�che le texte de passer � la ligne
  overflow: hidden; // Cache le texte qui d�passe
  text-overflow: ellipsis; // Ajoute les points de suspension
  min-width: 0; // Permet la troncature dans un conteneur flex
`;