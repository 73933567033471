
// src/utils/api.js
import { getAuthToken, setAuthToken, getRefreshToken, setRefreshToken, logout } from './auth';


// Function to fetch the CSRF token, if needed
async function fetchCSRFToken() {
  const response = await fetch(`${process.env.REACT_APP_BACKEND_URL}/csrf/`, {
    credentials: 'include',
  });
  if (response.ok) {
    const token = getCookie('csrftoken');
    console.log('CSRF Token:', token); // Log the token in development
    return token;
  }
  throw new Error('Failed to fetch CSRF token');
}

// Helper function to get a cookie value
function getCookie(name) {
  let cookieValue = null;
  if (document.cookie && document.cookie !== '') {
    const cookies = document.cookie.split(';');
    for (let i = 0; i < cookies.length; i++) {
      const cookie = cookies[i].trim();
      if (cookie.substring(0, name.length + 1) === (name + '=')) {
        cookieValue = decodeURIComponent(cookie.substring(name.length + 1));
        break;
      }
    }
  }
  return cookieValue;
}

const apiCall = async (url, method, body = null, headers = {}, requireCSRF = false, isRetry = false) => {
  try {
    let csrfToken = null;
    if (requireCSRF) {
      csrfToken = await fetchCSRFToken();
    }

    let token = getAuthToken();
    if (token) {
      headers['Authorization'] = `Bearer ${token}`;
    }

    const options = {
      method,
      headers: {
        'Content-Type': 'application/json',
        ...(csrfToken && { 'X-CSRFToken': csrfToken }),
        ...headers,
      },
      credentials: 'include',
    };

    if (body) {
      options.body = JSON.stringify(body);
    }

    const response = await fetch(url, options);

    const contentType = response.headers.get('content-type');
    const responseText = await response.text();

    console.log('Response status:', response.status);
    console.log('Response headers:', Object.fromEntries(response.headers.entries()));
    console.log('Response text:', responseText);

    if (response.status === 401 && !isRetry) {
      // Attempt to refresh token
      const refreshToken = getRefreshToken();
      if (refreshToken) {
        try {
          const refreshResponse = await fetch(`${process.env.REACT_APP_BACKEND_URL}/auth/token/refresh/`, {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
            },
            body: JSON.stringify({ refresh: refreshToken }),
          });
          if (refreshResponse.ok) {
            const refreshData = JSON.parse(await refreshResponse.text());
            const newToken = refreshData.access;
            const newRefreshToken = refreshData.refresh;
            setAuthToken(newToken);
            setRefreshToken(newRefreshToken);
            // Retry the original request with the new token
            headers['Authorization'] = `Bearer ${newToken}`;
            options.headers['Authorization'] = `Bearer ${newToken}`;
            return apiCall(url, method, body, headers, requireCSRF, true); // Retry with isRetry = true
          } else {
            // Refresh failed, log out the user
            logout();
            throw new Error('Failed to refresh token');
          }
        } catch (error) {
          console.error('Error refreshing token:', error);
          logout();
          throw error;
        }
      } else {
        // No refresh token, log out the user
        logout();
        throw new Error('No refresh token available');
      }
    } else if (!response.ok) {
      throw new Error(`HTTP error! status: ${response.status}, message: ${responseText}`);
    }

    if (contentType && contentType.indexOf('application/json') !== -1) {
      try {
        return JSON.parse(responseText);
      } catch (parseError) {
        console.error('Error parsing JSON response:', parseError);
        return responseText;
      }
    } else {
      return responseText;
    }
  } catch (error) {
    console.error('API call error:', error);
    throw error;
  }
};

export default apiCall;