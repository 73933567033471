
import React, { useState, useEffect } from 'react';
import { ThemeProvider } from 'styled-components';
import { LoadingContainer, LoadingBar } from '../components/LoadingStyles';
import theme from '../theme';

const AppLoadingHandler = ({ children }) => {
  const [isStylesLoaded, setIsStylesLoaded] = useState(false);

  useEffect(() => {
    // Update viewport height
    const updateViewportHeight = () => {
      const vh = window.innerHeight * 0.01;
      document.documentElement.style.setProperty('--vh', `${vh}px`);
    };

    // Initialize viewport height
    updateViewportHeight();

    // Add event listeners
    window.addEventListener('resize', updateViewportHeight);
    window.addEventListener('orientationchange', () => {
      setTimeout(updateViewportHeight, 100);
    });

    // Force a microtask delay to ensure styles are processed
    Promise.resolve().then(() => {
      setTimeout(() => {
        setIsStylesLoaded(true);
      }, 100); // Small delay to ensure styles are processed
    });

    return () => {
      window.removeEventListener('resize', updateViewportHeight);
      window.removeEventListener('orientationchange', updateViewportHeight);
    };
  }, []);

  if (!isStylesLoaded) {
    return (
      <ThemeProvider theme={theme}>
        <LoadingContainer>
          <LoadingBar />
        </LoadingContainer>
      </ThemeProvider>
    );
  }

  return children;
};

export default AppLoadingHandler;