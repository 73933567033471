
// styles.js
import styled from 'styled-components';

// Layout Components
const Container = styled.div`
  display: flex;
  flex-direction: column;
  max-width: 800px;
  width: 100%;
  height: calc(var(--vh, 1vh) * 100);
  margin: 0 auto;
  padding: ${({ theme }) => theme.spacing.medium};
  background-color: ${({ theme }) => theme.colors.background};
  position: relative;
  overflow-x: hidden;

  @media (min-width: 768px) {
    padding: ${({ theme }) => theme.spacing.large};
    height: 90%;
  }
`;

const ContentWrapper = styled.div`
  flex: 1;
  overflow-y: auto;
  padding: ${({ theme }) => `${theme.spacing.medium} ${theme.spacing.medium}`};
  background-color: ${({ theme }) => theme.colors.veryLightGray};
  border-radius: 12px;
  margin: ${({ theme }) => theme.spacing.medium} 0;
  scrollbar-width: thin;
  scrollbar-color: ${({ theme }) => `${theme.colors.primary} ${theme.colors.lightGray}`};

  &::-webkit-scrollbar {
    width: 6px;
  }

  &::-webkit-scrollbar-track {
    background: ${({ theme }) => theme.colors.lightGray};
    border-radius: 3px;
  }

  &::-webkit-scrollbar-thumb {
    background-color: ${({ theme }) => theme.colors.primary};
    border-radius: 3px;
  }
`;

// Header Components
const Title = styled.h2`
  font-size: ${({ theme }) => theme.fontSizes.xlarge};
  color: ${({ theme }) => theme.colors.noir};
  margin: ${({ theme }) => theme.spacing.medium} 0;
  text-align: center;
  font-weight: ${({ theme }) => theme.fontWeights.bold};
  font-family: ${({ theme }) => theme.fonts.heading};
`;

const ReturnButton = styled.button`
  position: absolute;
  top: ${({ theme }) => theme.spacing.medium};
  left: ${({ theme }) => theme.spacing.medium};
  background: none;
  border: none;
  cursor: pointer;
  padding: ${({ theme }) => theme.spacing.small};
  color: ${({ theme }) => theme.colors.primary};
  transition: transform 0.2s ease;

  &:hover {
    transform: translateX(-2px);
  }

  svg {
    font-size: ${({ theme }) => theme.fontSizes.xlarge};
  }

  @media (min-width: 768px) {
    top: ${({ theme }) => theme.spacing.large};
    left: ${({ theme }) => theme.spacing.large};
  }
`;

// Section Components
const SettingsSection = styled.section`
  background: ${({ theme }) => theme.colors.background};
  padding: ${({ theme }) => theme.spacing.medium};
  border-radius: 8px;
  margin-bottom: ${({ theme }) => theme.spacing.large};
  box-shadow: 0 2px 8px rgba(255, 39, 104, 0.1);
`;

const SectionTitle = styled.h3`
  font-size: ${({ theme }) => theme.fontSizes.large};
  color: ${({ theme }) => theme.colors.noir};
  margin-bottom: ${({ theme }) => theme.spacing.medium};
  padding-bottom: ${({ theme }) => theme.spacing.small};
  border-bottom: 2px solid ${({ theme }) => theme.colors.accent};
  font-weight: ${({ theme }) => theme.fontWeights.semiBold};
  font-family: ${({ theme }) => theme.fonts.heading};
`;

// User Info Components
const UserInfo = styled.div`
  display: grid;
  gap: ${({ theme }) => theme.spacing.medium};
  background-color: ${({ theme }) => theme.colors.lightGray};
  padding: ${({ theme }) => theme.spacing.medium};
  border-radius: 8px;
`;

const SettingsItem = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: ${({ theme }) => theme.spacing.small};
  background: ${({ theme }) => theme.colors.background};
  border-radius: 6px;
`;

const SettingsLabel = styled.span`
  font-size: ${({ theme }) => theme.fontSizes.medium};
  color: ${({ theme }) => theme.colors.gris};
  font-weight: ${({ theme }) => theme.fontWeights.medium};
`;

const SettingsValue = styled.span`
  font-size: ${({ theme }) => theme.fontSizes.medium};
  color: ${({ theme }) => theme.colors.primary};
  font-weight: ${({ theme }) => theme.fontWeights.semiBold};
  word-break: break-word;
  text-align: right;
  margin-left: ${({ theme }) => theme.spacing.small};
`;

// Button Components
const BaseButton = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  padding: ${({ theme }) => `${theme.spacing.medium} ${theme.spacing.large}`};
  border: none;
  border-radius: 8px;
  font-size: ${({ theme }) => theme.fontSizes.medium};
  font-weight: ${({ theme }) => theme.fontWeights.medium};
  cursor: pointer;
  transition: all 0.2s ease;
  gap: ${({ theme }) => theme.spacing.small};

  &:disabled {
    opacity: 0.6;
    cursor: not-allowed;
  }

  svg {
    font-size: 1.2em;
  }
`;

const PrimaryButton = styled(BaseButton)`
  background-color: ${({ theme }) => theme.colors.primary};
  color: ${({ theme }) => theme.colors.lightText};

  &:hover:not(:disabled) {
    background-color: ${({ theme }) => theme.colors.secondary};
    transform: translateY(-1px);
  }

  &:active:not(:disabled) {
    transform: translateY(0);
  }
`;

const RestartQuizButton = styled(PrimaryButton)`
  margin-bottom: ${({ theme }) => theme.spacing.medium};
`;

const LogoutButton = styled(PrimaryButton)`
  background-color: ${({ theme }) => theme.colors.gris};
  
  &:hover:not(:disabled) {
    background-color: ${({ theme }) => theme.colors.gris_clair};
  }
`;

const DeleteAccountButton = styled(PrimaryButton)`
  background-color: #dc3545;
  margin-top: ${({ theme }) => theme.spacing.medium};

  &:hover:not(:disabled) {
    background-color: #bd2130;
  }
`;

const CGUButton = styled(PrimaryButton)`
  background-color: ${({ theme }) => theme.colors.accent};
  margin-top: ${({ theme }) => theme.spacing.medium};

  &:hover:not(:disabled) {
    background-color: ${({ theme }) => theme.colors.secondary};
  }
`;

// Modal Components
const ModalOverlay = styled.div`
  display: ${({ isOpen }) => (isOpen ? 'flex' : 'none')};
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  justify-content: center;
  align-items: center;
  z-index: 1000;
  backdrop-filter: blur(3px);
`;

const ModalContent = styled.div`
  background-color: ${({ theme }) => theme.colors.background};
  padding: ${({ theme }) => theme.spacing.large};
  border-radius: 12px;
  max-width: 90%;
  width: 400px;
  box-shadow: 0 4px 20px rgba(0, 0, 0, 0.15);

  h2 {
    font-size: ${({ theme }) => theme.fontSizes.large};
    color: ${({ theme }) => theme.colors.noir};
    margin-bottom: ${({ theme }) => theme.spacing.medium};
    font-weight: ${({ theme }) => theme.fontWeights.bold};
  }

  p {
    color: ${({ theme }) => theme.colors.gris};
    margin-bottom: ${({ theme }) => theme.spacing.medium};
    line-height: 1.5;
  }
`;

const ModalButtons = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: ${({ theme }) => theme.spacing.medium};
  margin-top: ${({ theme }) => theme.spacing.large};
`;

const ConfirmButton = styled(BaseButton)`
  background-color: ${({ theme }) => theme.colors.primary};
  color: ${({ theme }) => theme.colors.lightText};
  padding: ${({ theme }) => theme.spacing.small};

  &:hover {
    background-color: ${({ theme }) => theme.colors.secondary};
  }
`;

const CancelButton = styled(BaseButton)`
  background-color: ${({ theme }) => theme.colors.gris_clair};
  color: ${({ theme }) => theme.colors.noir};
  padding: ${({ theme }) => theme.spacing.small};

  &:hover {
    background-color: ${({ theme }) => theme.colors.gris_clair_clair};
  }
`;

const WarningText = styled.p`
  color: #dc3545;
  margin-bottom: ${({ theme }) => theme.spacing.medium};
  font-weight: ${({ theme }) => theme.fontWeights.medium};
  text-align: center;
  padding: ${({ theme }) => theme.spacing.medium};
  background-color: rgba(220, 53, 69, 0.1);
  border-radius: 6px;
`;

// Social Links Components
const SocialLinksContainer = styled.div`
  display: flex;
  justify-content: center;
  gap: ${({ theme }) => theme.spacing.medium};
  margin-bottom: calc(max(7vh, 70px)) ;
`;

const SocialLink = styled.a`
  color: ${({ theme }) => theme.colors.primary};
  transition: all 0.2s ease;
  padding: ${({ theme }) => theme.spacing.small};
  border-radius: 50%;
  background-color: ${({ theme }) => theme.colors.background};
  display: flex;
  align-items: center;
  justify-content: center;

  &:hover {
    color: ${({ theme }) => theme.colors.secondary};
    transform: translateY(-2px);
    box-shadow: 0 2px 8px rgba(255, 39, 104, 0.2);
  }

  svg {
    font-size: ${({ theme }) => theme.fontSizes.xlarge};
  }
`;

// Support Form Components
const SupportForm = styled.form`
  display: flex;
  flex-direction: column;
  gap: ${({ theme }) => theme.spacing.medium};
`;

const TextArea = styled.textarea`
  width: 100%;
  min-height: 110px;
  padding: ${({ theme }) => theme.spacing.medium};
  border: 2px solid ${({ theme }) => theme.colors.lightGray};
  border-radius: 8px;
  font-family: ${({ theme }) => theme.fonts.main};
  resize: vertical;
  transition: border-color 0.2s ease;

  &:focus {
    outline: none;
    border-color: ${({ theme }) => theme.colors.primary};
  }

  &:disabled {
    background-color: ${({ theme }) => theme.colors.lightGray};
    cursor: not-allowed;
  }
`;

const MessageStatus = styled.p`
  text-align: center;
  padding: ${({ theme }) => theme.spacing.small};
  border-radius: 4px;
  font-size: ${({ theme }) => theme.fontSizes.small};
  color: ${({ success }) => (success ? '#28a745' : '#dc3545')};
  background-color: ${({ success }) => (success ? 'rgba(40, 167, 69, 0.1)' : 'rgba(220, 53, 69, 0.1)')};
`;

export {
  Container,
  ContentWrapper,
  Title,
  ReturnButton,
  SettingsSection,
  SectionTitle,
  UserInfo,
  SettingsItem,
  SettingsLabel,
  SettingsValue,
  RestartQuizButton,
  LogoutButton,
  DeleteAccountButton,
  CGUButton,
  ModalOverlay,
  ModalContent,
  ModalButtons,
  ConfirmButton,
  CancelButton,
  WarningText,
  SocialLinksContainer,
  SocialLink,
  SupportForm,
  TextArea,
  MessageStatus,
};
