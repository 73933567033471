import React, { useState, useEffect, useCallback } from 'react';
import axios from 'axios';
import { ChatHistoryContainer, HistoryItem, RenameInput, IconButton, DiscussionName, UnauthenticatedMessage, Overlay } from './styles';
import { useAuth } from '../../contexts/AuthContext';
import { Edit, Check, X } from 'lucide-react';
import PopUp from '../SaveLoginPopup';

const ChatHistory = ({ onDiscussionClick, isOpen, onClose }) => {
  const [discussions, setDiscussions] = useState([]);
  const [error, setError] = useState(null);
  const { isAuthenticated, token } = useAuth();
  const [renamingId, setRenamingId] = useState(null);
  const [newName, setNewName] = useState('');
  const [isPopUpOpen, setIsPopUpOpen] = useState(false);

  const fetchDiscussions = useCallback(async () => {
    if (isAuthenticated && token) {
      try {
        console.log("Fetching discussions...");
        const config = {
          method: 'get',
          url: `${process.env.REACT_APP_BACKEND_URL}/inference/get_user_discussions/`,
          headers: {
            'Authorization': `Bearer ${token}`,
            'Content-Type': 'application/json',
          },
        };
        console.log("Request config:", JSON.stringify(config, null, 2));
        const response = await axios(config);
        console.log("Discussions fetched:", response.data);
        setDiscussions(response.data);
        setError(null);
      } catch (error) {
        console.error('Error fetching discussions:', error);
        setDiscussions([]);
        setError(error.response?.data?.error || 'Error fetching discussions');
      }
    } else {
      console.log("User is not logged in or token is missing");
      setDiscussions([]);
    }
  }, [isAuthenticated, token]);

  useEffect(() => {
    fetchDiscussions();
  }, [fetchDiscussions]);

  const handleItemClick = useCallback((discussion) => {
    console.log('Discussion clicked:', discussion);
    if (onDiscussionClick) {
      const discussionData = {
        id: discussion.id,
        name: discussion.name || `Discussion ${discussion.id}`,
      };
      onDiscussionClick(discussionData);
    }
  }, [onDiscussionClick]);

  const startRenaming = (discussion, e) => {
    e.stopPropagation();
    setRenamingId(discussion.id);
    setNewName(discussion.name || `Discussion ${discussion.id}`);
  };

  const handleRename = async (discussionId, e) => {
    e.stopPropagation();
    if (isAuthenticated && token && newName.trim() !== '') {
      try {
        const response = await axios.post(
          `${process.env.REACT_APP_BACKEND_URL}/inference/rename_discussion/`,
          {
            discussion_id: discussionId,
            new_name: newName.trim(),
          },
          {
            headers: {
              'Authorization': `Bearer ${token}`,
              'Content-Type': 'application/json',
            },
          }
        );

        if (response.data.success) {
          await fetchDiscussions();
        }
      } catch (error) {
        console.error('Error renaming discussion:', error);
        setError('Error renaming discussion');
      }
    }
    setRenamingId(null);
  };

  const handleKeyPress = (event, discussionId) => {
    if (event.key === 'Enter') {
      handleRename(discussionId, event);
    }
  };

  const cancelRenaming = (e) => {
    e.stopPropagation();
    setRenamingId(null);
    setNewName('');
  };

  const handleOverlayClick = useCallback((e) => {
    if (e.target === e.currentTarget) {
      onClose();
    }
  }, [onClose]);

  return (
    <>
      <Overlay show={isOpen} onClick={handleOverlayClick} />
      <ChatHistoryContainer isEmpty={!isAuthenticated || discussions.length === 0}>
        {error && <p className="error-message">{error}</p>}
        {!isAuthenticated ? (
          <UnauthenticatedMessage onClick={() => setIsPopUpOpen(true)}>
            Voir son historique.
          </UnauthenticatedMessage>
        ) : discussions.length === 0 ? (
          <p>Aucune discussion r�cente</p>
        ) : (
          discussions.map((discussion) => (
            <HistoryItem
              key={discussion.id}
              onClick={() => handleItemClick(discussion)}
            >
              {renamingId === discussion.id ? (
                <>
                  <RenameInput
                    value={newName}
                    onChange={(e) => setNewName(e.target.value)}
                    onKeyPress={(e) => handleKeyPress(e, discussion.id)}
                    onClick={(e) => e.stopPropagation()}
                  />
                  <IconButton onClick={(e) => handleRename(discussion.id, e)}>
                    <Check size={18} />
                  </IconButton>
                  <IconButton onClick={cancelRenaming}>
                    <X size={18} />
                  </IconButton>
                </>
              ) : (
                <>
                  <DiscussionName>
                    {discussion.name || `Discussion ${discussion.id}`}
                  </DiscussionName>
                  <IconButton onClick={(e) => startRenaming(discussion, e)}>
                    <Edit size={18} />
                  </IconButton>
                </>
              )}
            </HistoryItem>
          ))
        )}
        <PopUp
          isOpen={isPopUpOpen}
          onClose={() => setIsPopUpOpen(false)}
          message="Connectez-vous pour voir votre historique de discussion."
        />
      </ChatHistoryContainer>
    </>
  );
};

export default ChatHistory;