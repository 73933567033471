import React from 'react';
import { Trophy, Medal, Award } from 'lucide-react';
import {
  PodiumContainer,
  FormationName,
  FirstPlace,
  SecondPlace,
  ThirdPlace,
  CardContent,
  IconWrapper,
  PlaceLabel,
  PodiumCard,
} from './styles';

const TutorialPodium = ({ results }) => {
  const [first, second, third] = results;

  // Prevent any interactions by default
  const handleClick = (e) => {
    e.preventDefault();
    e.stopPropagation();
  };

  const renderPodiumStep = (result, place, Icon, StepComponent) => (
    <StepComponent 
      onClick={handleClick} 
      style={{ cursor: 'default' }} // Remove pointer cursor
      className="tutorial-step" // Add tutorial class for potential styling
    >
      <IconWrapper>
        <Icon size={24} />
      </IconWrapper>
      <FormationName>{result.formation}</FormationName>
      <PlaceLabel>{place}</PlaceLabel>
    </StepComponent>
  );

  return (
    <PodiumCard>
      <CardContent>
        <PodiumContainer>
          {renderPodiumStep(second, '', Medal, SecondPlace)}
          {renderPodiumStep(first, '', Trophy, FirstPlace)}
          {renderPodiumStep(third, '', Award, ThirdPlace)}
        </PodiumContainer>
      </CardContent>
    </PodiumCard>
  );
};

export default TutorialPodium;