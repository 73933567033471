
import React from 'react';
import { useNavigate } from 'react-router-dom';
import { PopUpContainer, PopUpContent, CloseButton, LoginLink } from './styles';

const PopUp = ({ isOpen, onClose, message, onLogin }) => {
  const navigate = useNavigate();

  const handleLogin = () => {
    if (onLogin) {
      onLogin();
    } else {
      navigate('/classement');
    }
    onClose();
  };

  if (!isOpen) return null;

  return (
    <PopUpContainer>
      <PopUpContent>
        <CloseButton onClick={onClose}>&times;</CloseButton>
        <p>{message || "Connecte toi pour voir tes discussions."}</p>
        <LoginLink onClick={handleLogin}>Se connecter</LoginLink>
      </PopUpContent>
    </PopUpContainer>
  );
};

export default PopUp;