import React from 'react';
import { Share2 } from 'lucide-react';

const ShareButton = ({ onClick }) => (
  <button
    onClick={onClick}
    style={{
      display: 'flex',
      alignItems: 'center',
      gap: '8px',
      background: '#FFD700',
      color: '#333',
      border: 'none',
      borderRadius: '8px',
      padding: '12px 24px',
      fontWeight: '600',
      cursor: 'pointer',
      transition: 'all 0.2s ease',
      marginTop: '16px',
    }}
  >
    <Share2 size={20} />
    Share My Top Schools
  </button>
);

const generateImage = async (rankings) => {
  // Create canvas
  const canvas = document.createElement('canvas');
  const ctx = canvas.getContext('2d');
  
  // Set canvas size
  canvas.width = 600;
  canvas.height = 400;
  
  // Set background
  ctx.fillStyle = '#fff9e6';
  ctx.fillRect(0, 0, canvas.width, canvas.height);
  
  // Add gradient background
  const gradient = ctx.createLinearGradient(0, 0, canvas.width, canvas.height);
  gradient.addColorStop(0, '#fff9e6');
  gradient.addColorStop(1, '#fff5cc');
  ctx.fillStyle = gradient;
  ctx.fillRect(0, 0, canvas.width, canvas.height);
  
  // Add title
  ctx.fillStyle = '#333';
  ctx.font = 'bold 24px Arial';
  ctx.textAlign = 'center';
  ctx.fillText('My Top 3 School Matches', canvas.width / 2, 40);
  
  // Medal colors
  const medalColors = {
    1: '#FFD700', // Gold
    2: '#C0C0C0', // Silver
    3: '#CD7F32'  // Bronze
  };
  
  // Draw schools
  const topSchools = Object.entries(rankings).slice(0, 3);
  topSchools.forEach(([key, school], index) => {
    const rank = parseInt(key.replace('top', ''));
    const yPosition = 100 + (index * 90);
    
    // Draw medal background
    ctx.fillStyle = medalColors[rank] + '20'; // 20 is hex for 12% opacity
    ctx.beginPath();
    ctx.roundRect(30, yPosition - 10, 540, 70, 10);
    ctx.fill();
    
    // Draw rank medal
    ctx.fillStyle = medalColors[rank];
    ctx.beginPath();
    ctx.arc(60, yPosition + 25, 20, 0, 2 * Math.PI);
    ctx.fill();
    ctx.fillStyle = '#fff';
    ctx.font = 'bold 20px Arial';
    ctx.textAlign = 'center';
    ctx.fillText(rank.toString(), 60, yPosition + 32);
    
    // Draw school name
    ctx.fillStyle = '#333';
    ctx.font = 'bold 18px Arial';
    ctx.textAlign = 'left';
    ctx.fillText(school.name, 100, yPosition + 20);
    
    // Draw formation
    ctx.fillStyle = '#666';
    ctx.font = '14px Arial';
    ctx.fillText(school.formation, 100, yPosition + 45);
    
    // Draw city
    ctx.textAlign = 'right';
    ctx.fillText(school.city, 550, yPosition + 45);
  });
  
  // Add footer
  ctx.fillStyle = '#666';
  ctx.font = '14px Arial';
  ctx.textAlign = 'center';
  ctx.fillText('Find your perfect school match at yourdomain.com', canvas.width / 2, 360);
  
  return canvas.toDataURL('image/png');
};

const ShareTopSchools = ({ rankings }) => {
  const handleShare = async () => {
    try {
      // Generate the image
      const dataUrl = await generateImage(rankings);
      
      // Convert data URL to Blob
      const response = await fetch(dataUrl);
      const blob = await response.blob();
      
      // Try to share the image
      if (navigator.share && navigator.canShare) {
        try {
          await navigator.share({
            title: 'My Top School Matches',
            text: 'Check out my top school matches!',
            files: [new File([blob], 'top-schools.png', { type: 'image/png' })]
          });
          return;
        } catch (shareError) {
          console.log('File sharing failed, falling back to text sharing');
        }
      }
      
      // Fallback to text sharing
      const textContent = `My Top 3 School Matches:\n\n${
        Object.entries(rankings)
          .slice(0, 3)
          .map(([key, school]) => 
            `${parseInt(key.replace('top', ''))}. ${school.name} - ${school.formation} (${school.city})`
          )
          .join('\n')
      }\n\nFind your perfect school match at yourdomain.com`;
      
      if (navigator.share) {
        await navigator.share({
          title: 'My Top School Matches',
          text: textContent
        });
      } else {
        // Final fallback: download the image
        const link = document.createElement('a');
        link.href = dataUrl;
        link.download = 'top-schools.png';
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
      }
    } catch (error) {
      console.error('Error sharing:', error);
      // Final fallback: copy to clipboard
      try {
        const textContent = `My Top 3 School Matches:\n\n${
          Object.entries(rankings)
            .slice(0, 3)
            .map(([key, school]) => 
              `${parseInt(key.replace('top', ''))}. ${school.name} - ${school.formation} (${school.city})`
            )
            .join('\n')
        }`;
        await navigator.clipboard.writeText(textContent);
        alert('Image sharing failed. Text copied to clipboard instead!');
      } catch (clipboardError) {
        alert('Unable to share. Please try again later.');
      }
    }
  };

  return <ShareButton onClick={handleShare} />;
};

export default ShareTopSchools;