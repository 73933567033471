// src/components/MobileHistoryTab/styles.js
import styled from 'styled-components';

export const Overlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 998;
  display: ${props => props.$isOpen ? 'block' : 'none'};
`;

export const MobileHistoryContainer = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 67%;
  height: 100%;
  background-color: ${props => props.theme.colors.background};
  box-shadow: 2px 0 5px rgba(0, 0, 0, 0.1);
  transform: translateX(${props => props.$isOpen ? '0' : '-100%'});
  transition: transform 0.3s ease-in-out;
  display: flex;
  flex-direction: column;
  overflow-y: auto;
  z-index: 999;
`;

export const HistoryContent = styled.div`
  padding-top: ${props => props.theme.spacing.xxlarge};
  height: 100%;
  overflow-y: auto;

  /* Custom scrollbar styles */
  &::-webkit-scrollbar {
    width: 8px;
  }

  &::-webkit-scrollbar-thumb {
    background-color: ${({ theme }) => theme.colors.mediumGray};
    border-radius: 4px;
  }

  &::-webkit-scrollbar-thumb:hover {
    background-color: ${({ theme }) => theme.colors.darkGray};
  }
`;

export const MobileMenuButtonWrapper = styled.div`
  position: fixed;
  top: ${props => props.theme.spacing.medium};
  left: ${props => props.isMobile ? '10px' : '20px'};
  z-index: 1000;
  transition: transform 0.3s ease-in-out;
  transform: none;
`;

export const HistoryList = styled.ul`
  list-style-type: none;
  padding: ${props => props.theme.spacing.medium};
  margin: 0;
`;

export const HistoryItem = styled.li`
  padding: ${props => props.theme.spacing.small} 0;
  border-bottom: 1px solid ${props => props.theme.colors.lightGray};
  cursor: pointer;
  transition: background-color 0.2s ease;

  &:hover {
    background-color: ${props => props.theme.colors.veryLightGray};
  }

  &:last-child {
    border-bottom: none;
  }

`;

export const HistoryItemText = styled.span`
  color: ${props => props.theme.colors.text};
  font-size: ${props => props.theme.fontSizes.medium};
  font-weight: ${props => props.theme.fontWeights.regular};
`;

export const EmptyHistoryMessage = styled.p`
  color: ${props => props.theme.colors.mediumGray};
  font-size: ${props => props.theme.fontSizes.medium};
  text-align: center;
  padding: ${props => props.theme.spacing.large};
`;
