
// components/BottomMenu/styles.js
import styled, { css, keyframes } from 'styled-components';
import theme from '../../theme';

const pulseAnimation = keyframes`
  0% { transform: scale(1); }
  50% { transform: scale(1.1); }
  100% { transform: scale(1); }
`;

const glowAnimation = keyframes`
  0% { box-shadow: 0 0 10px 3px rgba(255, 255, 255, 0.3); }
  50% { box-shadow: 0 0 20px 5px rgba(255, 255, 255, 0.5); }
  100% { box-shadow: 0 0 10px 3px rgba(255, 255, 255, 0.3); }
`;

export const BottomMenuContainer = styled.div`
  position: fixed;
  z-index: 1600;
  bottom: 0;
  left: 0;
  right: 0;
  height: calc(max(7vh, 70px));
  background-color: ${props => props.theme.colors.background};
  display: flex;
  justify-content: space-around;
  align-items: center;
  box-shadow: 0 -2px 5px rgba(0, 0, 0, 0.1);
  flex-grow: 1;
  border-top-left-radius: 30px;
  border-top-right-radius: 30px;

  ${props => props.$tutorialActive && css`
    &::before {
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      backdrop-filter: blur(3px);
      pointer-events: none;
      border-top-left-radius: 30px;
      border-top-right-radius: 30px;
    }
  `}
`;

export const MenuText = styled.span`
  display: block;
  font-size: 12px;
  margin-top: 2px;
  font-family: ${theme.fonts.main};
  color: ${props => props.active ? props.theme.colors.primary : props.theme.colors.mediumGray};
  transition: color 0.3s ease;
`;

export const MenuButton = styled.button`
  background: none;
  border: none;
  cursor: pointer;
  padding: 0;
  opacity: ${props => props.active ? 1 : 0.5};
  transition: all 0.3s ease;
  color: ${props => props.active ? props.theme.colors.primary : props.theme.colors.text};
  position: relative;
  width: 33.33%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;

  > div {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }

  &:focus {
    outline: none;
  }

  -webkit-tap-highlight-color: transparent;

  &:focus-visible {
    box-shadow: 0 0 0 2px ${props => props.theme.colors.primary};
  }

  &:hover {
    color: ${props => props.theme.colors.secondary};
  }

  ${props => props.isBlurred && css`
    opacity: 0.3;
    pointer-events: none;
    filter: blur(1px);
  `}

  ${props => props.isHighlighted && css`
    opacity: 1;
    pointer-events: auto;
    filter: none;
    position: relative;
    z-index: 1601;

    &::before {
      content: '';
      position: absolute;
      top: -15px;
      left: -15px;
      right: -15px;
      bottom: -15px;
      background: radial-gradient(
        circle at center,
        rgba(255, 255, 255, 0.2) 0%,
        rgba(255, 255, 255, 0.1) 50%,
        transparent 70%
      );
      border-radius: 50%;
      z-index: -1;
    }
  `}

  ${props => props.hasAnimation && css`
    animation: ${pulseAnimation} 2s infinite;

    &::before {
      animation: ${glowAnimation} 2s infinite;
    }

    svg {
      filter: drop-shadow(0 0 5px rgba(255, 255, 255, 0.5));
    }
  `}
`;
