// src/components/LandingPage/index.js
import React, { useState, useEffect, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import useDeviceType from '../useDeviceType';
import Meo from '../../assets/meo.png';
import QuizScreen from '../../assets/screen_quiz_phone.png';
import ChatScreen from '../../assets/screen_chat_desktop.png';
import { FaTiktok, FaInstagram } from "react-icons/fa";
import { BsYoutube, BsLinkedin } from "react-icons/bs";

import {
  EnhancedContainer,
  Section,
  SectionContent,
  HorizontalContainer,
  LeftContent,
  RightContent,
  PrimaryButton,
  PrimaryButtonNav,
  ButtonContainer,
  SecondaryButton,
  SocialLinksContainer,
  SocialLink,
  DynamicNavBar,
  DynamicNavLogo,
  MainTitle,
  MainSubtitle,
} from './styles';

const LandingPage = () => {
  const [isTyping, setIsTyping] = useState(true);
  const [text, setText] = useState('');
  const [showNavButton, setShowNavButton] = useState(false);
  const [hasScrolled, setHasScrolled] = useState(false);
  const fullText = "Tu es prêt à prendre en main ton orientation avec moi ?";
  const { isMobile } = useDeviceType();
  const navigate = useNavigate();
  const primaryButtonRef = useRef(null);
  const navBarHeight = 63;
  const scrollThreshold = 0; // Threshold for showing nav button

  useEffect(() => {
    // Text typing effect
    let currentIndex = 0;
    const typingInterval = setInterval(() => {
      if (currentIndex <= fullText.length) {
        setText(fullText.slice(0, currentIndex));
        currentIndex++;
      } else {
        setIsTyping(false);
        clearInterval(typingInterval);
      }
    }, 15);

    // Intersection Observer for the primary button
    const observer = new IntersectionObserver(
      ([entry]) => {
        requestAnimationFrame(() => {
          // Add scrollThreshold to make the button appear later
          const buttonRect = entry.boundingClientRect;
          const isHidden = buttonRect.bottom <= (navBarHeight + scrollThreshold);
          setShowNavButton(isHidden);
        });
      },
      {
        root: null,
        rootMargin: `-${navBarHeight + scrollThreshold}px 0px 0px 0px`,
        threshold: 0,
      }
    );

    if (primaryButtonRef.current) {
      observer.observe(primaryButtonRef.current);
    }

    // Scroll handler with immediate update
    const handleScroll = () => {
      requestAnimationFrame(() => {
        setHasScrolled(window.scrollY > 1); // Detect even slight scroll
      });
    };

    window.addEventListener('scroll', handleScroll);

    return () => {
      clearInterval(typingInterval);
      if (primaryButtonRef.current) {
        observer.unobserve(primaryButtonRef.current);
      }
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  useEffect(() => {
    const handleScroll = () => {
      console.log('Scroll event fired');
      console.log('window.scrollY:', window.scrollY);
      console.log('document.documentElement.scrollTop:', document.documentElement.scrollTop);
      console.log('document.body.scrollTop:', document.body.scrollTop);
      
      requestAnimationFrame(() => {
        const newHasScrolled = window.scrollY > 1;
        console.log('Setting hasScrolled to:', newHasScrolled);
        console.log('Previous hasScrolled was:', hasScrolled);
        setHasScrolled(newHasScrolled);
      });
    };

    // Log when effect is mounted
    console.log('Scroll effect mounted');
    
    window.addEventListener('scroll', handleScroll);
    
    // Test if scroll is possible
    console.log('Document height:', document.documentElement.scrollHeight);
    console.log('Window height:', window.innerHeight);
    console.log('Is scroll possible:', document.documentElement.scrollHeight > window.innerHeight);

    return () => {
      console.log('Scroll effect cleanup');
      window.removeEventListener('scroll', handleScroll);
    };
  }, [hasScrolled]);

  // Add this outside the effect to monitor hasScrolled changes
  useEffect(() => {
    console.log('hasScrolled changed to:', hasScrolled);
  }, [hasScrolled]);

  const handleCreateAccount = () => {
    navigate('/presentation-meo');
  };

  const handleExistingAccount = () => {
    navigate('/login');
  };

  return (
    <EnhancedContainer>
      <DynamicNavBar $showShadow={hasScrolled}>
        <DynamicNavLogo $centered={!showNavButton}>
          MEORIA
        </DynamicNavLogo>
        {showNavButton && (
          <PrimaryButtonNav onClick={handleCreateAccount}>
            C'est parti !
          </PrimaryButtonNav>
        )}
      </DynamicNavBar>

      {/* Hero Section */}
      <Section $hero>
        <HorizontalContainer>
          <SectionContent style={{ width: '60%' }}>
            <MainTitle>
              Qui de mieux qu'un petit singe rose pour te guider dans la jungle de l'orientation ?
            </MainTitle>

            <ButtonContainer ref={primaryButtonRef}>
              <PrimaryButton onClick={handleCreateAccount}>
                C'est parti !
              </PrimaryButton>
              <SecondaryButton onClick={handleExistingAccount}>
                J'ai déjà un compte
              </SecondaryButton>
            </ButtonContainer>
          </SectionContent>
          <RightContent style={{ width: 'min(200px, 20%)' }}>
            <img src={Meo} alt="Monkey Counselor" />
          </RightContent>
        </HorizontalContainer>
      </Section>

      {/* Quiz Section */}
      <Section>
        <HorizontalContainer>
          <LeftContent style={{ width: '55%' }}>
            <img src={QuizScreen} alt="Quiz Interface" />
          </LeftContent>

          <SectionContent style={{ width: '45%', paddingLeft: '64px', paddingRight: '0' }}>
            <MainTitle>
              Swipe pour trouver la formation qui te correspond !
            </MainTitle>
            <MainSubtitle>
              Une expérience interactive et personnalisée pour découvrir ton parcours idéal.
            </MainSubtitle>
          </SectionContent>
        </HorizontalContainer>
      </Section>

      {/* Chat Section */}
      <Section>
        <HorizontalContainer>
          <SectionContent style={{ width: '45%', paddingRight: '64px', paddingLeft: '0' }}>
            <MainTitle>
              Discute avec Meo pour des conseils personnalisés
            </MainTitle>
            <MainSubtitle>
              Obtenez des recommandations et des réponses instantanées pour vous orienter efficacement.
            </MainSubtitle>
          </SectionContent>
          <RightContent style={{ width: 'min(200px, 20%)' }}>
            <img src={ChatScreen} alt="Chat Interface" className="chat-screen" />
          </RightContent>
        </HorizontalContainer>
      </Section>

      {/* Social Links */}
      <SocialLinksContainer>
        <SocialLink href="https://www.tiktok.com/@meoria_app" target="_blank" rel="noopener noreferrer">
          <FaTiktok />
        </SocialLink>
        <SocialLink href="https://www.instagram.com/meoria_app/" target="_blank" rel="noopener noreferrer">
          <FaInstagram />
        </SocialLink>
        <SocialLink href="https://www.youtube.com/@meoria_app" target="_blank" rel="noopener noreferrer">
          <BsYoutube />
        </SocialLink>
        <SocialLink href="https://www.linkedin.com/company/meoria" target="_blank" rel="noopener noreferrer">
          <BsLinkedin />
        </SocialLink>
      </SocialLinksContainer>
    </EnhancedContainer>
  );
};

export default LandingPage;
