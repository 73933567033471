import React, { createContext, useState, useContext, useEffect } from 'react';

const QuizContext = createContext();

export const QuizProvider = ({ children }) => {
  // Question and progress state
  const [currentQuestion, setCurrentQuestion] = useState(null);
  const [quizProgress, setQuizProgress] = useState({
    quiz1_progress: 0,
    quiz1_total: 0,
    quiz2_progress: 0,
    quiz2_total: 0
  });
  const [quizId, setQuizId] = useState(null);

  // Quiz status flags
  const [isQuizInitialized, setIsQuizInitialized] = useState(false);
  //log.error('isQuizInitialized', isQuizInitialized);
  const [quizCompleted, setQuizCompleted] = useState(false);
  const [isQuizPaused, setIsQuizPaused] = useState(false);
  const [showQuiz1CompletePopup, setShowQuiz1CompletePopup] = useState(false);
  const [showQuiz2ProgressPopup, setShowQuiz2ProgressPopup] = useState(false);

  // Results
  const [quizResult, setQuizResult] = useState(null);

  // Regions
  const [regionsCompleted, setRegionsCompleted] = useState(false);
  const [regions, setRegions] = useState([]);
  const [regionsLoading, setRegionsLoading] = useState(true);

  // Load saved state from localStorage on initial mount
  useEffect(() => {
    const savedState = localStorage.getItem('quizState');
    if (savedState) {
      const {
        currentQuestion: savedQuestion,
        quizProgress: savedProgress,
        quizId: savedQuizId,
        isQuizInitialized: savedInitialized,
        quizCompleted: savedCompleted,
        isQuizPaused: savedPaused,
        quizResult: savedResult,
        regionsCompleted: savedRegionsCompleted,
        regions: savedRegions,
        regionsLoading: savedRegionsLoading,
      } = JSON.parse(savedState);

      if (savedQuestion) setCurrentQuestion(savedQuestion);
      if (savedProgress) setQuizProgress(savedProgress);
      if (savedQuizId) setQuizId(savedQuizId);
      if (savedInitialized) setIsQuizInitialized(savedInitialized);
      if (savedCompleted) setQuizCompleted(savedCompleted);
      if (savedPaused) setIsQuizPaused(savedPaused);
      if (savedResult) setQuizResult(savedResult);
      if (savedRegionsCompleted) setRegionsCompleted(savedRegionsCompleted);
      if (savedRegions) setRegions(savedRegions);
      if (savedRegionsLoading !== undefined) setRegionsLoading(savedRegionsLoading);
    }
  }, []);

  // Save state to localStorage whenever it changes
  useEffect(() => {
    if (isQuizInitialized) {
      const stateToPersist = {
        currentQuestion,
        quizProgress,
        quizId,
        isQuizInitialized,
        quizCompleted,
        isQuizPaused,
        quizResult,
        regions,
        regionsLoading,
      };
      localStorage.setItem('quizState', JSON.stringify(stateToPersist));
    }
  }, [
    currentQuestion,
    quizProgress,
    quizId,
    isQuizInitialized,
    quizCompleted,
    isQuizPaused,
    quizResult,
    regions,
    regionsLoading,
  ]);

  // Quiz control functions
  const pauseQuiz = () => setIsQuizPaused(true);
  const resumeQuiz = () => setIsQuizPaused(false);

  const resetQuiz = () => {
    setCurrentQuestion(null);
    setQuizProgress({
      quiz1_progress: 0,
      quiz1_total: 0,
      quiz2_progress: 0,
      quiz2_total: 0
    });
    setQuizId(null);
    setIsQuizInitialized(false);
    setQuizCompleted(false);
    setIsQuizPaused(false);
    setRegionsCompleted(false);
    setQuizResult(null);
    setRegions([]);
    setRegionsLoading(true);
    localStorage.removeItem('quizState');
  };

  return (
    <QuizContext.Provider
      value={{
        // Question and progress
        currentQuestion,
        setCurrentQuestion,
        quizProgress,
        setQuizProgress,
        quizId,
        setQuizId,

        // Quiz status
        isQuizInitialized,
        setIsQuizInitialized,
        quizCompleted,
        setQuizCompleted,
        isQuizPaused,
        showQuiz1CompletePopup,
        setShowQuiz1CompletePopup,
        showQuiz2ProgressPopup,
        setShowQuiz2ProgressPopup,

        // Quiz controls
        pauseQuiz,
        resumeQuiz,
        resetQuiz,

        // Results
        quizResult,
        setQuizResult,

        // Regions
        regionsCompleted,
        setRegionsCompleted,
        regions,
        setRegions,
        regionsLoading,
        setRegionsLoading,
      }}
    >
      {children}
    </QuizContext.Provider>
  );
};

export const useQuiz = () => useContext(QuizContext);

export default QuizContext;

