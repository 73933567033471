
import React, { useState, useEffect } from 'react';
import { Container, Button, Message, CloseButton, BlurredBackground } from './styles';
import { X } from 'lucide-react';

const PWAInstallPrompt = () => {
  const [deferredPrompt, setDeferredPrompt] = useState(null);

  useEffect(() => {
    const handler = (e) => {
      e.preventDefault();
      setDeferredPrompt(e);
    };

    window.addEventListener('beforeinstallprompt', handler);

    return () => {
      window.removeEventListener('beforeinstallprompt', handler);
    };
  }, []);

  const handleInstall = async () => {
    if (deferredPrompt) {
      deferredPrompt.prompt();
      const { outcome } = await deferredPrompt.userChoice;
      setDeferredPrompt(null);

      console.log(`User ${outcome === 'accepted' ? 'accepted' : 'dismissed'} the install prompt`);
    }
  };

  const handleDismiss = () => {
    setDeferredPrompt(null);
  };

  if (!deferredPrompt) {
    return null;
  }

  return (
    <BlurredBackground>
      <Container>
        <CloseButton onClick={handleDismiss}>
          <X size={16} />
        </CloseButton>
        <Button onClick={handleInstall}>
          Installer
        </Button>
        <Message>pour une meilleure expérience</Message>
      </Container>
    </BlurredBackground>
  );
};

export default PWAInstallPrompt;