import React, { useState, useEffect, useCallback } from 'react';
import { useSpring, animated, to } from '@react-spring/web';
import { useTutorial } from '../Tutorial/TutorialContext';
import useDeviceType from '../useDeviceType';
import questionIcon from '../../assets/doubt.png';
import heartIcon from '../../assets/jadore.png';
import styled, { keyframes } from 'styled-components';
import Meo from '../../assets/meo.png';
import theme from '../../theme';
import { ReturnButton } from '../AccountCreation/styles';
import { useNavigate } from 'react-router-dom';
import { 
  MdOutlineSwipeDown, 
  MdOutlineSwipeUp, 
  MdOutlineSwipeRight, 
  MdOutlineSwipeLeft 
} from "react-icons/md";
import { IoIosArrowBack } from "react-icons/io";

import {
  QuizContainer,
  BackgroundBall,
  QuestionText,
  AnswerOptionsContainer,
  AnswerButton,
  StyledIcon,
  StyledThumbsDown,
  StyledThumbsUp,
} from '../Quiz/styles';

const SWIPE_THRESHOLD = 10;

export const CardWrapper = styled.div`
  position: fixed;
  width: 100%;
  top: calc(var(--vh, 1vh) * 28);
  display: flex;
  justify-content: center;
  align-items: center;
  transform-style: preserve-3d;
  perspective: 1000px;

  ${props => !props.isMobile && `
    width: 100%;
  `}
`;


// Styled Components
const float = keyframes`
  0% { transform: translateY(0px) rotate(0deg); }
  25% { transform: translateY(-3px) rotate(1deg); }
  50% { transform: translateY(-5px) rotate(0deg); }
  75% { transform: translateY(-3px) rotate(-1deg); }
  100% { transform: translateY(0px) rotate(0deg); }
`;

const LogoImage = styled.img`
  width: 25%;
  height: auto;
  object-fit: contain;
  margin-left: -60%;
  filter: drop-shadow(0 4px 6px rgba(0, 0, 0, 0.1));
  animation: ${float} 6s ease-in-out infinite;
  transition: all 0.3s ease;

  &:hover {
    transform: scale(1.05) translateY(-2px);
    filter: drop-shadow(0 6px 8px rgba(0, 0, 0, 0.15));
    animation-play-state: paused;
  }

  @media (prefers-reduced-motion: no-preference) {
    will-change: transform;
  }
`;



export const QuestionCard = styled.div`
  background-color: ${props => props.theme.colors.background};
  border-radius: 25px;
  padding: ${props => props.theme.spacing.xlarge};
  box-shadow: 0 0 20px 0 rgba(0, 0, 0, 0.2);
  width: 70%;
  height: calc(var(--vh, 1vh) * 45);
  display: flex;
  align-items: center;
  z-index: 2;
  position: relative;
  transform-origin: center center;
  will-change: transform;
  margin-top: 15%;
  margin-left: 15%;

  ${props => !props.isMobile && `
    width: 50%;
    height: calc(var(--vh, 1vh) * 60);
    margin-top: 0;
    transform: none;
  `}
`;

const ChatBubbleContainer = styled.div`
  position: fixed;
  top: calc(var(--vh, 1vh) * 7);
  left: 38%;
  transform: none;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  z-index: 2;
`;

const ChatText = styled.div`
  background-color: transparent;
  color: ${theme.colors.noir};
  font-family: "Sour Gummy", sans-serif;
  padding: 0.8rem 1.2rem;
  border-radius: 1rem;
  font-size: ${theme.fontSizes.medium};
  text-align: left;
  position: relative;
  width: calc(var(--vh, 1vh) * 28);
  min-height: 60px;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  border: 2px solid ${theme.colors.lightGray};
  
  & span {
    opacity: ${props => props.isTyping ? 0 : 1}; // Changed this line
    transform: translateY(${props => props.isTyping ? '-10px' : '0'}); // Changed this line
    transition: opacity 0.3s ease-out, transform 0.3s ease-out;
  }

  &:after {
    content: '';
    position: absolute;
    left: -13px;
    top: 50%;
    transform: translateY(-50%);
    border-top: 10px solid transparent;
    border-bottom: 10px solid transparent;
    border-right: 10px solid ${theme.colors.lightGray};
  }

  &:before {
    content: '';
    position: absolute;
    left: -7px;
    top: 50%;
    transform: translateY(-50%);
    border-top: 8px solid transparent;
    border-bottom: 8px solid transparent;
    border-right: 8px solid transparent;
    z-index: 1;
  }
`;


const DirectionArrow = styled.div`
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  color: ${theme.colors.primary};
  font-size: 2rem;
  opacity: 0.7;
  transition: opacity 0.3s ease;
  animation: ${props => props.pulse} 2s infinite;
  pointer-events: none;
  z-index: 10;
`;
const pulseUp = keyframes`
  0%, 100% { transform: translateY(0) translateX(-50%); opacity: 0.7; }
  50% { transform: translateY(-5px) translateX(-50%); opacity: 1; }
`;

const pulseDown = keyframes`
  0%, 100% { transform: translateY(0) translateX(-50%); opacity: 0.7; }
  50% { transform: translateY(5px) translateX(-50%); opacity: 1; }
`;

const pulseLeft = keyframes`
  0%, 100% { transform: translateY(-50%) translateX(0); opacity: 0.7; }
  50% { transform: translateY(-50%) translateX(-5px); opacity: 1; }
`;

const pulseRight = keyframes`
  0%, 100% { transform: translateY(-50%) translateX(0); opacity: 0.7; }
  50% { transform: translateY(-50%) translateX(5px); opacity: 1; }
`;

const ArrowUp = styled(DirectionArrow)`
  top: 10px;
  left: 50%;
  transform: translateX(-50%);
  animation: ${pulseUp} 2s infinite;
  color: ${theme.colors.primary};
`;

const ArrowDown = styled(DirectionArrow)`
  bottom: 10px;
  left: 50%;
  transform: translateX(-50%);
  animation: ${pulseDown} 2s infinite;
  color: ${theme.colors.primary};
`;

const ArrowLeft = styled(DirectionArrow)`
  left: 10px;
  top: 50%;
  transform: translateY(-50%);
  animation: ${pulseLeft} 2s infinite;
  color: ${theme.colors.primary};
`;

const ArrowRight = styled(DirectionArrow)`
  right: 10px;
  top: 50%;
  transform: translateY(-50%);
  animation: ${pulseRight} 2s infinite;
  color: ${theme.colors.primary};
`;

const DirectionArrows = ({ isVisible }) => {
  if (!isVisible) return null;
  
  return (
    <>
      <ArrowUp>
        <MdOutlineSwipeUp size={32} />
      </ArrowUp>
      <ArrowDown>
        <MdOutlineSwipeDown size={32} />
      </ArrowDown>
      <ArrowLeft>
        <MdOutlineSwipeLeft size={32} />
      </ArrowLeft>
      <ArrowRight>
        <MdOutlineSwipeRight size={32} />
      </ArrowRight>
    </>
  );
};


// Constants
const TYPING_SPEED = 15;

const demoQuestions = {
  'show-quiz-buttons': {
    text: "Aimes-tu voyager ?",
    subText: "On commence mollo... Clique sur un des boutons ci-dessous!"
  },
  'show-quiz-swipe': {
    text: "Aimes-tu la cuisine française ?",
    subText: "Glisse la carte dans la direction de ton choix"
  },
  'quiz-complete': {
    text: "Quiz terminé!",
    subText: "Fonce voir tes résultats sur l'onglet Classement en bas à droite!"
  }
};

const TutorialQuiz = () => {
  // Hooks
  const { isMobile } = useDeviceType();
  const { 
    currentStepData, 
    nextStep, 
    setIsTutorialActive, 
    isTutorialActive, 
    currentStep 
  } = useTutorial();
  
  const navigate = useNavigate();
  const [text, setText] = useState('');
  const [isTyping, setIsTyping] = useState(true);
  const [isInitialized, setIsInitialized] = useState(false);

  const [{ x, y, rotate }, api] = useSpring(() => ({
    x: 0,
    y: 0,
    rotate: 0,
    config: { tension: 300, friction: 20 }
  }));

  const touchStartRef = React.useRef({ x: 0, y: 0 });

  // Effects
  useEffect(() => {
    if (isTutorialActive && currentStepData?.isQuizStep) {
      setIsInitialized(true);
      
      // Reset animation states
      api.start({
        x: 0,
        y: 0,
        rotate: 0,
        immediate: true
      });

      // Initialize typing animation
      if (currentStepData?.id) {
        const currentQuestion = demoQuestions[currentStepData.id];
        if (currentQuestion?.subText) {
          setIsTyping(true);
          setText('');
        }
      }
    }
    
    return () => {
      setIsInitialized(false);
      setIsTyping(false);
      setText('');
    };
  }, [isTutorialActive, currentStepData, api]);

  useEffect(() => {
    if (!isInitialized || !currentStepData?.id) return;
  
    const currentQuestion = demoQuestions[currentStepData.id];
    if (!currentQuestion?.subText) return;
  
    let currentIndex = 0;
    const fullText = currentQuestion.subText;
    
    const typingInterval = setInterval(() => {
      if (currentIndex <= fullText.length) {
        setText(fullText.slice(0, currentIndex));
        currentIndex++;
      } else {
        setIsTyping(false);
        clearInterval(typingInterval);

      }
    }, TYPING_SPEED);
  
    return () => clearInterval(typingInterval);
  }, [isInitialized, currentStepData?.id, nextStep]);

  // Handlers
  const handleBack = useCallback(() => {
    setIsTutorialActive(false);
    navigate('/presentation-outils');
  }, [setIsTutorialActive, navigate]);

  const animateCard = useCallback((direction) => {
    const distance = window.innerWidth + 200;
    const verticalDistance = window.innerHeight + 200;

    const animations = {
      up: { y: -verticalDistance, rotate: 0 },
      down: { y: verticalDistance, rotate: 0 },
      left: { x: -distance, rotate: -45 },
      right: { x: distance, rotate: 45 }
    };

    api.start({
      ...animations[direction],
      config: { tension: 200, friction: 20 }
    });

    setTimeout(() => {
      nextStep();
      api.start({
        x: 0,
        y: 0,
        rotate: 0,
        immediate: true
      });
    }, 300);
  }, [api, nextStep]);

  const handleButtonClick = useCallback((direction) => {
    if (currentStepData?.id === 'show-quiz-buttons') {
      animateCard(direction);
    }
  }, [currentStepData, animateCard]);

  const handleTouchStart = useCallback((e) => {
    const touch = e.touches[0];
    touchStartRef.current = { x: touch.clientX, y: touch.clientY };
  }, []);

  const handleTouchMove = useCallback((e) => {
    if (currentStepData?.id !== 'show-quiz-swipe') return;
    
    const touch = e.touches[0];
    const deltaX = touch.clientX - touchStartRef.current.x;
    const deltaY = touch.clientY - touchStartRef.current.y;
    const rotation = deltaX / 15;

    api.start({
      x: deltaX,
      y: deltaY,
      rotate: rotation,
      immediate: true
    });
  }, [currentStepData, api]);

  const handleTouchEnd = useCallback((e) => {
    if (currentStepData?.id !== 'show-quiz-swipe') {
      api.start({ x: 0, y: 0, rotate: 0, config: { tension: 200, friction: 20 } });
      return;
    }

    const touch = e.changedTouches[0];
    const deltaX = touch.clientX - touchStartRef.current.x;
    const deltaY = touch.clientY - touchStartRef.current.y;

    if (Math.abs(deltaX) > SWIPE_THRESHOLD || Math.abs(deltaY) > SWIPE_THRESHOLD) {
      if (Math.abs(deltaX) > Math.abs(deltaY)) {
        deltaX > 0 ? animateCard('right') : animateCard('left');
      } else {
        deltaY > 0 ? animateCard('down') : animateCard('up');
      }
    } else {
      api.start({
        x: 0,
        y: 0,
        rotate: 0,
        config: { tension: 200, friction: 20 }
      });
    }
  }, [currentStepData, animateCard, api]);

  // Early returns
  if (!isInitialized && isTutorialActive) {
    return <div>Loading tutorial...</div>;
  }

  if (!isTutorialActive || !currentStepData?.isQuizStep) {
    return null;
  }

  const currentQuestion = demoQuestions[currentStepData?.id];
  if (!currentQuestion) {
    return null;
  }

  return (
    <QuizContainer $isMobile={isMobile}>
      <LogoImage src={Meo} alt="Meoria Logo" />
      <ChatBubbleContainer>
        <ChatText isVisible={isTyping}>
          <span>{text}</span>
        </ChatText>
      </ChatBubbleContainer>
      <ReturnButton onClick={handleBack}>
        <IoIosArrowBack />
      </ReturnButton>
      <BackgroundBall $isMobile={isMobile} />
      <CardWrapper $isMobile={isMobile}>
        <animated.div
          style={{
            touchAction: 'none',
            transform: to(
              [x, y, rotate],
              (x, y, r) => `translate3d(${x}px,${y}px,0) rotate(${r}deg)`
            ),
            width: '100%',
            height: '100%',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            position: 'relative'
          }}
        >
          <QuestionCard 
            $isMobile={isMobile}
            onTouchStart={handleTouchStart}
            onTouchMove={handleTouchMove}
            onTouchEnd={handleTouchEnd}
            style={{
              margin: 0,
              position: 'relative',
              width: isMobile ? '70%' : '50%',
              height: isMobile ? 'calc(var(--vh, 1vh) * 45)' : 'calc(var(--vh, 1vh) * 60)'
            }}
          >
            <QuestionText>
              {currentQuestion.text}
            </QuestionText>
            <DirectionArrows isVisible={currentStepData?.id === 'show-quiz-swipe'} />
          </QuestionCard>
        </animated.div>
      </CardWrapper>
      <AnswerOptionsContainer $isMobile={isMobile}>
        <AnswerButton 
          onClick={() => handleButtonClick('down')}
          disabled={currentStepData?.interactionType !== 'buttons'}
        >
          <StyledIcon src={questionIcon} alt="Question" fill="yellow"/>
        </AnswerButton>
        <AnswerButton 
          onClick={() => handleButtonClick('left')}
          disabled={currentStepData?.interactionType !== 'buttons'}
        >
          <StyledThumbsDown fill="red"/>
        </AnswerButton>
        <AnswerButton 
          onClick={() => handleButtonClick('right')}
          disabled={currentStepData?.interactionType !== 'buttons'}
        >
          <StyledThumbsUp fill="#0BDB22" />
        </AnswerButton>
        <AnswerButton 
          onClick={() => handleButtonClick('up')}
          disabled={currentStepData?.interactionType !== 'buttons'}
        >
          <StyledIcon src={heartIcon} alt="Heart" fill="#FF2768" />
        </AnswerButton>
      </AnswerOptionsContainer>
    </QuizContainer>
  );
};

export default TutorialQuiz;