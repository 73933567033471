
import React, { useState, useRef, useEffect } from 'react';
import { FaArrowUp } from "react-icons/fa6";
import { useTutorial } from '../Tutorial/TutorialContext';
import TutorialWelcomeMenu from '../WelcomeMenu/WelcomeMenuTutorial';
import styled, { keyframes, css } from 'styled-components';
import {
  Container,
  ChatBox,
  ChatHistory,
  Message,
  InputArea,
  Input,
  SendButton,
  MarkdownBox,
  InputAreaWrapper,
} from './styles';
import useDeviceType from '../useDeviceType';
import Meo from '../../assets/meo.png';
import theme from '../../theme';

const baseChatStyles = css`
  background-color: ${props => props.theme.colors.background};
`;

export const ChatContainer = styled.div`
  ${baseChatStyles}
  display: flex;
  flex-direction: column;
  flex: 1;
  justify-content: center;
  width: ${props => props.isSideMenuOpen && !props.$isMobile ? 'calc(67% - 0)' : '80%'};
  margin-left: ${props => props.isSideMenuOpen && !props.$isMobile ? '300px' : '10%'};
  border-radius: 12px;
  position: relative;
  overflow-y: auto;
  padding: ${props => props.theme.spacing.medium};

  ${props => props.showWelcomeMenu && css`
    align-items: center;
  `}

  ${props => props.$isMobile && css`
    position: absolute;
    margin-left: 0;
    right: 0;
    width: 100%;
    padding-top: ${props.theme.spacing.big};
    padding-bottom: calc(${props.theme.spacing.xxsmall} + var(--bottom-menu-height - 10px, 30px));
    height: calc(100vh - var(--bottom-menu-height, 60px) - var(--input-area-height, 50px));
  `}
`;


const float = keyframes`
  0% { transform: translateY(0px) rotate(0deg); }
  25% { transform: translateY(-3px) rotate(1deg); }
  50% { transform: translateY(-5px) rotate(0deg); }
  75% { transform: translateY(-3px) rotate(-1deg); }
  100% { transform: translateY(0px) rotate(0deg); }
`;


export const LogoImage = styled.img`
  width: 30%;
  height: 30%;
  object-fit: contain;
  margin-top: 15%;

  /* Add drop shadow */
  filter: drop-shadow(0 4px 6px rgba(0, 0, 0, 0.1));

  /* Add floating animation */
  animation: ${float} 6s ease-in-out infinite;

  /* Add smooth transition for hover effect */
  transition: all 0.3s ease;

  /* Optional: Add subtle hover effect */
  &:hover {
    transform: scale(1.05) translateY(-2px);
    filter: drop-shadow(0 6px 8px rgba(0, 0, 0, 0.15));
    animation-play-state: paused;
  }

  /* Make animation smoother on higher-end devices */
  @media (prefers-reduced-motion: no-preference) {
    will-change: transform;
  }
`;

const ContinueButton = styled.button`
  background-color: ${theme.colors.primary};
  color: white;
  border: none;
  border-radius: 8px;
  padding: 12px 24px;
  font-size: ${theme.fontSizes.medium};
  font-weight: 600;
  cursor: pointer;
  width: 100%;
  margin-top: 12px;
  transition: background-color 0.2s ease;

  &:hover {
    background-color: ${theme.colors.primaryDark};
  }
`;

const LoadingDots = styled.span`
  &::after {
    content: '.';
    animation: dots 1.5s steps(5, end) infinite;
  }

  @keyframes dots {
    0%, 20% { content: '.'; }
    40% { content: '..'; }
    60% { content: '...'; }
    80%, 100% { content: ''; }
  }
`;


export const ChatBubbleContainer = styled.div`
  position: absolute;
  top: 5%;
  left: 50%;
  transform: translateX(-50%);
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  max-width: 300px;
  z-index: 2;
`;

export const ChatText = styled.div`
  background-color: transparent;
  color: ${theme.colors.black};
  padding: 0.8rem 1.2rem;
  border-radius: 1rem;
  font-family: "Sour Gummy", sans-serif;
  font-size: ${theme.fontSizes.medium};
  text-align: center;
  position: relative;
  width: 200px;
  height: 60px;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 2px solid ${theme.colors.lightGray};
  
  & span {
    opacity: ${props => props.$isVisible ? 1 : 0};
    transform: translateY(${props => props.$isVisible ? '0' : '-10px'});
    transition: opacity 0.3s ease-out, transform 0.3s ease-out;
  }

  &:after {
    content: '';
    position: absolute;
    bottom: -12px;
    left: 50%;
    transform: translateX(-50%);
    border-left: 10px solid transparent;
    border-right: 10px solid transparent;
    border-top: 10px solid ${theme.colors.lightGray};
  }

  &:before {
    content: '';
    position: absolute;
    bottom: -9px;
    left: 50%;
    transform: translateX(-50%);
    border-left: 8px solid transparent;
    border-right: 8px solid transparent;
    border-top: 8px solid transparent;
    z-index: 1;
  }
`;


const TutorialChat = () => {
  const { isMobile } = useDeviceType();
  const { currentStepData, endTutorial } = useTutorial();
  const [showWelcomeMenu, setShowWelcomeMenu] = useState(true);
  const [chatHistory, setChatHistory] = useState([]);
  const [isTyping, setIsTyping] = useState(false);
  const [showContinueButton, setShowContinueButton] = useState(false);
  const [currentResponse, setCurrentResponse] = useState('');
  const chatHistoryRef = useRef(null);

  const simulatedResponse = `Bien-sûr ! Je suis là pour ça, il ne te reste qu'une étape. En ensuite, je serai heureux de t'accompagner personellement à l'intérieur de cette jungle de l'orientation. Je vais t'aider à trouver ta voie ! `;


  const simulateTyping = async (text) => {
    setIsTyping(true);
    let current = '';
  
    await new Promise((resolve) => setTimeout(resolve, 1000));
  
    for (let i = 0; i < text.length; i++) {
      current += text[i];
      setCurrentResponse(current);
      await new Promise((resolve) => setTimeout(resolve, Math.random() * 50));
    }
  
    setIsTyping(false);
    setChatHistory((prev) => [...prev, { user: 'Bot', message: text }]);
    setCurrentResponse('');
    setShowContinueButton(true);
  };

  const handleExampleClick = async (example) => {
    setShowWelcomeMenu(false);
    setChatHistory(prev => [...prev, { user: 'You', message: example }]);
    await simulateTyping(simulatedResponse);
  };

  const handleContinue = () => {
    setShowContinueButton(false);
    endTutorial();
  };

  return (
    <Container $isMobile={isMobile}>
      <ChatContainer $isMobile={isMobile}>
        <ChatBox>
        <ChatHistory ref={chatHistoryRef}>
            {currentStepData?.id === 'show-chat' && (
              <>
                <LogoImage src={Meo} alt="Tutorial Logo" />
                <ChatBubbleContainer>
                  <ChatText $isVisible={true}>
                    <span>Clique sur l'exemple suivante !</span>
                  </ChatText>
                </ChatBubbleContainer>
              </>
            )}
            {currentStepData?.id === 'show-chat' && showWelcomeMenu ? (
              <TutorialWelcomeMenu
                onExampleClick={handleExampleClick}
                isSideMenuOpen={false}
                $isMobile={isMobile}
              />
            ) : (
              <>
                {chatHistory.map((chat, index) => (
                  <Message key={index} $user={chat.user}>
                    <MarkdownBox>
                      {chat.message}
                    </MarkdownBox>
                  </Message>
                ))}
                {isTyping && (
                  <Message $user="Bot">
                    <MarkdownBox>
                      {currentResponse || <LoadingDots>Meoria réfléchit</LoadingDots>}
                    </MarkdownBox>
                  </Message>
                )}
                {showContinueButton && (
                  <Message $user="Bot">
                    <MarkdownBox>
                      <ContinueButton onClick={handleContinue}>
                        Rentrer dans la jungle
                      </ContinueButton>
                    </MarkdownBox>
                  </Message>
                )}
              </>
            )}
          </ChatHistory>
        </ChatBox>
      </ChatContainer>

      <InputAreaWrapper $isMobile={isMobile}>
        <InputArea as="form" $isMobile={isMobile}>
          <Input
            as="textarea"
            value=""
            placeholder="Message à Meoria..."
            disabled={true}
            rows={1}
          />
          <SendButton
            type="submit"
            disabled={true}
            $show={false}
          >
            <FaArrowUp size={21} />
          </SendButton>
        </InputArea>
      </InputAreaWrapper>
    </Container>
  );
};

export default TutorialChat;
