import React from 'react';
import {
 WelcomeMenuWrapper,
 ContentWrapper,
 ExamplesContainer,
 ExampleBox
} from '../WelcomeMenu/styles';

const TutorialWelcomeMenu = ({ onExampleClick, isSideMenuOpen, isMobile }) => {
 const examples = [
   "J'ai plein de question sur mon orientation !",
 ];

 return (
   <WelcomeMenuWrapper
     $isSideMenuOpen={isSideMenuOpen}
     isMobile={isMobile}
   >
     <ContentWrapper>
       <ExamplesContainer 
         $isSideMenuOpen={isSideMenuOpen} 
         isMobile={isMobile}
         style={{ gridTemplateColumns: '1fr' }} // Force single column
       >
         {examples.map((example, index) => (
           <ExampleBox
             key={index}
             onClick={() => onExampleClick(example)}
             $isSideMenuOpen={isSideMenuOpen}
             isMobile={isMobile}
             style={{ 
               cursor: 'pointer',
               width: '90%', // Make box narrower
               margin: '0 auto' // Center the box
             }}
           >
             {example}
           </ExampleBox>
         ))}
       </ExamplesContainer>
     </ContentWrapper>
   </WelcomeMenuWrapper>
 );
};

export default TutorialWelcomeMenu;