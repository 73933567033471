import React, { useRef, useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { IoMenu, IoRefresh } from "react-icons/io5";
import ChatHistory from '../ChatHistory';
import useDeviceType from '../useDeviceType';
import {
    SideMenuContainer,
    TopSection,
    HeaderContainer,
    BottomNavList,
    NavItem,
    HistoryContainer,
    MenuButton,
    MainContentWrapper
} from './styles';

const SideMenu = ({ isOpen, onToggle, onRestartChat, onDiscussionSelect, children }) => {
    const navigate = useNavigate();
    const location = useLocation();
    const { isMobile } = useDeviceType();
    const menuRef = useRef(null);

    useEffect(() => {
        const handleClickOutside = (event) => {
            // Get the actual event (touch or mouse)
            const targetEvent = event.touches ? event.touches[0] : event;
            // Get the target element
            const targetElement = targetEvent.target;

            // Check if menu is open and click is outside menu
            if (isOpen && menuRef.current && !menuRef.current.contains(targetElement)) {
                const menuButton = document.querySelector('[data-menu-button]');
                // Check if click is not on menu button
                if (!menuButton?.contains(targetElement)) {
                    onToggle();
                }
            }
        };

        // Add both mouse and touch event listeners when menu is open
        if (isOpen) {
            document.addEventListener('mousedown', handleClickOutside);
            document.addEventListener('touchstart', handleClickOutside, { passive: true });
        }

        // Cleanup
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
            document.removeEventListener('touchstart', handleClickOutside);
        };
    }, [isOpen, onToggle]);

    const handleNavigation = (path) => {
        navigate(path);
        if (isMobile) onToggle();
    };

    const handleItemClick = (discussion) => {
        console.log('Discussion clicked:', discussion);
        if (onDiscussionSelect) {
            onDiscussionSelect(discussion);
        }
        if (location.pathname !== '/chat') {
            navigate('/chat', { state: { selectedDiscussion: discussion } });
        }
        if (isMobile) onToggle();
    };

    return (
        <>
            {!isMobile && (
                <MenuButton
                    onClick={onToggle}
                    style={{ position: 'fixed', top: '10px', left: '10px', zIndex: 1001 }}
                    data-menu-button
                >
                    <IoMenu size={24} />
                </MenuButton>
            )}
            {isOpen && (
                <div
                    className="fixed inset-0 bg-black bg-opacity-50 transition-opacity z-40"
                    aria-hidden="true"
                />
            )}
            <SideMenuContainer ref={menuRef} $isOpen={isOpen}>
                <HeaderContainer>
                    {isMobile ? (
                        <MenuButton
                            onClick={onToggle}
                            data-menu-button
                        >
                            <IoMenu size={24} />
                        </MenuButton>
                    ) : (
                        <div />
                    )}
                </HeaderContainer>
                <TopSection>
                    <HistoryContainer>
                        <ChatHistory onDiscussionClick={handleItemClick} />
                    </HistoryContainer>
                </TopSection>
                <BottomNavList>
                    <NavItem>
                        <button onClick={() => handleNavigation('/chat')}>Chat</button>
                    </NavItem>
                    <NavItem>
                        <button onClick={() => handleNavigation('/classement')}>Résultats</button>
                    </NavItem>
                    <NavItem>
                        <button onClick={() => handleNavigation('/quiz')}>Swipe</button>
                    </NavItem>
                </BottomNavList>
            </SideMenuContainer>
            <MainContentWrapper $isOpen={isOpen} $isMobile={isMobile}>
                {children}
            </MainContentWrapper>
        </>
    );
};

export default SideMenu;