import styled, { keyframes } from 'styled-components';
import { IoSettingsOutline } from "react-icons/io5";
import { FaMapMarkerAlt } from 'react-icons/fa';
import { GiTrophyCup, GiPodium } from 'react-icons/gi';
import theme from '../../theme';

export const Container = styled.div`
  width: 100%;
  max-width: 100%;
  margin: 0 auto;
  padding: 10px;
  box-sizing: border-box;
  overflow-x: hidden;
  
  @media (min-width: 768px) {
    max-width: 1200px;
    padding: 20px;
  }
`;

export const ScrollableRankings = styled.div`
  max-height: 60vh;
  overflow-y: auto;
  overflow-x: hidden;
  padding: 10px;
  width: 100%;
  box-sizing: border-box;
  
  @media (min-width: 768px) {
    padding: 20px;
  }
  
  &::-webkit-scrollbar {
    width: 8px;
  }
  
  &::-webkit-scrollbar-track {
    background: #f1f1f1;
    border-radius: 4px;
  }
  
  &::-webkit-scrollbar-thumb {
    background: #888;
    border-radius: 4px;
  }
`;

export const RankingList = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
  width: 100%;
  max-width: 100%;
  box-sizing: border-box;
`;

export const RankingItem = styled.a`
  display: flex;
  align-items: center;
  padding: 12px;
  background: white;
  border-radius: 12px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  transition: all 0.2s ease-in-out;
  text-decoration: none;
  width: 100%;
  max-width: 100%;
  height: ${props => props.rank <= 3 ? '90px' : '70px'};
  box-sizing: border-box;
  cursor: pointer;
  
  @media (min-width: 768px) {
    padding: 20px;
    height: ${props => props.rank <= 3 ? '100px' : '80px'};
  }
  
  ${props => {
    if (props.rank === 1) {
      return `
        background: linear-gradient(135deg, #fff9e6, #fff5cc);
        border: 1px solid #FFD700;
        box-shadow: 0 6px 16px rgba(255, 215, 0, 0.15);
        transform: scale(1.02);
      `;
    } else if (props.rank === 2) {
      return `
        background: linear-gradient(135deg, #f8f9fa, #e9ecef);
        border: 1px solid #C0C0C0;
        box-shadow: 0 6px 16px rgba(192, 192, 192, 0.15);
        transform: scale(1.01);
      `;
    } else if (props.rank === 3) {
      return `
        background: linear-gradient(135deg, #fff4eb, #ffe5d4);
        border: 1px solid #CD7F32;
        box-shadow: 0 6px 16px rgba(205, 127, 50, 0.15);
        transform: scale(1.005);
      `;
    }
  }}
`;

export const Trophy = styled(GiTrophyCup)`
  font-size: ${props => props.rank <= 3 ? '1.8rem' : '1.5rem'};
  margin-right: 12px;
  color: ${props => {
    switch(parseInt(props.rank)) {
      case 1: return '#FFD700';
      case 2: return '#C0C0C0';
      case 3: return '#CD7F32';
      default: return '#A0A0A0';
    }
  }};
  flex-shrink: 0;
  
  @media (min-width: 768px) {
    font-size: ${props => props.rank <= 3 ? '2.5rem' : '2rem'};
    margin-right: 20px;
  }
`;

export const SchoolInfo = styled.div`
  flex: 1;
  min-width: 0;
  margin-right: 8px;
  overflow: hidden;
  
  @media (min-width: 768px) {
    margin-right: 16px;
  }
`;

export const SchoolName = styled.h3`
  margin: 0;
  font-size: ${props => props.rank <= 3 ? '1.1rem' : '1rem'};
  font-weight: ${props => props.rank <= 3 ? '700' : '600'};
  color: #333;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  
  @media (min-width: 768px) {
    font-size: ${props => props.rank <= 3 ? '1.4rem' : '1.25rem'};
  }
`;

export const Formation = styled.p`
  margin: 4px 0 0 0;
  font-size: 0.8rem;
  color: #666;
  font-weight: 300;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  
  @media (min-width: 768px) {
    font-size: 0.9rem;
  }
`;

export const Location = styled.div`
  display: flex;
  align-items: center;
  gap: 2px;
  font-size: 0.75rem;
  color: #888;
  white-space: nowrap;
  flex-shrink: 0;
  overflow: hidden;
  max-width: 80px;
  
  @media (min-width: 768px) {
    gap: 4px;
    font-size: 0.85rem;
    max-width: 120px;
  }
`;

export const LocationIcon = styled(FaMapMarkerAlt)`
  color: #888;
  font-size: 0.75rem;
  flex-shrink: 0;
  
  @media (min-width: 768px) {
    font-size: 0.85rem;
  }
`;

export const RankNumber = styled.span`
  font-size: 1rem;
  font-weight: 500;
  color: #666;
  margin-right: 12px;
  min-width: 20px;
  text-align: center;
  flex-shrink: 0;
  
  @media (min-width: 768px) {
    font-size: 1.1rem;
    margin-right: 20px;
    min-width: 24px;
  }
`;



export const PodiumHeader = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 24px;
  padding: 20px;
  background: linear-gradient(135deg, #fff9e6, #fff5cc);
  border-radius: 12px;
  box-shadow: 0 4px 12px rgba(255, 215, 0, 0.15);
  
  @media (min-width: 768px) {
    margin-bottom: 32px;
  }
`;

export const PodiumIcon = styled(GiPodium)`
  font-size: 2.5rem;
  color: #FFD700;
  margin-bottom: 8px;
  
  @media (min-width: 768px) {
    font-size: 3rem;
  }
`;

export const PodiumTitle = styled.h2`
  font-size: 1.5rem;
  font-weight: 700;
  color: #333;
  margin: 0;
  text-align: center;
  
  @media (min-width: 768px) {
    font-size: 1.8rem;
  }
`;


// Animations
export const slideUp = keyframes`
  from {
    transform: translateY(20px);
    opacity: 0;
  }
  to {
    transform: translateY(0);
    opacity: 1;
  }
`;

export const NoResultsMessage = styled.div`
  text-align: center;
  padding: 40px;
  color: #666;
  font-size: 1.1rem;
  margin-top: 2rem;
  font-style: italic;
  padding-bottom: ${props => props.isMobile ? 
    'calc(var(--bottom-menu-height, 60px))' :
    '0'
  };
`;

// Float animation for logo
export const float = keyframes`
  0% {
    transform: translateY(0px) rotate(0deg);
  }
  25% {
    transform: translateY(-3px) rotate(1deg);
  }
  50% {
    transform: translateY(-5px) rotate(0deg);
  }
  75% {
    transform: translateY(-3px) rotate(-1deg);
  }
  100% {
    transform: translateY(0px) rotate(0deg);
  }
`;

export const LogoImage = styled.img`
  width: 30%;
  height: 30%;
  object-fit: contain;
  margin-left: 35%;
  margin-bottom: 30%;
  filter: drop-shadow(0 4px 6px rgba(0, 0, 0, 0.1));
  animation: ${float} 6s ease-in-out infinite;
  transition: all 0.3s ease;

  &:hover {
    transform: scale(1.05) translateY(-2px);
    filter: drop-shadow(0 6px 8px rgba(0, 0, 0, 0.15));
    animation-play-state: paused;
  }

  @media (prefers-reduced-motion: no-preference) {
    will-change: transform;
  }
`;

export const SpeechBubbleContainer = styled.div`
  position: relative;
  margin-left: -5%;
  margin-top: -50%;
`;

export const SpeechBubbleImage = styled.img`
  min-width: 200px;
  height: 200px;
  object-fit: contain;
  padding: ${theme.spacing.medium};
`;


// Classement specific styles
export const ClassementContainer = styled.div`
  width: 100%;
  max-width: 800px;
  margin: ${props => props.isMobile ? '0' : '-60px'} auto 0;
  padding: ${theme.spacing.large};
  background-color: white;
  border-radius: 20px;
  box-shadow: 0 10px 25px rgba(255, 39, 104, 0.1);
  
  ${props => props.isMobile && styled.css`
    padding: ${theme.spacing.medium};
    border-radius: 12px;
    margin-bottom: calc(var(--bottom-menu-height, 60px) + ${theme.spacing.medium});
  `}
`;

export const ClassementTitle = styled.h2`
  color: ${theme.colors.text};
  font-size: ${props => props.isMobile ? 
    theme.fontSizes.large : 
    theme.fontSizes.xlarge
  };
  font-weight: ${theme.fontWeights.bold};
  text-align: center;
  margin-bottom: ${theme.spacing.large};
`;

export const ClassementList = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${theme.spacing.medium};
  padding-bottom: ${props => props.isMobile ? 
    'calc(var(--bottom-menu-height, 60px) + 20px)' : 
    '0'
  };
`;

export const ClassementItem = styled.div`
  display: flex;
  align-items: center;
  padding: ${props => props.isMobile ? 
    theme.spacing.small : 
    theme.spacing.medium
  };
  background-color: ${theme.colors.veryLightGray};
  border-radius: 12px;
  transition: all 0.3s ease;

  &:hover {
    transform: translateX(8px);
    background-color: ${theme.colors.lightGray};
  }
`;

// Settings button positioning
export const SettingsButton = styled.button`
  position: fixed;
  top: ${theme.spacing.medium};
  right: ${props => props.isMobile ? '10px' : '20px'};
  background: none;
  border: none;
  cursor: pointer;
  padding: 5px;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: transform 0.2s ease-in-out;
  z-index: 11;
`;

// Utility components
export const RankingLink = styled.a`
  color: ${props => props.theme.colors.primary};
  text-decoration: none;
  flex: 1;

  &:hover {
    text-decoration: underline;
  }
`;

export const RankingNumber = styled.span`
  min-width: 2rem;
  margin-right: ${props => props.isMobile ? '0.5rem' : '1rem'};
  font-weight: bold;
  color: ${props => props.theme.colors.primary};
`;

export const Position = styled.span`
  min-width: ${props => props.isMobile ? '32px' : '40px'};
  height: ${props => props.isMobile ? '32px' : '40px'};
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: ${theme.colors.primary};
  color: ${theme.colors.lightText};
  border-radius: 50%;
  font-weight: ${theme.fontWeights.bold};
  margin-right: ${props => props.isMobile ? 
    theme.spacing.small : 
    theme.spacing.medium
  };
`;

export const FormationInfo = styled.div`
  flex: 1;
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: ${props => props.isMobile ? 
    theme.spacing.small : 
    theme.spacing.medium
  };
`;

export const Score = styled.span`
  font-weight: ${theme.fontWeights.semiBold};
  color: ${theme.colors.primary};
  ${props => props.isMobile && styled.css`
    font-size: ${theme.fontSizes.small};
  `}
`;

export const SettingsIcon = styled(IoSettingsOutline)`
  font-size: ${props => props.isMobile ? '20px' : '24px'};
  color: ${props => props.theme.colors.primary};
`;

export const ChatBubbleContainer = styled.div`
  position: absolute;
  top: 10%;
  left: 50%;
  transform: translateX(-50%);
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  max-width: 300px;
  z-index: 2;
`;

export const ChatText = styled.div`
  background-color: transparent;
  color: ${theme.colors.black};
  padding: 0.8rem 1.2rem;
  border-radius: 1rem;
  font-family: "Sour Gummy", sans-serif;
  font-size: ${theme.fontSizes.medium};
  text-align: center;
  position: relative;
  width: 200px; // Fixed width
  height: 60px; // Fixed height
  display: flex;
  align-items: center;
  justify-content: center;
  border: 2px solid ${theme.colors.lightGray};
  
  // For the text animation only
  & span {
    opacity: ${props => props.isVisible ? 1 : 0};
    transform: translateY(${props => props.isVisible ? '0' : '-10px'});
    transition: opacity 0.3s ease-out, transform 0.3s ease-out;
  }

  &:after {
    content: '';
    position: absolute;
    bottom: -12px; // Adjusted to account for border width
    left: 50%;
    transform: translateX(-50%);
    border-left: 10px solid transparent;
    border-right: 10px solid transparent;
    border-top: 10px solid ${theme.colors.lightGray}; // Changed to grey
  }

  // Additional pseudo-element for the transparent tail
  &:before {
    content: '';
    position: absolute;
    bottom: -9px; // Slightly higher than the grey border
    left: 50%;
    transform: translateX(-50%);
    border-left: 8px solid transparent;
    border-right: 8px solid transparent;
    border-top: 8px solid transparent;
    z-index: 1;
  }
`;

export const ContentWrapper = styled.div`
  position: relative;
  width: 100%;
  max-width: 600px;
  padding-top: 120px; // Space for the logo and bubble
`;

// New components
export const RankingContainer = styled.div`
  max-width: 1200px;
  margin: 0 auto;
  padding: 20px;
`;

export const ScrollableRankingList = styled.div`
  max-height: calc(100vh - 200px);
  overflow-y: auto;
  padding: 20px;
`;

export const RankingListContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
`;

export const RankingItemContainer = styled.div`
  display: flex;
  align-items: center;
  padding: 20px;
  background: white;
  border-radius: 12px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  transition: transform 0.2s;
  &:hover { transform: translateY(-2px); }
`;

export const Card = styled.div`
  background: ${theme.colors.lightText};
  border-radius: 20px;
  padding: ${theme.spacing.xlarge};
  box-shadow: 0 8px 32px rgba(255, 39, 104, 0.08);
  width: 80%;
  text-align: center;
  margin: 0 auto;
`;

export const Button = styled.button`
  background: ${props => props.primary ? theme.colors.primary : theme.colors.lightText};
  color: ${theme.colors.primary};
  border: 2px solid ${theme.colors.primary};
  border-radius: 10px;
  padding: ${theme.spacing.medium} ${theme.spacing.xlarge};
  font-family: ${theme.fonts.main};
  font-size: ${theme.fontSizes.medium};
  font-weight: ${theme.fontWeights.semiBold};
  cursor: pointer;
  width: 50%;
  justify-content;

  &:hover {
    background: ${props => props.primary ? theme.colors.secondary : theme.colors.lightText};
  }
`;

export const LinkText = styled.a`
  color: ${theme.colors.primary};
  font-size: ${theme.fontSizes.medium};
  font-weight: ${theme.fontWeights.medium};
  text-decoration: none;
  cursor: pointer;

  &:hover {
    color: ${theme.colors.secondary};
    text-decoration: underline;
  }
`;

export const BaseButton = styled.button`
  border-radius: 10px;
  padding: ${props => props.padding || `${theme.spacing.medium} ${theme.spacing.xlarge}`};
  font-family: ${props => props.fontFamily || theme.fonts.main};
  font-size: ${props => props.fontSize || theme.fontSizes.medium};
  font-weight: ${props => props.fontWeight || theme.fontWeights.semiBold};
  cursor: pointer;
  width: ${props => props.width || '50%'};
  transition: all 0.3s ease;

  &:disabled {
    opacity: 0.6;
    cursor: not-allowed;
  }
`;

export const PrimaryButton = styled(BaseButton)`
  background: ${props => props.background || theme.colors.primary};
  color: ${props => props.color || theme.colors.lightText};
  border: 3px solid ${props => props.borderColor || theme.colors.primary};
  position: fixed;
  bottom: calc(var(--vh, 1vh) * 11); /* 11vh from bottom */
  left: 50%;
  width: min(90%, 400px);
  border-radius: 20px;
  transform: translateX(-50%);
  box-shadow: 0 6px 8px rgba(0, 0, 0, 0.5); /* Smaller spread but darker shadow */
  transition: all 0.15s ease-in-out;
  
  &:hover {
    transform: translateX(-50%) translateY(3px); /* Matches shadow height */
    box-shadow: none;
    background: ${props => props.background || theme.colors.primary};
  }
  
  &:active {
    transform: translateX(-50%) translateY(3px);
    box-shadow: none;
  }
`;

export const BottomActionsContainer = styled.div`
  position: fixed;
  bottom: calc(var(--vh, 1vh) * 6); /* 6vh from bottom */
  left: 50%;
  transform: translateX(-50%);
  display: flex;
  align-items: baseline;
  justify-content: center; /* This keeps horizontal centering */
  gap: ${theme.spacing.xsmall};
  width: 90%;
  z-index: 1000;
`;

export const StyledText = styled.span`
  color: ${props => theme.colors.gris};
  font-size: ${props => theme.fontSizes.medium};
  font-weight: ${props => theme.fontWeights.semiBold};
`;

export const TextButton = styled.button`
  background: none;
  border: none;
  color: ${props => props.color || theme.colors.noir};
  font-size: ${props => props.fontSize || theme.fontSizes.medium};
  font-weight: ${theme.fontWeights.semiBold};
  text-decoration: none;
  cursor: pointer;
  padding: 0;
  transition: transform 0.15s ease-in-out;
  
  &:hover {
    transform: scale(0.95); /* Subtle dezoom effect */
    color: ${props => props.color || theme.colors.noir}; /* Keeps original color */
  }
  
  &:active {
    transform: scale(0.95); /* Maintains same dezoom as hover */
  }
`;

export const TextContainer = styled.div`
  position: fixed;
  top: 64%;
  left: 50%;
  transform: translate(-50%, -50%);
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  width: 100%;
  gap: ${props => theme.spacing.medium};
`;

export const Subtitle = styled.h2`
  color: ${props => theme.colors.gris};
  font-size: ${props => props.fontSize || '16px'};
  font-weight: ${props => theme.fontWeights.medium};
  margin: 0;
  padding: 0;
  line-height: 1.4;

  ${props => props.isMobile ? `
    font-size: 14px;
  ` : `
    font-size: 16px;
  `}
`;

