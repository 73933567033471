
// styles.js
import styled, { css, keyframes} from 'styled-components';
import theme from '../../theme';

// Base button component needs to be defined first
const BaseButton = styled.button`
  border-radius: 10px;
  padding: ${props => props.padding || `${theme.spacing.medium} ${theme.spacing.xlarge}`};
  font-family: ${props => props.fontFamily || theme.fonts.main};
  font-size: ${props => props.fontSize || theme.fontSizes.medium};
  font-weight: ${props => props.fontWeight || theme.fontWeights.semiBold};
  cursor: pointer;
  transition: all 0.3s ease;

  &:disabled {
    opacity: 0.6;
    cursor: not-allowed;
  }
`;

export const Container = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 100vh;
  height: calc(var(--vh, 1vh) * 100);
  min-width: calc(var(--wh, 1wh) * 100);
  background-color: ${theme.colors.background};
  padding: ${theme.spacing.medium};
  overflow: hidden;
  z-index: 2000;
`;

export const ContentWrapper = styled.div`
  position: relative;
  width: 100%;
  max-width: 600px;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: ${theme.spacing.large} 0;
  gap: ${theme.spacing.large};
`;


const float = keyframes`
  0% {
    transform: translateY(0px) rotate(0deg);
  }
  25% {
    transform: translateY(-3px) rotate(1deg);
  }
  50% {
    transform: translateY(-5px) rotate(0deg);
  }
  75% {
    transform: translateY(-3px) rotate(-1deg);
  }
  100% {
    transform: translateY(0px) rotate(0deg);
  }
`;

export const LogoImage = styled.img`
  width: 30%;
  height: 30%;
  margin-top: 8%;
  object-fit: contain;
  margin-left: 35%;
  margin-top: 0vh;

  /* Add drop shadow */
  filter: drop-shadow(0 4px 6px rgba(0, 0, 0, 0.1));

  /* Add floating animation */
  animation: ${float} 6s ease-in-out infinite;

  /* Add smooth transition for hover effect */
  transition: all 0.3s ease;

  /* Optional: Add subtle hover effect */
  &:hover {
    transform: scale(1.05) translateY(-2px);
    filter: drop-shadow(0 6px 8px rgba(0, 0, 0, 0.15));
    animation-play-state: paused;
  }

  /* Make animation smoother on higher-end devices */
  @media (prefers-reduced-motion: no-preference) {
    will-change: transform;
  }
`;


export const SpeechBubbleText = styled.div`
  position: absolute;
  top: 35%;
  left: 40%;
  transform: translate(-50%, -50%);
  font-family: ${theme.fonts.main};
  font-weight: ${theme.fontWeights.bold};
  font-size: ${theme.fontSizes.xxlarge};
  color: ${theme.colors.noir};
  text-align: flex-end;
  width: 80%;
  line-height: 1.4;
`;

const cursorBlink = keyframes`
  0%, 100% { opacity: 1; }
  50% { opacity: 0; }
`;

export const InputContainer = styled.div`
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 90%;
  max-width: 400px;
  display: flex;
  justify-content: flex-start; /* Align content to the left */
  padding: 0 ${theme.spacing.large};

  /* Shadow effect */
  &::after {
    content: '';
    position: absolute;
    top: 100%;
    left: 50%;
    transform: translateX(-50%);
    width: 80%;
    height: 40px;
    background: radial-gradient(ellipse at top, rgba(0, 0, 0, 0.05) 0%, rgba(0, 0, 0, 0) 70%);
    pointer-events: none;
  }
`;

export const InputWrapper = styled.div`
  position: relative;
  width: 100%;
  display: flex;
  align-items: center;
  overflow: visible;
`;

export const TextMeasure = styled.span`
  visibility: hidden;
  position: absolute;
  white-space: pre;
  font-size: ${theme.fontSizes.large};
  font-family: ${theme.fonts.main};
  pointer-events: none;
`;


export const StyledInput = styled.input`
  width: 100%;
  padding: ${theme.spacing.small} 0;
  border: none;
  background: transparent;
  font-size: ${theme.fontSizes.large};
  font-family: ${theme.fonts.main};
  color: ${theme.colors.noir};
  outline: none;
  caret-color: transparent;

  &::placeholder {
    opacity: 0;
  }

  &::selection {
    background: transparent;
  }
`;

export const Cursor = styled.div`
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  width: 2px;
  height: 32px;
  background-color: ${theme.colors.gris};
  animation: ${cursorBlink} 1.2s cubic-bezier(0.68, -0.55, 0.265, 1.55) infinite;
  transition: left 0.1s ease-out;
`;

export const ReturnButton = styled.button`
  position: fixed;
  top: ${theme.spacing.large};
  left: ${theme.spacing.large};
  background: transparent;
  border: none;
  cursor: pointer;
  color: ${theme.colors.lightGray};
  padding: ${theme.spacing.small};
  display: flex;
  align-items: center;
  justify-content: center;
  transition: transform 0.3s ease;
  z-index: 10;

  svg {
    width: 24px;
    height: 24px;
    stroke: ${theme.colors.primary};
    stroke-width: 2.5px;
  }

  &:hover {
    transform: translateX(-3px);

    svg {
      stroke: ${theme.colors.secondary};
    }
  }
`;

export const SubmitButton = styled(BaseButton)`
  background: ${props => props.background || theme.colors.primary};
  color: ${props => props.color || theme.colors.lightText};
  border: 3px solid ${theme.colors.primary};
  position: fixed;
  bottom: calc(var(--vh, 1vh) * 11);
  left: 50%;
  width: 90%;
  border-radius: 20px;
  transform: translateX(-50%);
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.12);
  transition: all 0.3s ease;
  opacity: ${props => props.show ? 1 : 0};
  visibility: ${props => props.show ? 'visible' : 'hidden'};
  transform: translateX(-50%) translateY(${props => props.show ? '0' : '20px'});

  &:hover {
    background: ${props => props.hoverBackground || theme.colors.secondary};
    box-shadow: 0 6px 16px rgba(0, 0, 0, 0.16);
    transform: translateX(-50%) translateY(-2px);
  }

  &:active {
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
    transform: translateX(-50%) translateY(0);
  }
`;