import React, { useState, useEffect } from 'react';
import {
  Container,
  ContentWrapper,
  ChatBubbleContainer,
  ChatText,
  ButtonsContainer,
  StoreButton,
  LogoImage
} from './styles';
import playstoreIcon from '../../assets/logo_playstore.png';
import appstoreIcon from '../../assets/logo_appstore.png';
import meoImage from '../../assets/meo.png';
import useDeviceType from '../useDeviceType';

const DownloadApp = () => {
  const [isTyping, setIsTyping] = useState(true);
  const [text, setText] = useState('');
  const fullText = "Cette fonctionalité est disponible sur la version mobile pour le moment !";
  const { isMobile } = useDeviceType();

  useEffect(() => {
    let currentIndex = 0;
    const typingInterval = setInterval(() => {
      if (currentIndex <= fullText.length) {
        setText(fullText.slice(0, currentIndex));
        currentIndex++;
      } else {
        setIsTyping(false);
        clearInterval(typingInterval);
      }
    }, 15);

    return () => clearInterval(typingInterval);
  }, []);

  const handlePlayStoreClick = () => {
    window.open('https://play.google.com/store/apps/your-app-link', '_blank');
  };

  const handleAppStoreClick = () => {
    window.open('https://apps.apple.com/app/your-app-link', '_blank');
  };

  return (
    <Container>
      <ContentWrapper>
        <ChatBubbleContainer>
          <ChatText $isVisible={text.length > 0}>
            <span>{text}</span>
          </ChatText>
        </ChatBubbleContainer>

        <LogoImage 
          src={meoImage} 
          alt="Meo" 
        />

     </ContentWrapper>
    </Container>
  );
};

export default DownloadApp;