import styled, { keyframes } from 'styled-components';
import theme from '../../theme';

export const Container = styled.div`
  width: 100vw;
  height: calc(var(--vh, 1vh) * 80);
  margin-top: calc(var(--vh, 1vh) * 10);
  padding: ${props => props.theme.spacing.large};
  position: relative;
  background-color: transparent;
`;

export const Title = styled.h2`
  position: absolute;
  bottom: calc(var(--vh, 1vh) * 85);
  left: 50%;
  transform: translateX(-50%);
  color: ${props => props.theme.colors.noir};
  font-size: clamp(${props => props.theme.fontSizes.large}, 4vw, ${props => props.theme.fontSizes.xlarge});
  margin: 0;
  padding: 0;
  text-align: center;
  width: 100%;
`;

export const SubTitle = styled.h2`
  position: absolute;
  top: calc(var(--vh, 1vh) * 3);
  left: 50%;
  transform: translateX(-50%);
  color: ${props => props.theme.colors.noir};
  font-size: clamp(${props => props.theme.fontSizes.xsmall}, 3vw, ${props => props.theme.fontSizes.small});
  font-weight: ${theme.fontWeights.semiBold};
  margin: 0;
  padding: 0;
  width: min(100%, 300px);
  text-align: left;
`;

export const Input = styled.input`
  position: absolute;
  margin-top: calc(var(--vh, 1vh) * -25);
  margin-left: 0%;
  width: min(100%, 300px);
  padding: clamp(0.5rem, 7%, 1.5rem);
  border: 1px solid ${props => props.theme.colors.gris_clair};
  border-radius: ${props => props.theme.spacing.medium};
  font-size: clamp(${props => props.theme.fontSizes.small}, 3vw, ${props => props.theme.fontSizes.medium});
`;

export const Button = styled.button`
  display: ${({ hide }) => (hide ? 'none' : 'flex')};
  align-items: center;
  justify-content: center;
  width: min(100%, 300px);
  height: auto;
  padding: ${props => props.theme.spacing.small} ${props => props.theme.spacing.medium};
  background-color: transparent;
  color: ${props => props.theme.colors.primary};
  border: 1px solid ${props => props.theme.colors.primary};
  border-radius: ${props => props.theme.spacing.small};
  cursor: pointer;
  font-size: clamp(${props => props.theme.fontSizes.small}, 3vw, ${props => props.theme.fontSizes.medium});
  margin-bottom: ${props => props.theme.spacing.medium};
  transition: background-color 0.3s, color 0.3s;

  &:hover {
    background-color: ${props => `${props.theme.colors.primary}1A`};
  }

  &:disabled {
    opacity: 0.6;
    cursor: not-allowed;
  }
`;

export const BaseButton = styled.button`
  border-radius: 10px;
  padding: ${props => props.padding || `${theme.spacing.medium} ${theme.spacing.xlarge}`};
  font-family: ${props => props.fontFamily || theme.fonts.main};
  font-size: clamp(${props => props.theme.fontSizes.small}, 3vw, ${props => props.theme.fontSizes.medium});
  font-weight: ${props => props.fontWeight || theme.fontWeights.semiBold};
  cursor: pointer;
  width: ${props => props.width || 'min(50%, 300px)'};
  transition: all 0.3s ease;

  &:disabled {
    opacity: 0.6;
    cursor: not-allowed;
  }
`;

export const SendButton = styled(BaseButton)`
  background: ${props => props.background || theme.colors.primary};
  color: ${props => props.color || theme.colors.lightText};
  border: 3px solid ${props => props.borderColor || theme.colors.primary};
  position: absolute;
  margin-top: calc(var(--vh, 1vh) * -14);
  left: 50%;
  width: min(90vw, 300px);
  border-radius: 20px;
  transform: translateX(-50%);
  transition: opacity 0.3s ease;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.12);

  ${props => props.disabled && `
    opacity: 0.6;
    cursor: not-allowed;
    background: ${`${props.background || theme.colors.primary}`};
  `}

  ${props => !props.disabled && `
    opacity: 1;
    cursor: pointer;
  `}
`;

export const Form = styled.form`
  position: relative;
  width: min(100%, 300px);
  margin: 0 auto;
`;

export const ButtonGoogle = styled.button`
  background: ${theme.colors.gris_clair_clair};
  color: ${props => props.color || theme.colors.lightText};
  border: 0px solid ${props => props.borderColor || theme.colors.primary};
  position: absolute;
  margin-top: calc(var(--vh, 1vh) * 34);
  left: 50%;
  width: min(90vw, 300px);
  padding: ${props => props.padding || `${theme.spacing.medium} ${theme.spacing.xlarge}`};
  border-radius: 20px;
  transform: translateX(-50%);
  transition: opacity 0.3s ease;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.12);
  cursor: pointer;
`;

export const GoogleButtonContent = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: ${props => props.gap || '8px'};
  margin-left: -20px;
`;

export const GoogleIcon = styled.img`
  width: clamp(16px, 5vw, 20px);
  height: clamp(16px, 5vw, 20px);
  object-fit: contain;
`;

export const ButtonTextGoogle = styled.span`
  color: ${theme.colors.noir};
  font-size: clamp(${props => props.theme.fontSizes.small}, 3vw, ${props => props.theme.fontSizes.medium});
  font-weight: ${props => props.fontWeight || theme.fontWeights.semiBold};
  text-align: center;
`;

export const Wrapper = styled.div`
  position: relative;
  width: 100vw;
  height: calc(var(--vh, 1vh) * 100);
`;

export const EmailLoginContainer = styled.div`
  position: absolute;
  bottom: calc(var(--vh, 1vh) * 45);
  left: 50%;
  transform: translateX(-50%);
  width: min(100%, 300px);
`;

export const ReturnButton = styled.button`
  position: fixed;
  top: max(${theme.spacing.large}, 3vh);
  left: max(${theme.spacing.large}, 3vw);
  background: transparent;
  border: none;
  cursor: pointer;
  color: ${theme.colors.primary};
  padding: ${theme.spacing.small};
  display: flex;
  align-items: center;
  justify-content: center;
  transition: transform 0.3s ease;
  z-index: 10;

  svg {
    width: clamp(20px, 6vw, 24px);
    height: clamp(20px, 6vw, 24px);
    stroke: ${theme.colors.primary};
    stroke-width: 2.5px;
  }

  &:hover {
    transform: translateX(-3px);
    svg {
      stroke: ${theme.colors.secondary};
    }
  }
`;

export const NextButton = styled(ReturnButton)`
  right: max(${theme.spacing.large}, 3vw);
  left: auto;

  &:hover {
    transform: translateX(3px);
    svg {
      stroke: ${theme.colors.secondary};
    }
  }
`;

const float = keyframes`
  0% { transform: translateY(0px) rotate(0deg); }
  25% { transform: translateY(-3px) rotate(1deg); }
  50% { transform: translateY(-5px) rotate(0deg); }
  75% { transform: translateY(-3px) rotate(-1deg); }
  100% { transform: translateY(0px) rotate(0deg); }
`;


export const LogoImage = styled.img`
  width: ${props => props.isMobile ? '25%' : '15%'};
  height: auto;
  margin-left: ${props => props.isMobile ? '37.5%' : '42.5%'};
  margin-top: calc(var(--vh, 1vh) * 3);
  filter: drop-shadow(0 4px 6px rgba(0, 0, 0, 0.1));
  animation: ${float} 6s ease-in-out infinite;
  transition: all 0.3s ease;
  
  &:hover {
    transform: scale(1.05) translateY(-2px);
    filter: drop-shadow(0 6px 8px rgba(0, 0, 0, 0.15));
    animation-play-state: paused;
  }
  
  @media (prefers-reduced-motion: no-preference) {
    will-change: transform;
  }
`;

export const ButtonLabel = styled.span`
  color: ${props => props.theme.colors.primary};
  font-size: clamp(${props => props.theme.fontSizes.xsmall}, 2.5vw, ${props => props.theme.fontSizes.small});
  font-weight: ${props => props.theme.fontWeights.medium};
  margin: ${props => props.isBack ? '0 0 0 8px' : '0 8px 0 0'};
  order: ${props => props.isBack ? 2 : 1};
`;

export const Logo = styled.img`
  width: clamp(1.2em, 4vw, 1.5em);
  height: clamp(1.2em, 4vw, 1.5em);
  margin-right: ${props => props.theme.spacing.small};
`;

export const Message = styled.p`
  color: ${props => props.theme.colors.text};
  background-color: ${props => props.theme.colors.background};
  padding: ${props => props.theme.spacing.small};
  border-radius: ${props => props.theme.spacing.small};
  margin-top: ${props => props.theme.spacing.medium};
  text-align: center;
  width: min(100%, 300px);
  border: 1px solid ${props => props.theme.colors.primary};
  font-size: clamp(${props => props.theme.fontSizes.xsmall}, 2.5vw, ${props => props.theme.fontSizes.small});
`;

export const CountdownText = styled.p`
  color: ${props => props.theme.colors.primary};
  font-size: clamp(${props => props.theme.fontSizes.xsmall}, 2.5vw, ${props => props.theme.fontSizes.small});
  text-align: center;
  
`;

export const Divider = styled.div`
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  width: min(90vw, 300px);
  display: flex;
  align-items: center;
  justify-content: center;
  gap: ${theme.spacing.medium};
  margin-top: calc(var(--vh, 1vh) * 31);
`;

export const DividerLine = styled.span`
  flex: 1;
  height: 1px;
  background-color: ${theme.colors.noir};
  opacity: 0.3;
`;

export const DividerText = styled.span`
  color: ${theme.colors.noir};
  font-size: clamp(${props => props.theme.fontSizes.xsmall}, 2.5vw, ${props => props.theme.fontSizes.small});
  font-weight: ${theme.fontWeights.medium};
  padding: 0 ${theme.spacing.small};
  opacity: 0.6;
`;


export const AlertOverlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
`;

export const AlertBox = styled.div`
  background-color: white;
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  text-align: center;
  max-width: 90%;
  width: 300px;
`;

export const AlertMessage = styled.p`
  margin: 0 0 20px 0;
  color: #ef4444;
  font-size: 16px;
`;

export const AlertButton = styled.button`
  background-color: #ef4444;
  color: white;
  border: none;
  padding: 8px 16px;
  border-radius: 4px;
  cursor: pointer;
  font-weight: 500;

  &:hover {
    background-color: #dc2626;
  }
`;


export const ButtonApple = styled.button`
  background: ${theme.colors.gris_clair_clair};
  color: ${props => props.color || theme.colors.lightText};
  border: 0px solid ${props => props.borderColor || theme.colors.primary};
  position: absolute;
  margin-top: calc(var(--vh, 1vh) * 44);
  left: 50%;
  width: min(90vw, 300px);
  padding: ${props => props.padding || `${theme.spacing.medium} ${theme.spacing.xlarge}`};
  border-radius: 20px;
  transform: translateX(-50%);
  transition: opacity 0.3s ease;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.12);
  cursor: pointer;
`;

export const AppleButtonContent = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: ${props => props.gap || '8px'};
  margin-left: -20px;
`;

export const AppleIcon = styled.img`
  width: clamp(16px, 5vw, 20px);
  height: clamp(16px, 5vw, 20px);
  object-fit: contain;
`;

export const ButtonTextApple = styled.span`
  color: ${theme.colors.noir};
  font-size: clamp(${props => props.theme.fontSizes.small}, 3vw, ${props => props.theme.fontSizes.medium});
  font-weight: ${props => props.fontWeight || theme.fontWeights.semiBold};
  text-align: center;
`;