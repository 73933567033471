
import styled, { keyframes } from 'styled-components';
import theme from '../../theme';

export const OptionsContainer = styled.div`
  position: relative;
  width: 90%;
  margin-left: 5%;
  min-height: calc(var(--vh, 1vh) * 45);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: ${theme.spacing.xlarge};
  margin-bottom: calc(var(--vh, 1vh) * 15);

  @media (max-height: 550px) {
    min-height: calc(var(--vh, 1vh) * 40);
    gap: ${theme.spacing.large};
  }
`;

const float = keyframes`
  0% {
    transform: translateY(0px) rotate(0deg);
  }
  25% {
    transform: translateY(-3px) rotate(1deg);
  }
  50% {
    transform: translateY(-5px) rotate(0deg);
  }
  75% {
    transform: translateY(-3px) rotate(-1deg);
  }
  100% {
    transform: translateY(0px) rotate(0deg);
  }
`;

export const LogoImage = styled.img`
  width: 20%;
  height: 20%;
  object-fit: contain;
  margin-left: 10%;

  /* Add drop shadow */
  filter: drop-shadow(0 4px 6px rgba(0, 0, 0, 0.1));

  /* Add floating animation */
  animation: ${float} 6s ease-in-out infinite;

  /* Add smooth transition for hover effect */
  transition: all 0.3s ease;

  /* Optional: Add subtle hover effect */
  &:hover {
    transform: scale(1.05) translateY(-2px);
    filter: drop-shadow(0 6px 8px rgba(0, 0, 0, 0.15));
    animation-play-state: paused;
  }

  /* Make animation smoother on higher-end devices */
  @media (prefers-reduced-motion: no-preference) {
    will-change: transform;
  }
`;

export const OptionButton = styled.button`
  width: 100%;
  height: calc(var(--vh, 1vh) * 10);
  max-height: 70px;
  min-height: 50px;
  border-radius: calc(var(--vh, 1vh) * 2.5);
  background: ${props => props.isSelected ? '#FFFFFF' : theme.colors.background_fonce};
  border: 1px solid ${props => props.isSelected ? theme.colors.gris : 'transparent'};
  color: ${theme.colors.noir};
  font-family: ${theme.fonts.main};
  font-size: ${theme.fontSizes.large};
  font-weight: ${theme.fontWeights.medium};
  cursor: pointer;
  transition: all 0.3s ease;
  -webkit-tap-highlight-color: transparent;
  text-align: left;
  padding: ${theme.spacing.medium};

  user-select: none;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;

  &:hover {
    transform: translateY(calc(var(--vh, 1vh) * -0.25));
    box-shadow: 0 calc(var(--vh, 1vh) * 0.5) calc(var(--vh, 1vh) * 1.5) rgba(0, 0, 0, 0.1);
  }

  &:active {
    transform: translateY(0);
    box-shadow: 0 calc(var(--vh, 1vh) * 0.25) calc(var(--vh, 1vh) * 1) rgba(0, 0, 0, 0.1);
  }

  ${props => props.isSelected && `
    animation: selectPulse 0.3s ease-out;

    @keyframes selectPulse {
      0% { transform: scale(1); }
      50% { transform: scale(1.02); }
      100% { transform: scale(1); }
    }
  `}

  ${props => props.isSelected && `
    &:hover {
      transform: none;
      box-shadow: 0 calc(var(--vh, 1vh) * 0.5) calc(var(--vh, 1vh) * 1.5) rgba(0, 0, 0, 0.1);
    }
  `}

  @media (max-height: 550px) {
    height: calc(var(--vh, 1vh) * 10);
    min-height: 40px;
    font-size: ${theme.fontSizes.medium};
    padding: ${theme.spacing.small} ${theme.spacing.medium};
  }
`;

export const ButtonsWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: calc(var(--vh, 1vh) * 3);
  opacity: 0;
  transform: translateY(calc(var(--vh, 1vh) * 2.5));
  animation: fadeIn 0.5s ease forwards;

  @keyframes fadeIn {
    to {
      opacity: 1;
      transform: translateY(0);
    }
  }

  @media (max-height: 550px) {
    gap: calc(var(--vh, 1vh) * 2);
  }
`;

export const ErrorMessage = styled.div`
  color: ${theme.colors.error};
  text-align: center;
  margin: calc(var(--vh, 1vh) * 2) 0;
  font-size: ${theme.fontSizes.small};
  min-height: calc(var(--vh, 1vh) * 3);
  opacity: 0;
  animation: fadeIn 0.3s ease forwards;
`;

export const LoadingOverlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: calc(var(--vh, 1vh) * 100);
  background: rgba(255, 255, 255, 0.8);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
  opacity: 0;
  pointer-events: ${props => props.isLoading ? 'auto' : 'none'};
  transition: opacity 0.3s ease;
  ${props => props.isLoading && 'opacity: 1;'}
>>>>>>> 51c7ff158d18283e8b87423030b10a824d3e13d9
`;