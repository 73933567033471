import styled, { keyframes } from 'styled-components';
import theme from '../../theme';

const float = keyframes`
  0% {
    transform: translateY(0px) rotate(0deg);
  }
  25% {
    transform: translateY(-3px) rotate(1deg);
  }
  50% {
    transform: translateY(-5px) rotate(0deg);
  }
  75% {
    transform: translateY(-3px) rotate(-1deg);
  }
  100% {
    transform: translateY(0px) rotate(0deg);
  }
`;

export const Container = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 100vh;
  background-color: ${theme.colors.background};
  padding: ${theme.spacing.medium};
`;

export const ContentWrapper = styled.div`
  position: relative;
  width: 100%;
  max-width: 600px;
  padding-top: 120px;
`;

export const ChatBubbleContainer = styled.div`
  position: absolute;
  top: 0%;
  left: 50%;
  transform: translateX(-50%);
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  max-width: 300px;
  z-index: 2;
`;

export const ChatText = styled.div`
  background-color: transparent;
  color: ${theme.colors.black};
  padding: ${theme.spacing.medium} ${theme.spacing.large};
  border-radius: ${theme.spacing.medium};
  font-family: "Sour Gummy", sans-serif;
  font-size: ${theme.fontSizes.medium};
  text-align: center;
  position: relative;
  width: 230px;
  height: 100px;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 2px solid ${theme.colors.lightGray};
  
  & span {
    opacity: ${props => props.$isVisible ? 1 : 0};
    transform: translateY(${props => props.$isVisible ? '0' : '-10px'});
    transition: opacity 0.3s ease-out, transform 0.3s ease-out;
  }

  &:after {
    content: '';
    position: absolute;
    bottom: -12px;
    left: 50%;
    transform: translateX(-50%);
    border-left: 10px solid transparent;
    border-right: 10px solid transparent;
    border-top: 10px solid ${theme.colors.lightGray};
  }

  &:before {
    content: '';
    position: absolute;
    bottom: -9px;
    left: 50%;
    transform: translateX(-50%);
    border-left: 8px solid transparent;
    border-right: 8px solid transparent;
    border-top: 8px solid transparent;
    z-index: 1;
  }
`;

export const LogoImage = styled.img`
  width: 20%;
  object-fit: contain;
  margin-bottom: 20%;
  filter: drop-shadow(0 4px 6px rgba(0, 0, 0, 0.1));
  animation: ${float} 6s ease-in-out infinite;
  transition: all 0.3s ease;

  &:hover {
    transform: scale(1.05) translateY(-2px);
    filter: drop-shadow(0 6px 8px rgba(0, 0, 0, 0.15));
    animation-play-state: paused;
  }
`;

export const ButtonsContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${theme.spacing.medium};
  width: 100%;
  max-width: 300px;
  margin-top: auto;
`;

export const StoreButton = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: ${theme.spacing.medium};
  background: ${theme.colors.primary};
  color: ${theme.colors.lightText};
  border: none;
  width: 100%;
  padding: ${theme.spacing.medium};
  border-radius: 10px;
  font-family: ${theme.fonts.main};
  font-size: ${theme.fontSizes.medium};
  font-weight: ${theme.fontWeights.semiBold};
  cursor: pointer;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.12);
  transition: transform 0.2s ease, box-shadow 0.2s ease;

  img {
    height: 24px;
    width: auto;
  }

  &:hover {
    transform: translateY(-2px);
    box-shadow: 0 6px 16px rgba(0, 0, 0, 0.16);
  }

  &:active {
    transform: translateY(0);
    box-shadow: 0 4px 12px rgba(0, 0, 0, 0.12);
  }

  &:disabled {
    opacity: 0.6;
    cursor: not-allowed;
  }
`;