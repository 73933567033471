import React, { useState } from 'react';
import { MdHealthAndSafety } from "react-icons/md";
import { FaComputer } from "react-icons/fa6";
import { GoLaw } from "react-icons/go";
import { GiReceiveMoney } from "react-icons/gi";
import {
  WelcomeMenuWrapper,
  AppLogo,
  WelcomeMessage,
  WelcomeDescription,
  ExampleBox,
  ExamplesContainer,
  ContentWrapper,
  ExampleContent,
  IconWrapper
} from './styles';
import appLogo from '../../assets/boussole.png';

const WelcomeMenu = ({ onExampleClick, isSideMenuOpen, isMobile }) => {
  const [imageError, setImageError] = useState(false);

  const examples = [
    {
      text: 'PASS, LAS, P0, je veux juste être médecin moi...',
      icon: <MdHealthAndSafety />,
      color: '#22c55e'  // green
    },
    {
      text: "J'adore coder, t'as une école pour moi ?",
      icon: <FaComputer />,
      color: '#3b82f6'  // blue
    },
    {
      text: "J'ai déjà mon école ! Comment avoir la bourse ?",
      icon: <GiReceiveMoney />,
      color: '#eab308'  // yellow
    },
    {
      text: "Je veux faire du droit mais j'ai un dossier vraiment moyen...",
      icon: <GoLaw />,
      color: '#92400e'  // brown
    },
  ];

  const handleImageError = () => {
    console.error('Failed to load the image');
    setImageError(true);
  };

  return (
    <WelcomeMenuWrapper
      isSideMenuOpen={isSideMenuOpen}
      isMobile={isMobile}
    >
      <ContentWrapper>
        {!imageError && (
          <AppLogo src={appLogo} alt="App Logo" onError={handleImageError} />
        )}
        <WelcomeMessage>Bienvenue chez Meoria !</WelcomeMessage>
        <WelcomeDescription>
          Je suis Meo. Pose-moi tes questions sur l'orientation et je ferai tout mon possible pour y répondre.
        </WelcomeDescription>
        <ExamplesContainer isSideMenuOpen={isSideMenuOpen} isMobile={isMobile}>
          {examples.map((example, index) => (
            <ExampleBox
              key={index}
              onClick={() => onExampleClick(example.text)}
              isSideMenuOpen={isSideMenuOpen}
              isMobile={isMobile}
            >
              <IconWrapper color={example.color}>
                {example.icon}
              </IconWrapper>
              <ExampleContent>
                {example.text}
              </ExampleContent>
            </ExampleBox>
          ))}
        </ExamplesContainer>
      </ContentWrapper>
    </WelcomeMenuWrapper>
  );
};

export default WelcomeMenu;