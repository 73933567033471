import React, { useEffect, useState, useCallback } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { IoIosArrowBack, IoIosArrowForward } from "react-icons/io";
import {
  Container,
  LogoImage,
  GoogleButtonContent,
  TermsText,
  TermsLink,
  Wrapper,
  SendButton,
  Divider,
  NextButton,
  DividerLine,
  DividerText,
  SubTitle,
  GoogleIcon,
  ButtonGoogle,
  ButtonTextGoogle,
  Input,
  Form,
  EmailLoginContainer,
  Message,
  CountdownText,
} from './styles';
import useDeviceType from '../useDeviceType';
import { useAuth } from '../../contexts/AuthContext';
import Meo from '../../assets/meo.png';
import GoogleLogo from '../../assets/google_logo.png';
import { AlertOverlay, AlertBox, AlertMessage, AlertButton } from './styles';
import AppleLogo from '../../assets/apple_logo.svg'; // You'll need to add this asset
import { ButtonApple, AppleButtonContent, AppleIcon, ButtonTextApple } from './styles';
import {
  ReturnButton,
} from '../AccountCreation/styles';
import { BottomActionsContainer, StyledText, TextButton } from '../FirstPage/styles';

const FirstLoginAccount = () => {
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);
  const [email, setEmail] = useState('');
  const [showVerificationView, setShowVerificationView] = useState(false);
  const [verificationMessage, setVerificationMessage] = useState('');
  const [countdown, setCountdown] = useState(0);
  const [isVerifying, setIsVerifying] = useState(false);
  const location = useLocation();
  const [isValidInput, setIsValidInput] = useState(false);
  const [isLoadingGoogle, setIsLoadingGoogle] = useState(false);
  const [isEmailSubmitted, setIsEmailSubmitted] = useState(false);
  const [hasEmailBeenSent, setHasEmailBeenSent] = useState(false);
  const { login, error: authError } = useAuth();
  const [showEmailExistsAlert, setShowEmailExistsAlert] = useState(false);
  const [verificationCode, setVerificationCode] = useState('');
  const [errorMessage, setErrorMessage] = useState('');
  const APPLE_CLIENT_ID = process.env.REACT_APP_APPLE_CLIENT_ID;
  const { isMobile } = useDeviceType();

  console.log('Apple Client ID:', process.env.REACT_APP_APPLE_CLIENT_ID);
  console.log('Backend URL:', process.env.REACT_APP_BACKEND_URL);

  const handleBack = () => {
    if (showVerificationView) {
      setShowVerificationView(false);
      setVerificationCode('');
    } else {
      navigate(-1);
    }
  };

  const handleNext = () => {
      if (hasEmailBeenSent) {
        setShowVerificationView(true);
      }
    };

  const handleViewCGU = () => {
      window.open("https://meoria.com/CGU.pdf", '_blank');
    };

    const handleViewPolitique = () => {
      window.open("https://meoria.com/politique.pdf", '_blank');
    };

    const updateUserData = async (token) => {
      const pseudo = localStorage.getItem('pseudo');
      const gender = localStorage.getItem('gender');
      const userType = localStorage.getItem('userType');
      const city = localStorage.getItem('user_city'); // Add city from localStorage
    
      console.log('Updating user data:', { pseudo, gender, userType, city });
    
      if (!pseudo && !gender && !userType && !city) {
        console.error('No user data found in localStorage');
        return false;
      }
    
      try {
        // Update pseudonym
        if (pseudo) {
          const pseudoResponse = await fetch(`${process.env.REACT_APP_BACKEND_URL}/auth/update_pseudonym/`, {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
              'Authorization': `Bearer ${token}`,
            },
            body: JSON.stringify({ pseudonym: pseudo }),
          });
    
          if (!pseudoResponse.ok) {
            throw new Error('Failed to update pseudonym');
          }
        }
    
        // Update gender
        if (gender) {
          const genderResponse = await fetch(`${process.env.REACT_APP_BACKEND_URL}/auth/update_gender/`, {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
              'Authorization': `Bearer ${token}`,
            },
            body: JSON.stringify({ gender }),
          });
    
          if (!genderResponse.ok) {
            throw new Error('Failed to update gender');
          }
        }
    
        // Update user type
        if (userType) {
          const typeResponse = await fetch(`${process.env.REACT_APP_BACKEND_URL}/auth/update_user_type/`, {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
              'Authorization': `Bearer ${token}`,
            },
            body: JSON.stringify({ user_type: userType }),
          });
    
          if (!typeResponse.ok) {
            throw new Error('Failed to update user type');
          }
        }
    
        // Update city
        if (city !== null) { // Check for null specifically to allow empty string
          const cityResponse = await fetch(`${process.env.REACT_APP_BACKEND_URL}/auth/update_city/`, {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
              'Authorization': `Bearer ${token}`,
            },
            body: JSON.stringify({ city }),
          });
    
          if (!cityResponse.ok) {
            throw new Error('Failed to update city');
          }
        }
    
        // Update user characteristics in a single call if any of these exists
        const typeUtilisateur = localStorage.getItem('type_utilisateur');
        const niveauInformation = localStorage.getItem('niveau_information');
        const sourceConnaissance = localStorage.getItem('source_connaissance');
    
        if (typeUtilisateur || niveauInformation || sourceConnaissance || city !== null) {
          const characteristicsResponse = await fetch(`${process.env.REACT_APP_BACKEND_URL}/auth/update_user_characteristics/`, {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
              'Authorization': `Bearer ${token}`,
            },
            body: JSON.stringify({
              ...(typeUtilisateur && { type_utilisateur: typeUtilisateur }),
              ...(niveauInformation && { niveau_information: niveauInformation }),
              ...(sourceConnaissance && { source_connaissance: sourceConnaissance }),
              ...(city !== null && { city }),
            }),
          });
    
          if (!characteristicsResponse.ok) {
            throw new Error('Failed to update user characteristics');
          }
        }
    
        // Clear the localStorage after successful updates
        localStorage.removeItem('pseudo');
        localStorage.removeItem('gender');
        localStorage.removeItem('userType');
        localStorage.removeItem('user_city');
        localStorage.removeItem('type_utilisateur');
        localStorage.removeItem('niveau_information');
        localStorage.removeItem('source_connaissance');
    
        return true;
      } catch (error) {
        console.error('Error updating user data:', error);
        return false;
      }
    };

    const handleLoginSuccess = useCallback(async (tokens, method, is_new_user = false, userData = null) => {
      try {
        // First update the user data with the access token
        const userDataUpdated = await updateUserData(tokens.access);
        if (!userDataUpdated) {
          console.error('Failed to update user data');
        }
    
        // If this is a new user and we have stored characteristics, update them
        if (is_new_user) {
          const typeUtilisateur = localStorage.getItem('type_utilisateur');
          const niveauInformation = localStorage.getItem('niveau_information');
          const sourceConnaissance = localStorage.getItem('source_connaissance');
    
          // Only make the API call if we have at least one characteristic to update
          if (typeUtilisateur || niveauInformation || sourceConnaissance) {
            try {
              const response = await fetch(`${process.env.REACT_APP_BACKEND_URL}/auth/update_user_characteristics/`, {
                method: 'POST',
                headers: {
                  'Authorization': `Bearer ${tokens.access}`,
                  'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                  type_utilisateur: typeUtilisateur,
                  niveau_information: niveauInformation,
                  source_connaissance: sourceConnaissance
                })
              });
    
              if (response.ok) {
                // Clear the characteristics from localStorage after successful update
                localStorage.removeItem('type_utilisateur');
                localStorage.removeItem('niveau_information');
                localStorage.removeItem('source_connaissance');
              } else {
                console.error('Failed to update user characteristics');
              }
            } catch (error) {
              console.error('Error updating user characteristics:', error);
            }
          }
        }
    
        // Complete the login process
        await login(tokens, method, is_new_user, userData);
    
      } catch (error) {
        console.error('Error during login:', error);
        setVerificationMessage('Une erreur est arrivée. Veuillez réessayer ou envoyer un message au support si le problème persiste.');
      }
    }, [login]);

  const verifyEmailCode = useCallback(async (email, code) => {
    if (isVerifying) {
      console.log('Already processing a verification, please wait...');
      return;
    }
    setIsVerifying(true);
    setIsLoading(true);
    try {
      console.log('Verifying code for email:', email);
      const response = await fetch(`${process.env.REACT_APP_BACKEND_URL}/auth/email/verify_code/`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ email, code }),
      });

      const data = await response.json();
      console.log('Server response:', data);

      if (!response.ok) {
        throw new Error(data.error || `HTTP error! status: ${response.status}`);
      }

      if (data.token && data.token.access && data.token.refresh) {
        await handleLoginSuccess(data.token, 'email', data.is_new_user);
      } else {
        throw new Error('Invalid response from server');
      }
    } catch (error) {
      console.error('Error verifying email:', error);
      setVerificationMessage(error.message || 'Code expir� ou invalide. Veuillez r�essayer.');
    } finally {
      setTimeout(() => {
        setIsLoading(false);
        setIsVerifying(false);
      }, 1000);
    }
  }, [handleLoginSuccess]);


  const handleGoogleResponse = useCallback(async (response) => {
    setIsLoading(true);
    try {
      // First decode the JWT to get the email
      const decodedToken = JSON.parse(atob(response.credential.split('.')[1]));
      const email = decodedToken.email;

      // Check if email exists
      const emailExists = await checkEmailExists(email);

      if (emailExists) {
        setShowEmailExistsAlert(true);
        setIsLoading(false);
        return;
      }

      // If email doesn't exist, proceed with Google authentication
      const res = await fetch(`${process.env.REACT_APP_BACKEND_URL}/auth/google/callback/`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          token: response.credential
        }),
      });

      const data = await res.json();

      if (res.ok) {
        await handleLoginSuccess(data.token, 'google', data.is_new_user);
      } else {
        throw new Error(data.error || 'Failed to authenticate with Google');
      }
    } catch (error) {
      console.error('Error during Google authentication:', error);
      setErrorMessage('Une erreur est survenue lors de l\'authentification Google. Veuillez r�essayer.');
    } finally {
      setIsLoading(false);
      setIsLoadingGoogle(false);
    }
  }, [handleLoginSuccess]);

  const handleGoogleLogin = useCallback((e) => {
       e.preventDefault();
       setIsLoadingGoogle(true);

       if (window.google?.accounts?.id) {
         // Create a hidden button element
         const buttonDiv = document.createElement('div');
         buttonDiv.style.display = 'none';
         document.body.appendChild(buttonDiv);

         // Render the Google button
         window.google.accounts.id.renderButton(buttonDiv, {
           type: 'standard',
           theme: 'outline',
           size: 'large',
         });

         // Click the button programmatically
         const googleButton = buttonDiv.querySelector('div[role="button"]');
         if (googleButton) {
           googleButton.click();
           // Remove the hidden button after a short delay
           setTimeout(() => buttonDiv.remove(), 100);
         }
       } else {
         console.error('Google Sign-In API not loaded');
         setIsLoadingGoogle(false);
       }
     }, []);



  useEffect(() => {
        const loadGoogleScript = async () => {
          // Load the Google Sign-In API script
          const script = document.createElement('script');
          script.src = 'https://accounts.google.com/gsi/client';
          script.async = true;
          script.defer = true;

          // Initialize Google Sign-In after script loads
          script.onload = () => {
            if (window.google?.accounts?.id) {
              window.google.accounts.id.initialize({
                client_id: process.env.REACT_APP_GOOGLE_CLIENT_ID,
                callback: handleGoogleResponse,
                auto_select: false,
                cancel_on_tap_outside: true
              });
            }
          };

          document.body.appendChild(script);
        };

        loadGoogleScript();

        return () => {
          const scriptElement = document.querySelector('script[src="https://accounts.google.com/gsi/client"]');
          if (scriptElement) {
            scriptElement.remove();
          }
        };
      }, [handleGoogleResponse]);

      const handleVerificationSubmit = async (e) => {
        e.preventDefault();
        if (isVerifying || isLoading) {
          console.log('Already processing a verification, please wait...');
          return;
        }
        await verifyEmailCode(email, verificationCode);
      };

  const verifyEmailToken = useCallback(async (token) => {
    setIsVerifying(true);
    setIsLoading(true);
    try {
      console.log('Verifying token:', token);
      const response = await fetch(`${process.env.REACT_APP_BACKEND_URL}/auth/email/verify_code/`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ token }),
      });

      const data = await response.json();
      console.log('Server response:', data);

      if (!response.ok) {
        throw new Error(data.error || `HTTP error! status: ${response.status}`);
      }

      if (data.token && data.token.access && data.token.refresh) {
        // Create a tokens object
        const tokens = {
          access: data.token.access,
          refresh: data.token.refresh,
        };
        await handleLoginSuccess(tokens, 'email', data.is_new_user);
      } else {
        throw new Error('Invalid response from server');
      }
    } catch (error) {
      console.error('Error verifying email:', error);
      setVerificationMessage(error.message || 'Lien expir� ou invalide. Veuillez r�essayer.');
    } finally {
      setIsLoading(false);
      setIsVerifying(false);
    }
  }, [handleLoginSuccess]);


  const checkEmailExists = async (email) => {
    try {
      const response = await fetch(`${process.env.REACT_APP_BACKEND_URL}/auth/check_email_exists/`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Accept': 'application/json',
        },
        body: JSON.stringify({ email }),
      });

      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }

      const data = await response.json();
      return data.exists;
    } catch (error) {
      console.error('Error checking email:', error);
      setErrorMessage('Erreur lors de la v�rification de l\'email. Veuillez r�essayer.');
      return false;
    }
  };

  const handleEmailSubmit = async (e) => {
    e.preventDefault();
    setIsLoading(true);
    setErrorMessage('');

    try {
      // First check if email exists
      const emailExists = await checkEmailExists(email);

      if (emailExists) {
        setShowEmailExistsAlert(true);
        return;
      }

      // If email doesn't exist, proceed with sending verification code
      const response = await fetch(`${process.env.REACT_APP_BACKEND_URL}/auth/email/request_code/`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ email }),
      });

      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }

      setVerificationMessage('Code de v�rification envoy�. V�rifiez votre boite mail.');
      setCountdown(180);
      setShowVerificationView(true);
      setIsEmailSubmitted(true);
      setIsValidInput(false);
      setHasEmailBeenSent(true);
      setVerificationCode('');
    } catch (error) {
      console.error('Error during email submission:', error);
      setErrorMessage('Une erreur est survenue. Veuillez r�essayer.');
    } finally {
      setIsLoading(false);
    }
  };

  const handleAppleResponse = useCallback(async (event) => {
    try {
      const response = await fetch(`${process.env.REACT_APP_BACKEND_URL}/auth/apple/callback/`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          id_token: event.authorization.id_token,
          user: event.user || {} // Apple only sends user info on first login
        }),
      });

      const data = await response.json();

      if (response.ok) {
        await handleLoginSuccess(data.token, 'apple', data.is_new_user);
      } else {
        throw new Error(data.error || 'Failed to authenticate with Apple');
      }
    } catch (error) {
      console.error('Error during Apple authentication:', error);
      setErrorMessage('Une erreur est survenue lors de l\'authentification Apple. Veuillez r�essayer.');
    } finally {
      setIsLoading(false);
    }
  }, [handleLoginSuccess]);


  const handleAppleLogin = useCallback(async (e) => {
    e.preventDefault();
    setIsLoading(true);
    setErrorMessage('');

    try {
      if (!APPLE_CLIENT_ID) {
        throw new Error('Apple Sign In is not configured');
      }

      if (!window.AppleID) {
        throw new Error('Apple Sign In is not initialized');
      }

      // Use consistent redirect URI
      const redirectURI = process.env.REACT_APP_APPLE_REDIRECT_URI;

      // Always reinitialize
      const config = {
        clientId: APPLE_CLIENT_ID,
        scope: 'name email',
        redirectURI: redirectURI,
        state: 'origin:web',
        usePopup: true
      };

      window.AppleID.auth.init(config);

      // Add timeout
      const signInPromise = window.AppleID.auth.signIn();
      const timeoutPromise = new Promise((_, reject) =>
        setTimeout(() => reject(new Error('Sign in timeout')), 30000)
      );

      const data = await Promise.race([signInPromise, timeoutPromise]);
      await handleAppleResponse(data);
    } catch (error) {
      console.error('Error during Apple Sign In:', error);
      if (error.message === 'Sign in timeout') {
        setErrorMessage('La connexion avec Apple prend trop de temps. Veuillez r�essayer.');
      } else {
        setErrorMessage('Une erreur est survenue avec Apple Sign In. Veuillez r�essayer.');
      }
    } finally {
      setIsLoading(false);
    }
  }, [handleAppleResponse]);

  useEffect(() => {
    let scriptLoadTimeout;

    const loadAppleScript = async () => {
      try {
        if (!APPLE_CLIENT_ID) {
          console.error('Apple Client ID is not configured');
          return;
        }

        if (document.getElementById('appleid-signin-script')) {
          return;
        }

        const script = document.createElement('script');
        script.src = 'https://appleid.cdn-apple.com/appleauth/static/jsapi/appleid/1/en_US/appleid.auth.js';
        script.id = 'appleid-signin-script';

        // Add timeout for script loading
        const scriptLoadPromise = new Promise((resolve, reject) => {
          script.onload = resolve;
          script.onerror = () => reject(new Error('Failed to load Apple Sign In script'));
          document.head.appendChild(script);
        });

        // 10-second timeout
        const timeoutPromise = new Promise((_, reject) => {
          scriptLoadTimeout = setTimeout(() => {
            reject(new Error('Failed to load Apple Sign In script: Timeout'));
          }, 10000);
        });

        await Promise.race([scriptLoadPromise, timeoutPromise]);
      } catch (error) {
        console.error('Error loading Apple Sign In:', error);
        setErrorMessage('Erreur lors du chargement de Apple Sign In. Veuillez rafra�chir la page.');
      }
    };

    loadAppleScript();

    return () => {
      if (scriptLoadTimeout) {
        clearTimeout(scriptLoadTimeout);
      }
      const script = document.getElementById('appleid-signin-script');
      if (script) {
        script.remove();
      }
    };
  }, []);

  const handleAlertConfirm = () => {
    setShowEmailExistsAlert(false);
    navigate('/first-login');
  };

  useEffect(() => {
    const params = new URLSearchParams(location.search);
    const token = params.get('token');

    if (token) {
      verifyEmailToken(token);
    }
  }, [location, verifyEmailToken]);

  useEffect(() => {
    let timer;
    if (countdown > 0) {
      timer = setInterval(() => {
        setCountdown((prevCountdown) => prevCountdown - 1);
      }, 1000);
    } else if (countdown === 0) {
      setVerificationMessage('');
    }

    return () => {
      if (timer) clearInterval(timer);
    };
  }, [countdown]);

  const isValidEmail = (email) => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(email); // This will return true or false explicitly
  };

  // And update your handleEmailChange to be more explicit
  const handleEmailChange = (e) => {
    const newEmail = e.target.value;
        setEmail(newEmail);
        setIsValidInput(isValidEmail(newEmail));
        setIsEmailSubmitted(false); // Reset submitted state when email changes
      };

      const handleVerificationCodeChange = (e) => {
        const newCode = e.target.value;
        setVerificationCode(newCode);
        setIsValidInput(newCode.length === 6);
      };

  
  const handleExistingAccount = () => {
        navigate('/login');
      };

  useEffect(() => {
    if (authError) {
      setVerificationMessage(authError);
    }
  }, [authError]);

  const getAlertMessage = () => {
    return 'Cette adresse email est déjà utilisée. Veuillez vous connecter avec votre compte existant.';
  };

  if (isVerifying) {
      return (
        <Container>
          <Message>Nous vérifions votre email... Veuillez patienter.</Message>
        </Container>
      );
    }

   return (
       <Wrapper>
         <ReturnButton onClick={handleBack}>
           <IoIosArrowBack />
         </ReturnButton>
         {hasEmailBeenSent && !showVerificationView && (
           <NextButton onClick={handleNext}>
             <IoIosArrowForward />
           </NextButton>
         )}
         <LogoImage src={Meo} alt="Compass Logo" isMobile={isMobile}/>
         <Container>
           <SubTitle>
             {showVerificationView ? 'Copie le code envoyé par email' : 'Email'}
           </SubTitle>
           <EmailLoginContainer>
          <Form onSubmit={showVerificationView ? handleVerificationSubmit : handleEmailSubmit}>
            <Input
              type={showVerificationView ? "text" : "email"}
              autoComplete={showVerificationView ? "off" : "email"}
              placeholder={showVerificationView ? "Entrez le code à 6 chiffres" : "Entrez votre email"}
              value={showVerificationView ? verificationCode : email}
              onChange={showVerificationView ? handleVerificationCodeChange : handleEmailChange}
              disabled={!showVerificationView && countdown > 0}
              maxLength={showVerificationView ? 6 : undefined}
              pattern={showVerificationView ? "[0-9]*" : undefined}
              inputMode={showVerificationView ? "numeric" : "email"}
              required
            />
            {errorMessage && (
              <Message style={{ color: 'red', marginTop: '8px' }}>
                {errorMessage}
              </Message>
            )}
            <SendButton
              type="submit"
              disabled={
                isLoading ||
                (!showVerificationView ?
                  (!isValidInput || countdown > 0 || isEmailSubmitted) :
                  verificationCode.length !== 6)
              }
            >
              {isLoading ? 'Chargement...' : (showVerificationView ? 'Vérifier' : 'Envoyer le code')}
            </SendButton>
          </Form>

          {countdown > 0 && (
            <CountdownText>
              Vous pourrez renvoyer un code dans: {Math.floor(countdown / 60)}:{(countdown % 60).toString().padStart(2, '0')}
            </CountdownText>
          )}
        </EmailLoginContainer>

           {!showVerificationView && (
             <>
               <Divider>
                 <DividerLine />
                 <DividerText>ou</DividerText>
                 <DividerLine />
               </Divider>
               <ButtonGoogle
                 id="google-login-button"
                 onClick={handleGoogleLogin}
                 disabled={isLoadingGoogle}
               >
                 <GoogleButtonContent>
                   <GoogleIcon src={GoogleLogo} alt="Google" />
                   <ButtonTextGoogle>
                     {isLoadingGoogle ? 'Chargement...' : 'Google'}
                   </ButtonTextGoogle>
                 </GoogleButtonContent>
               </ButtonGoogle>
                  <ButtonApple
                onClick={handleAppleLogin}
                disabled={isLoading}
              >
                <AppleButtonContent>
                  <AppleIcon src={AppleLogo} alt="Apple" />
                  <ButtonTextApple>
                    {isLoading ? 'Chargement...' : 'Apple'}
                  </ButtonTextApple>
                </AppleButtonContent>
              </ButtonApple>
             </>
           )}

           {showEmailExistsAlert && (
            <AlertOverlay>
              <AlertBox>
                <AlertMessage>
                  {getAlertMessage()}
                </AlertMessage>
                <AlertButton onClick={handleAlertConfirm}>
                  Ok
                </AlertButton>
              </AlertBox>
            </AlertOverlay>
          )}
         </Container>

         <TermsText>
           En vous connectant, vous acceptez nos{' '}
           <TermsLink onClick={handleViewCGU}>
             conditions générales d'utilisation
           </TermsLink>{' '}
           et notre{' '}
           <TermsLink onClick={handleViewPolitique}>
             politique de confidentialité
           </TermsLink>
         </TermsText>
         <BottomActionsContainer>
        <StyledText>Déjà inscrit ?</StyledText>
        <TextButton onClick={handleExistingAccount} isMobile={isMobile}>
          Se connecter
        </TextButton>
      </BottomActionsContainer>
       </Wrapper>
     );
   };

   export default FirstLoginAccount;
