// src/theme.js
const theme = {
  colors: {
    primary: '#FF2768',
    secondary: '#FF6B98',
    accent: '#FF8CAD',
    background: '#FFF8F9',
    background_fonce: '#FFEAEE',
    noir: '#333333',
    gris: '#6E6E6E',
    bleu: '#DCF3FF',
    vert: '#1EE018',
    bleu_fonce: '#38B6FF',
    gris_clair: '#BFBFBF',
    gris_clair_clair: '#E3E3E3',
    lightText: '#ffffff',
    lightGray: '#FFE4EC',
    midlightGray: '#FFEDF8',
    veryLightGray: '#FFF0F6',
    mediumGray: '#FF4F85',
  },
  fonts: {
    main: "'Roboto', sans-serif",
    heading: "'Inter', sans-serif",
  },
  fontSizes: {
    xsmall: '0.75rem',
    small: '0.875rem',
    medium: '1rem',
    large: '1.125rem',
    xlarge: '1.25rem',
    xxlarge: '1.5rem',
  },
  fontWeights: {
    regular: 400,
    medium: 500,
    semiBold: 600,
    bold: 700,
    bigBold: 800,
  },
  spacing: {
    xxsmall: '0rem',
    xsmall: '0.25rem',
    small: '0.5rem',
    medium: '1rem',
    large: '1.5rem',
    xlarge: '2rem',
    xxlarge: '2.5rem',
    xxxlarge: '3rem',
    big: '3.5rem',
  },
  breakpoints: {
    mobile: '767px',
  },
};

export default theme;
