import React, {useEffect, useState} from 'react';
import { useNavigate } from 'react-router-dom';
import styled, { keyframes } from 'styled-components';
import { PiRankingFill } from "react-icons/pi";
import { HiOutlineChat, HiChat } from "react-icons/hi";
import { useTutorial } from '../Tutorial/TutorialContext';
import theme from '../../theme';

const float = keyframes`
  0% { transform: translateY(0px) rotate(0deg); }
  25% { transform: translateY(-3px) rotate(1deg); }
  50% { transform: translateY(-5px) rotate(0deg); }
  75% { transform: translateY(-3px) rotate(-1deg); }
  100% { transform: translateY(0px) rotate(0deg); }
`;

const fadeOut = keyframes`
  from {
     opacity: 1;
    transform: scale(1);
  }
  to {
     opacity: 0;
    transform: scale(0.8);
  }
`;

const pulseAnimation = keyframes`
  0% { transform: scale(1); }
  50% { transform: scale(1.1); }
  100% { transform: scale(1); }
`;

const lightAnimation = keyframes`
  0% { opacity: 0.3; }
  50% { opacity: 0.7; }
  100% { opacity: 0.3; }
`;

const BaseButton = styled.button`
  position: fixed;
  bottom: 0;
  height: var(--bottom-menu-height, 60px);
  display: flex;
  justify-content: center;
  align-items: center;
  background: none;
  border: none;
  cursor: pointer;
  z-index: 1601;
  animation: ${pulseAnimation} 2s infinite;
  opacity: ${props => props.$shouldShow ? '1' : '0'};
  visibility: ${props => props.$shouldShow ? 'visible' : 'hidden'};
  transition: opacity 0.5s ease-out, visibility 0.5s ease-out;
  
  &.fade-out {
    animation: ${fadeOut} 0.5s ease-out forwards;
  }
`;

const RankingButton = styled(BaseButton)`
  right: 0;
  width: 33.33%;
  color: white;
`;

const ChatButton = styled(BaseButton)`
  left: 33.33%;
  width: 33.33%;
  color: ${theme.colors.primary};
`;

const LightEffect = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 50px;
  height: 50px;
  background: radial-gradient(
    circle at center,
    ${props => props.$isChat ? props.theme.colors.primary : 'white'}40 0%,
    transparent 70%
  );
  animation: ${lightAnimation} 2s infinite;
  pointer-events: none;
  z-index: -1;
`;

const TutorialBottomMenu = () => {
  const navigate = useNavigate();
  const [shouldRender, setShouldRender] = useState(false);
  const [isAnimatingOut, setIsAnimatingOut] = useState(false);
  const {
    currentStepData,
    nextStep
  } = useTutorial();

  useEffect(() => {
    let timeoutId;
    
    if (currentStepData?.id === 'quiz-complete' || currentStepData?.id === 'highlight-chat') {
      timeoutId = setTimeout(() => {
        setShouldRender(true);
        setIsAnimatingOut(false);
      }, 900);
    } else {
      setShouldRender(false);
    }
    
    return () => {
      if (timeoutId) {
        clearTimeout(timeoutId);
      }
    };
  }, [currentStepData?.id]);

  const handleButtonClick = () => {
    setIsAnimatingOut(true);
    
    setTimeout(() => {
      nextStep();
      if (currentStepData?.id === 'quiz-complete') {
        navigate('/tutorial/classement');
      } else if (currentStepData?.id === 'highlight-chat') {
        navigate('/tutorial/chat');
      }
      setShouldRender(false);
    }, 500);
  };

  if (!currentStepData?.id) return null;

  if (currentStepData.id === 'highlight-chat') {
    return (
      <ChatButton
        onClick={handleButtonClick}
        $shouldShow={shouldRender}
        className={isAnimatingOut ? 'fade-out' : ''}
        disabled={isAnimatingOut}
      >
        <LightEffect $isChat={true} />
        <HiChat size={32} />
      </ChatButton>
    );
  }

  if (currentStepData.id === 'quiz-complete') {
    return (
      <RankingButton
        onClick={handleButtonClick}
        $shouldShow={shouldRender}
        className={isAnimatingOut ? 'fade-out' : ''}
        disabled={isAnimatingOut}
      >
        <LightEffect $isChat={false} />
        <PiRankingFill size={27} style={{ opacity: 0.9 }} />
      </RankingButton>
    );
  }

  return null;
};

export default TutorialBottomMenu;