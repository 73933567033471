// components/BottomMenu/index.js
import React from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { BottomMenuContainer, MenuButton, MenuText } from './styles';
import { TbSwipe } from "react-icons/tb";
import { HiOutlineChat, HiChat } from "react-icons/hi";
import { PiRanking, PiRankingFill } from "react-icons/pi";
import { useTheme } from 'styled-components';
import useDeviceType from '../useDeviceType';

const BottomMenu = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const theme = useTheme();
  const { isMobile } = useDeviceType();

  const getIconColor = (path) => {
    return location.pathname === path ? theme.colors.primary : theme.colors.mediumGray;
  };

  const handleNavigation = (path) => {
    navigate(path);
  };

  const isActive = (path) => location.pathname === path;

  if (!isMobile) return null;
  
  return (
    <BottomMenuContainer>
      <MenuButton
        id="quiz-button"
        onClick={() => handleNavigation('/quiz')}
        active={isActive('/quiz')}
      >
        <div>
          <TbSwipe size={27} color={getIconColor('/quiz')} />
          <MenuText active={isActive('/quiz')}>Swipe</MenuText>
        </div>
      </MenuButton>
      <MenuButton
        id="chat-button"
        onClick={() => handleNavigation('/chat')}
        active={isActive('/chat')}
        center
      >
        <div>
          {isActive('/chat') ? (
            <HiChat size={27} color={getIconColor('/chat')} />
          ) : (
            <HiOutlineChat size={27} color={getIconColor('/chat')} />
          )}
          <MenuText active={isActive('/chat')}>Chat</MenuText>
        </div>
      </MenuButton>
      <MenuButton
        id="ranking-button"
        onClick={() => handleNavigation('/classement')}
        active={isActive('/classement')}
      >
        <div>
          {isActive('/classement') ? (
            <PiRankingFill size={27} color={getIconColor('/classement')} />
          ) : (
            <PiRanking size={27} color={getIconColor('/classement')} />
          )}
          <MenuText active={isActive('/classement')}>Résultats</MenuText>
        </div>
      </MenuButton>
    </BottomMenuContainer>
  );
};

export default BottomMenu;