
import React, { useState, useEffect, useCallback } from 'react';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import apiCall from '../../utils/api';
import useDeviceType from '../useDeviceType';
import { useQuiz } from '../../contexts/QuizContext';

import { SubmitButton,
  RegionChip,
  DropZone,
  TierLabel,
  UnrankedSection,
  TierRow,
  TiersContainer,
  Subtitle,
  Title,
  ErrorMessage,
  Container,
 } from './styles';

const TIERS = [
  { id: 'GREEN', label: 'Très intéressé', points: 3 },
  { id: 'YELLOW', label: 'Intéressé', points: 1 },
  { id: 'ORANGE', label: 'Peu intéressé', points: -1 },
  { id: 'RED', label: 'Pas intéressé', points: -3 },
];

const RegionTierList = ({ onComplete }) => {
  const { isMobile } = useDeviceType();
  const { regions, setRegions, regionsLoading, setRegionsLoading } = useQuiz();

  const [tiers, setTiers] = useState({
    GREEN: [],
    YELLOW: [],
    ORANGE: [],
    RED: [],
    UNRANKED: []
  });
  const [regionsMap, setRegionsMap] = useState({});
  const [errorMessage, setErrorMessage] = useState('');
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [isInitialized, setIsInitialized] = useState(false);

  const initializeRegionsData = useCallback((availableRegions) => {
    if (!availableRegions || availableRegions.length === 0) return;
    
    const mapping = {};
    const numericIds = availableRegions.map((region, index) => {
      const id = `region-${region.replace(/\s+/g, '-').toLowerCase()}`;
      mapping[id] = region;
      return id;
    });

    setRegionsMap(mapping);
    
    if (!isInitialized) {
      setTiers(prev => ({
        ...prev,
        GREEN: [],
        YELLOW: [],
        ORANGE: [],
        RED: [],
        UNRANKED: numericIds
      }));
      setIsInitialized(true);
    }
  }, [isInitialized]);

  useEffect(() => {
    let isMounted = true;

    const fetchRegions = async () => {
      try {
        const response = await apiCall(
          `${process.env.REACT_APP_BACKEND_URL}/quiz/regions/`,
          'POST',
          { filtered_formations: [] }
        );

        if (!isMounted) return;

        if (response.available_regions) {
          setRegions(response.available_regions);
          initializeRegionsData(response.available_regions);
        }
      } catch (error) {
        console.error('Error fetching regions:', error);
        if (isMounted) {
          setErrorMessage("Impossible de charger les régions. Veuillez réessayer.");
        }
      } finally {
        if (isMounted) {
          setRegionsLoading(false);
        }
      }
    };

    if (regions.length === 0) {
      fetchRegions();
    } else {
      initializeRegionsData(regions);
      setRegionsLoading(false);
    }

    return () => {
      isMounted = false;
    };
  }, [regions, setRegions, setRegionsLoading, initializeRegionsData]);

  const onDragEnd = useCallback((result) => {
    const { source, destination } = result;

    // If there's no destination or the item is dropped in the same spot
    if (!destination || (
      source.droppableId === destination.droppableId &&
      source.index === destination.index
    )) {
      return;
    }

    setTiers(prev => {
      const newTiers = { ...prev };
      const sourceList = [...newTiers[source.droppableId]];
      const destList = [...newTiers[destination.droppableId]];
      const [removed] = sourceList.splice(source.index, 1);
      destList.splice(destination.index, 0, removed);
      
      return {
        ...newTiers,
        [source.droppableId]: sourceList,
        [destination.droppableId]: destList
      };
    });
  }, []);

  const renderDraggables = (tierId, items) => {
    return items.map((id, index) => (
      <Draggable key={id} draggableId={id} index={index}>
        {(provided, snapshot) => (
          <RegionChip
            ref={provided.innerRef}
            {...provided.draggableProps}
            {...provided.dragHandleProps}
            $isDragging={snapshot.isDragging}
          >
            {regionsMap[id]}
          </RegionChip>
        )}
      </Draggable>
    ));
  };

  const handleSubmit = async () => {
    try {
      setIsSubmitting(true);
      setErrorMessage('');

      const regionPoints = {};
      TIERS.forEach(tier => {
        tiers[tier.id].forEach(numericId => {
          regionPoints[regionsMap[numericId]] = tier.points;
        });
      });

      tiers.UNRANKED.forEach(numericId => {
        regionPoints[regionsMap[numericId]] = -3;
      });

      await apiCall(
        `${process.env.REACT_APP_BACKEND_URL}/quiz/regions/`,
        'POST',
        { region_points: regionPoints }
      );

      if (onComplete) {
        onComplete();
      }
    } catch (error) {
      console.error('Error submitting regions:', error);
      setErrorMessage("Impossible d'enregistrer vos préférences. Veuillez réessayer.");
    } finally {
      setIsSubmitting(false);
    }
  };

  if (regionsLoading) {
    return (
      <Container isMobile={isMobile}>
        <Title>Chargement des régions...</Title>
      </Container>
    );
  }

  return (
    <Container isMobile={isMobile}>
      <Title>Classez les régions par ordre de préférence</Title>
      <Subtitle>
        Faites glisser les régions dans les différentes catégories selon vos préférences
      </Subtitle>

      {errorMessage && <ErrorMessage>{errorMessage}</ErrorMessage>}

      <DragDropContext onDragEnd={onDragEnd}>
        <TiersContainer>
          {TIERS.map(tier => (
            <TierRow key={tier.id}>
              <TierLabel tier={tier.id.toLowerCase()}>
                {tier.label}
              </TierLabel>
              <Droppable droppableId={tier.id} direction="horizontal">
                {(provided, snapshot) => (
                  <DropZone
                    ref={provided.innerRef}
                    {...provided.droppableProps}
                    tier={tier.id.toLowerCase()}
                    $isDraggingOver={snapshot.isDraggingOver}
                  >
                    {renderDraggables(tier.id, tiers[tier.id])}
                    {provided.placeholder}
                  </DropZone>
                )}
              </Droppable>
            </TierRow>
          ))}

          <UnrankedSection>
            <TierLabel>Régions à classer</TierLabel>
            <Droppable droppableId="UNRANKED" direction="horizontal">
              {(provided, snapshot) => (
                <DropZone
                  ref={provided.innerRef}
                  {...provided.droppableProps}
                  tier="unranked"
                  $isDraggingOver={snapshot.isDraggingOver}
                >
                  {renderDraggables('UNRANKED', tiers.UNRANKED)}
                  {provided.placeholder}
                </DropZone>
              )}
            </Droppable>
          </UnrankedSection>
        </TiersContainer>
      </DragDropContext>

      <SubmitButton
        onClick={handleSubmit}
        disabled={isSubmitting}
      >
        {isSubmitting ? 'Chargement...' : 'Valider mes préférences'}
      </SubmitButton>
    </Container>
  );
};
export default RegionTierList;