import React, {useEffect} from 'react';
import { Routes, Route, useLocation } from 'react-router-dom';
import { useTutorial } from './TutorialContext';
import TutorialBottomMenu from '../BottomMenu/TutorialBottomMenu';
import TutorialChatDiscussion from '../ChatDiscussion/TutorialChatDiscussion';
import TutorialQuiz from '../Quiz/TutorialQuiz';
import TutorialClassement from '../Classement/TutorialClassement';

const TutorialWrapper = () => {
  const { isTutorialActive, startTutorial, currentStepData } = useTutorial();
  const location = useLocation();

  useEffect(() => {
    if (location.pathname.startsWith('/tutorial') && !isTutorialActive) {
      console.log('Activating tutorial from wrapper');
      startTutorial();
    }
  }, [location.pathname, isTutorialActive, startTutorial]);

  // Modified: Don't return null immediately
  if (!isTutorialActive && !location.pathname.startsWith('/tutorial')) {
    return null;
  }

  return (
    <>
      <Routes>
        <Route path="/quiz" element={<TutorialQuiz />} />
        <Route 
          path="/chat" 
          element={
            <TutorialChatDiscussion 
              key={currentStepData?.id} // Add this line to preserve state
              forceHistoryOpen={currentStepData?.id === 'view-history'}
            />
          } 
        />
        <Route path="/classement" element={<TutorialClassement />} />
      </Routes>
      <TutorialBottomMenu />
    </>
  );
};

export default TutorialWrapper;