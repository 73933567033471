import styled from 'styled-components';

export const FeatureContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${({ theme }) => theme.spacing.medium};
  width: 100%;
`;

export const ErrorMessage = styled.div`
  padding: ${({ theme }) => theme.spacing.medium};
  background-color: rgba(220, 53, 69, 0.1);
  color: #dc3545;
  border-radius: 8px;
  text-align: center;
  font-size: ${({ theme }) => theme.fontSizes.small};
`;

export const FeatureList = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${({ theme }) => theme.spacing.medium};
`;

export const FeatureCard = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  gap: ${({ theme }) => theme.spacing.medium};
  padding: ${({ theme }) => theme.spacing.medium};
  background-color: ${({ theme }) => theme.colors.background};
  border: 1px solid ${({ theme }) => theme.colors.lightGray};
  border-radius: 8px;
  transition: box-shadow 0.2s ease;

  &:hover {
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
  }
`;

export const FeatureTitle = styled.h4`
  font-size: ${({ theme }) => theme.fontSizes.small};
  font-weight: ${({ theme }) => theme.fontWeights.semiBold};
  color: ${({ theme }) => theme.colors.gris};
  margin-bottom: ${({ theme }) => theme.spacing.small};
`;

export const FeatureDescription = styled.p`
  font-size: ${({ theme }) => theme.fontSizes.small};
  color: ${({ theme }) => theme.colors.gris};
  line-height: 1.5;
`;

export const VoteButton = styled.button`
  display: flex;
  align-items: center;
  gap: ${({ theme }) => theme.spacing.small};
  padding: ${({ theme }) => `${theme.spacing.small} ${theme.spacing.medium}`};
  border: none;
  border-radius: 6px;
  cursor: ${({ disabled }) => (disabled ? 'not-allowed' : 'pointer')};
  transition: all 0.2s ease;
  background-color: ${({ theme, hasVoted, isImplemented }) =>
    isImplemented
      ? theme.colors.secondary
      : hasVoted
      ? `${theme.colors.primary}1a`
      : theme.colors.lightGray};

  &:hover:not(:disabled) {
    background-color: ${({ theme, isImplemented }) =>
      isImplemented
        ? theme.colors.secondary
        : `${theme.colors.primary}33`};
  }
`;

export const VoteCount = styled.span`
  font-size: ${({ theme }) => theme.fontSizes.small};
  font-weight: ${({ theme }) => theme.fontWeights.medium};
  color: ${({ theme }) => theme.colors.gris};
`;

export const LoadingContainer = styled.div`
  text-align: center;
  padding: ${({ theme }) => theme.spacing.medium};
  color: ${({ theme }) => theme.colors.gris};
  font-size: ${({ theme }) => theme.fontSizes.small};
`;
