import React, { useEffect, useState } from 'react';
import {
  ProgressBarContainer,
  ProgressBarFill,
  StepIndicator,
  StepText
} from './styles';

const ProgressBar = ({ currentStep, totalSteps = 4 }) => {
  const [width, setWidth] = useState(0);

  useEffect(() => {
    // Calculate progress percentage
    const progress = (currentStep / totalSteps) * 100;
    
    // Animate the progress bar
    setTimeout(() => {
      setWidth(progress);
    }, 100);
  }, [currentStep, totalSteps]);

  return (
    <ProgressBarContainer>
      <ProgressBarFill width={width} />
      <StepIndicator>
        <StepText>Étape {currentStep}/{totalSteps}</StepText>
      </StepIndicator>
    </ProgressBarContainer>
  );
};

export default ProgressBar;