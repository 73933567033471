import styled from 'styled-components';

export const WelcomeMenuWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  background-color: ${({ theme }) => theme.colors.background};
  width: 100%;
  height: 100%;
  max-width: ${props => props.$isSideMenuOpen && !props.isMobile ? 'calc(100% - 2rem)' : '100%'};
  margin: 0 auto;
  overflow-y: auto;
  position: relative;
`;

export const ContentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  gap: ${props => props.isMobile ? '1rem' : '1.5rem'};
`;

export const AppLogo = styled.img`
  width: 20%;
  height: 20%;
  margin-bottom: ${({ theme }) => theme.spacing.small};
  object-fit: contain;
`;

export const WelcomeMessage = styled.h2`
  font-family: ${({ theme }) => theme.fonts.heading};
  font-size: ${props => props.isMobile ? '1.25rem' : '1.5rem'};
  font-weight: ${({ theme }) => theme.fontWeights.semiBold};
  margin-bottom: ${({ theme }) => theme.spacing.small};
  text-align: center;
  color: ${({ theme }) => theme.colors.text};
`;

export const WelcomeDescription = styled.p`
  font-size: ${props => props.isMobile ? '0.875rem' : '1rem'};
  margin-bottom: ${({ theme }) => theme.spacing.small};
  text-align: center;
  line-height: 1.5;
  color: ${({ theme }) => theme.colors.text};
  max-width: 90%;
`;

export const ExamplesContainer = styled.div`
  width: 100%;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: ${props => props.isMobile ? '0.5rem' : '1rem'};
  padding: ${props => props.isMobile ? '0.5rem' : '1rem'};
  margin-top: ${({ theme }) => theme.spacing.small};

  @media (max-width: 355px) {
    grid-template-columns: 1fr;
    gap: 0.5rem;
    padding: 0.25rem;
  }
`;

export const ExampleBox = styled.div`
  padding: ${props => props.isMobile ? '0.75rem' : '1rem'};
  border-radius: 8px;
  cursor: pointer;
  transition: all 0.2s ease;
  color: ${({ theme }) => theme.colors.text};
  background-color: ${({ theme }) => theme.colors.white};
  display: flex;
  align-items: center;
  min-height: ${props => props.isMobile ? '50px' : '60px'};
  font-size: ${props => props.isMobile ? '0.875rem' : '1rem'};
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  gap: 0.75rem;

  &:hover {
    background-color: ${({ theme }) => theme.colors.lightGray};
    transform: translateY(-2px);
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.15);
  }

  &:active {
    transform: translateY(0);
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  }

  @media (max-width: 380px) {
    padding: 0.5rem;
    min-height: 45px;
    font-size: 0.813rem;
  }
`;

export const IconWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  color: ${props => props.color};
  font-size: 1.5rem;

  @media (max-width: 380px) {
    font-size: 1.25rem;
  }
`;

export const ExampleContent = styled.div`
  flex: 1;
`;