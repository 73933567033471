import React, { useEffect, useRef, useCallback, useState } from 'react';
import { useAuth } from '../../contexts/AuthContext';
import { useSpring, animated, config, to } from '@react-spring/web';
import { useNavigate } from 'react-router-dom';
import Meo from '../../assets/boussole.png';
import {
  QuizContainer,
  BackgroundBall,
  CardWrapper,
  QuestionCard,
  QuestionText,
  StyledIcon,
  AnswerOptionsContainer,
  AnswerButton,
  QuizMessage,
  StyledThumbsUp,
  StyledThumbsDown,
  ContinueModal,
  ModalContent,

  ModalTitle,
  ModalText,
  ModalButtonsContainer,
  ModalButton,
  QuizTypeIndicator,
  ProgressContainer,
  QuizNumber,
  ProgressBar,
  Progress,
  ResultButton,
  StyledRanking,
  QuizCompletionMessage,
  BrandContainer,
  BrandImage,
  BrandSubtitle,
  ArrowUp,
  ArrowDown,
  ArrowLeft,
  ArrowRight
} from './styles';

import {
  MdOutlineSwipeUp,
  MdOutlineSwipeDown,
  MdOutlineSwipeLeft,
  MdOutlineSwipeRight
} from 'react-icons/md';
import apiCall from '../../utils/api';
import useDeviceType from '../useDeviceType';
import { useQuiz } from '../../contexts/QuizContext';
import { SettingsButton, SettingsIcon } from '../Classement/styles';
import RegionTierList from '../RegionTierList';
import QuestionPopup from '../QuestionPopup';
import questionIcon from '../../assets/doubt.png';
import heartIcon from '../../assets/jadore.png';

const SWIPE_THRESHOLD = 80;
const ANSWER_MAPPING = {
  1: 'Non',
  2: 'Je ne sais pas',
  3: 'Oui',
  4: "J'adore"
};

const Quiz = () => {
  const { isAuthenticated } = useAuth();
  const navigate = useNavigate();
  const { isMobile } = useDeviceType();
  const [showQuiz1CompletePopup, setShowQuiz1CompletePopup] = useState(false);
  const [showQuiz2ProgressPopup, setShowQuiz2ProgressPopup] = useState(false);
  const [showDirectionArrows, setShowDirectionArrows] = useState(true);
  const {
    currentQuestion,
    setCurrentQuestion,
    quizProgress,
    setQuizProgress,
    setQuizResult,
    isQuizInitialized,
    setIsQuizInitialized,
    setQuizId,
    showContinueModal,
    setShowContinueModal,
    isQuizPaused,
    resumeQuiz,
    quizCompleted,
    setQuizCompleted,
    regionsCompleted,
    setRegionsCompleted,
  } = useQuiz();

  const isQuiz1 = currentQuestion?.type === 'quiz1';
  const progress = isQuiz1
      ? (quizProgress.quiz1_progress / quizProgress.quiz1_total) * 100
      : (quizProgress.quiz2_progress / quizProgress.quiz2_total) * 100;

   const [{ x, y, rotate }, api] = useSpring(() => ({
     x: 0,
     y: 0,
     rotate: 0,
     config: { tension: 300, friction: 20 }
   }));

  const cardRef = useRef(null);
  const touchStartRef = useRef({ x: 0, y: 0 });

  const fetchNextQuestion = useCallback(async () => {
      try {
        const response = await apiCall(`${process.env.REACT_APP_BACKEND_URL}/quiz/question/`, 'GET');

        if (response.message === 'No more questions available') {
          setQuizCompleted(true);
          return;
        }

        setCurrentQuestion({
          id: response.question_id,
          text: response.question_text,
          type: response.question_type
        });
        setQuizProgress(response.progress);
      } catch (error) {
        console.error('Error fetching question:', error);
      }
    }, [setCurrentQuestion, setQuizCompleted, setQuizProgress]);

  const startQuiz = useCallback(async () => {
      try {
        const response = await apiCall(`${process.env.REACT_APP_BACKEND_URL}/quiz/start/`, 'POST');

        setQuizId(response.quiz_id);
        setQuizProgress(response.progress);
        await fetchNextQuestion();
        setIsQuizInitialized(true);
      } catch (error) {
        console.error('Error starting quiz:', error);
      }
    }, [setQuizId, setIsQuizInitialized, setQuizProgress, fetchNextQuestion]);

  console.info('Before Starting the quiz isAuthenticated : ', isAuthenticated);
  console.info('Before Starting the quiz !isQuizInitialized : ', !isQuizInitialized);
  useEffect(() => {
    if (isAuthenticated && !isQuizInitialized) {
    console.log('Starting the quiz');
      startQuiz();
    }
  }, [isAuthenticated, isQuizInitialized, startQuiz]);

  const handleContinueQuiz = async () => {
    // First clear the modal
    setShowContinueModal(false);
    // Resume the quiz
    resumeQuiz();
    // Fetch next question explicitly
    await fetchNextQuestion();
    // Reset card position
    api.start({ x: 0, y: 0, immediate: true });
  };

  const handleRegionComplete = () => {
      setRegionsCompleted(true);
    };

    const handleSeeResults = () => {
      navigate('/classement');
    };

  const handleSettingsClick = () => {
    navigate('/settings', { state: { from: '/quiz' } });
  };

  const handleAnswer = async (answer, direction) => {
    if (!currentQuestion || isQuizPaused) return;

    try {
      const response = await apiCall(`${process.env.REACT_APP_BACKEND_URL}/quiz/answer/`, 'POST', {
        question_id: currentQuestion.id,
        question_type: currentQuestion.type,
        answer: answer
      });

      // Animate the card
      animateCard(direction);

      // Wait for animation
      await new Promise(resolve => setTimeout(resolve, 300));

      // Update progress
      setQuizProgress(response.progress);

      // Check if quiz1 is completed and quiz2 hasn't started
      if (response.progress.quiz1_progress === response.progress.quiz1_total &&
          response.progress.quiz2_progress === 0) {
        setShowQuiz1CompletePopup(true);
      }

      // Check if quiz2 has reached 10 questions
      if (response.progress.quiz2_progress === 10) {
        setShowQuiz2ProgressPopup(true);
      }

      // Check if we have rankings available
      if (response.rankings) {
        setQuizResult(response.rankings);
      }

      if (response.progress.quiz_completed) {
        setQuizCompleted(true);
        navigate('/classement');
      } else {
        // Store the current question ID before fetching next question
        const currentQuestionId = currentQuestion.id;

        // Fetch next question
        await fetchNextQuestion();

        // Only hide arrows after answering question 2
        if (currentQuestionId === 6) {
          setShowDirectionArrows(false);
        }

        // Reset card position AND rotation
        api.start({
          x: 0,
          y: 0,
          rotate: 0,
          immediate: true
        });
      }
    } catch (error) {
      console.error('Error submitting answer:', error);
      api.start({
        x: 0,
        y: 0,
        rotate: 0,
        config: config.wobbly
      });
    }
  };

  const handleCloseQuiz1Popup = () => {
    setShowQuiz1CompletePopup(false);
    // Continue with quiz2 after closing the popup
    fetchNextQuestion();
  };

  const handleCloseQuiz2Popup = () => {
    setShowQuiz2ProgressPopup(false);
    // Continue with quiz after showing 10 questions message
    fetchNextQuestion();
  };

// Update the animateCard function
const animateCard = (direction) => {
  const distance = window.innerWidth + 200;
  const verticalDistance = window.innerHeight + 200;

  switch (direction) {
    case 'up':
      api.start({
        y: -verticalDistance,
        rotate: 0,
        config: { tension: 200, friction: 20 }
      });
      break;
    case 'down':
      api.start({
        y: verticalDistance,
        rotate: 0,
        config: { tension: 200, friction: 20 }
      });
      break;
    case 'left':
      api.start({
        x: -distance,
        rotate: -45,
        config: { tension: 200, friction: 20 }
      });
      break;
    case 'right':
      api.start({
        x: distance,
        rotate: 45,
        config: { tension: 200, friction: 20 }
      });
      break;
    default:
      api.start({
        x: 0,
        y: 0,
        rotate: 0,
        config: { tension: 200, friction: 20 }
      });
  }
};

  const handleTouchStart = (e) => {
    const touch = e.touches[0];
    touchStartRef.current = { x: touch.clientX, y: touch.clientY };
  };


const handleTouchMove = (e) => {
  if (!cardRef.current) return;

  const touch = e.touches[0];
  const deltaX = touch.clientX - touchStartRef.current.x;
  const deltaY = touch.clientY - touchStartRef.current.y;

  // Add rotation based on horizontal movement
  // Adjust the division factor (15) to control rotation sensitivity
  const rotation = deltaX / 15;

  api.start({
    x: deltaX,
    y: deltaY,
    rotate: rotation,
    immediate: true
  });
};

const handleTouchEnd = (e) => {
  const touch = e.changedTouches[0];
  const deltaX = touch.clientX - touchStartRef.current.x;
  const deltaY = touch.clientY - touchStartRef.current.y;

  if (Math.abs(deltaX) > SWIPE_THRESHOLD || Math.abs(deltaY) > SWIPE_THRESHOLD) {
    if (Math.abs(deltaX) > Math.abs(deltaY)) {
      // Horizontal swipe
      if (deltaX > 0) {
        // Add rotation to the exit animation
        api.start({
          x: window.innerWidth + 200,
          y: 0,
          rotate: 45, // Rotate right on right swipe
          config: { tension: 200, friction: 20 }
        });
        handleAnswer(1, 'right');
      } else {
        api.start({
          x: -window.innerWidth - 200,
          y: 0,
          rotate: -45, // Rotate left on left swipe
          config: { tension: 200, friction: 20 }
        });
        handleAnswer(2, 'left');
      }
    } else {
      // Vertical swipe
      if (deltaY > 0) {
        api.start({
          x: 0,
          y: window.innerHeight + 200,
          rotate: 0, // Optional: add some rotation for vertical swipes
          config: { tension: 200, friction: 20 }
        });
        handleAnswer(3, 'down');
      } else {
        api.start({
          x: 0,
          y: -window.innerHeight - 200,
          rotate: 0,
          config: { tension: 200, friction: 20 }
        });
        handleAnswer(4, 'up');
      }
    }
  } else {
    // Return to center with a springy effect
    api.start({
      x: 0,
      y: 0,
      rotate: 0,
      config: { tension: 200, friction: 20 }
    });
  }
};

const DirectionArrows = ({ isVisible, questionId }) => {
  if (!isVisible || quizProgress.quiz1_progress !== 0) return null;

  return (
    <>
      {/* Up Arrow - Love icon */}
      <ArrowUp>
        <div className="flex flex-col items-center">
          <StyledIcon src={heartIcon} alt="Heart" fill="#FF2768" className="mb-2" />
          <MdOutlineSwipeUp size={32} />
        </div>
      </ArrowUp>

      {/* Down Arrow - Question icon */}
      <ArrowDown>
        <div className="flex flex-col items-center">
          <MdOutlineSwipeDown size={32} />
          <StyledIcon src={questionIcon} alt="Question" fill="yellow" className="mt-2" />
        </div>
      </ArrowDown>

      {/* Left Arrow - Thumbs down icon */}
      <ArrowLeft>
        <div className="flex items-center">
          <StyledThumbsDown fill="red" className="mr-2" />
          <MdOutlineSwipeLeft size={32} />
        </div>
      </ArrowLeft>

      {/* Right Arrow - Thumbs up icon */}
      <ArrowRight>
        <div className="flex items-center">
          <MdOutlineSwipeRight size={32} />
          <StyledThumbsUp fill="#0BDB22" className="ml-2" />
        </div>
      </ArrowRight>
    </>
  );
};

  if (!isAuthenticated) {
    return <QuizMessage>Connecte-toi pour accéder au quiz !</QuizMessage>;
  }

  if (!currentQuestion && !quizCompleted) {
    console.info("!currentQuestion", !currentQuestion);
    return <QuizMessage>Chargement de la question...</QuizMessage>;
  }

   if (quizCompleted) {
       // If quiz is completed but regions aren't ranked yet
       if (!regionsCompleted) {
         return (
           <QuizContainer>
                   <BrandContainer>
          <BrandImage src={Meo} alt="Meoria Logo" />
          <BrandSubtitle>
            Swipe pour affiner tes résultats !
          </BrandSubtitle>
        </BrandContainer>
             <RegionTierList onComplete={handleRegionComplete} />
           </QuizContainer>
         );
       }

       // If both quiz and regions are completed, show final completion message
       return (
         <QuizContainer>
           <QuizCompletionMessage>
             Bravo ! Tu as plié le quiz... Fonce voir tes résultats!
           </QuizCompletionMessage>
           <ResultButton onClick={handleSeeResults}>
             <StyledRanking />
             Classement
           </ResultButton>
         </QuizContainer>
       );
     }


  return (
      <>
        <QuizContainer isMobile={isMobile}>
          <SettingsButton onClick={handleSettingsClick}>
            <SettingsIcon />
          </SettingsButton>

          <BrandContainer>
          <BrandImage src={Meo} alt="Meoria Logo" />
          <BrandSubtitle>
          Swipe pour affiner tes r�sultats !
          </BrandSubtitle>
        </BrandContainer>
          <BackgroundBall isMobile={isMobile}/>
          <QuizTypeIndicator>
                <ProgressContainer>
                  <QuizNumber>{isQuiz1 ? '1' : '2'}</QuizNumber>
                  <ProgressBar>
                    <Progress
                      $isQuiz1={isQuiz1}
                      $progress={progress}
                    />
                  </ProgressBar>
                </ProgressContainer>
              </QuizTypeIndicator>
          <CardWrapper isMobile={isMobile}>
        <animated.div
          ref={cardRef}
          style={{
            touchAction: 'none',
            transform: to(
              [x, y, rotate],
              (x, y, r) => `translate3d(${x}px, ${y}px, 0) rotate(${r}deg)`
            )
          }}
          onTouchStart={handleTouchStart}
          onTouchMove={handleTouchMove}
          onTouchEnd={handleTouchEnd}
        >
          <QuestionCard isMobile={isMobile}>
          <DirectionArrows
            isVisible={showDirectionArrows}
            questionId={currentQuestion.id}
          />
          <QuestionText>{currentQuestion.text}</QuestionText>
        </QuestionCard>
        </animated.div>
          </CardWrapper>
          <AnswerOptionsContainer isMobile={isMobile}>
            <AnswerButton onClick={() => handleAnswer(2, 'down')} aria-label={ANSWER_MAPPING[2]}>
              <StyledIcon src={questionIcon} alt="Question" fill="yellow"/>
            </AnswerButton>
            <AnswerButton onClick={() => handleAnswer(1, 'left')} aria-label={ANSWER_MAPPING[1]}>
              <StyledThumbsDown fill="red"/>
            </AnswerButton>
            <AnswerButton onClick={() => handleAnswer(3, 'right')} aria-label={ANSWER_MAPPING[3]}>
              <StyledThumbsUp fill="#0BDB22" />
            </AnswerButton>
            <AnswerButton onClick={() => handleAnswer(4, 'up')} aria-label={ANSWER_MAPPING[4]}>
              <StyledIcon src={heartIcon} alt="Heart" fill="#FF2768" />
            </AnswerButton>
          </AnswerOptionsContainer>
        </QuizContainer>

        {showContinueModal && (
          <ContinueModal>
            <ModalContent>
              <ModalTitle>Tes premiers résultats sont disponibles !</ModalTitle>
              <ModalText>
                Tu peux continuer à répondre pour affiner les prédictions de Meoria.
              </ModalText>
              <ModalButtonsContainer>
                <ModalButton className="continue" onClick={handleContinueQuiz}>
                  Continuer
                </ModalButton>
                <ModalButton className="results" onClick={handleSeeResults}>
                  Voir résultats
                </ModalButton>
              </ModalButtonsContainer>
            </ModalContent>
          </ContinueModal>
        )}
        <QuestionPopup
        isOpen={showQuiz1CompletePopup}
        onClose={handleCloseQuiz1Popup}
      />
      {/* New popup for quiz2 progress */}
      <QuestionPopup
        isOpen={showQuiz2ProgressPopup}
        onClose={handleCloseQuiz2Popup}
      />
      </>
    );
  };

export default Quiz;
