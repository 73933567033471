
import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useTutorial } from '../Tutorial/TutorialContext';  // Add this import
import compassImage from '../../assets/meo.png';
import { IoIosArrowBack } from "react-icons/io";
import {
  Container,
  ContentWrapper,
  LogoImage,
  PrimaryButton,
  ChatBubbleContainer,
  ChatText
} from './styles';
import { ReturnButton } from '../AccountCreation/styles';

const PresentationOutils = () => {
  const [isTyping, setIsTyping] = useState(true);
  const [text, setText] = useState('');
  const fullText = "Laisse moi te présenter mes 2 outils pour t'en sortir une fois à l'intérieur!";
  const navigate = useNavigate();
  const { startTutorial } = useTutorial();  // Add this hook

  useEffect(() => {
    let currentIndex = 0;
    const typingInterval = setInterval(() => {
      if (currentIndex <= fullText.length) {
        setText(fullText.slice(0, currentIndex));
        currentIndex++;
      } else {
        setIsTyping(false);
        clearInterval(typingInterval);
      }
    }, 15);

    return () => clearInterval(typingInterval);
  }, []);

  const handleBack = () => {
    navigate('/message-pret');
  };

  const handleCreateAccount = () => {
    // Initialize tutorial state and start tutorial
    sessionStorage.setItem('tutorialState', JSON.stringify({
      isActive: true,
      step: 0,
      from: '/presentation-outils'
    }));
    
    startTutorial();  // This will set isTutorialActive to true and handle navigation
  };

  return (
    <Container>
      <ContentWrapper>
        <ReturnButton onClick={handleBack}>
          <IoIosArrowBack />
        </ReturnButton>

        <ChatBubbleContainer>
          <ChatText isVisible={text.length > 0}>
            {text}
          </ChatText>
        </ChatBubbleContainer>

        <LogoImage src={compassImage} alt="Compass Logo" />
        
        <PrimaryButton 
          onClick={handleCreateAccount} 
          disabled={isTyping}
          style={{
            opacity: isTyping ? 0.5 : 1,
            cursor: isTyping ? 'not-allowed' : 'pointer'
          }}
        >
          Je suis prêt(e)
        </PrimaryButton>
      </ContentWrapper>
    </Container>
  );
};

export default PresentationOutils;