import styled from 'styled-components';

export const PopupOverlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(51, 51, 51, 0.7);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 9999; /* Increased z-index to ensure it's above other elements */
`;

// Add a new wrapper component that will handle the body scroll lock
export const PopupWrapper = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: ${props => props.isOpen ? 'block' : 'none'};
  pointer-events: ${props => props.isOpen ? 'auto' : 'none'};
  z-index: 9998; /* Just below the overlay */
`;

export const PopupContent = styled.div`
  background-color: ${props => props.theme.colors.background};
  border-radius: 8px;
  padding: ${props => props.theme.spacing.large};
  max-width: 400px;
  width: 90%;
  text-align: center;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  border: 2px solid ${props => props.theme.colors.primary};
  position: relative;
  z-index: 10000; /* Above the overlay */
`;

export const PopupText = styled.p`
  color: ${props => props.theme.colors.noir};
  font-family: ${props => props.theme.fonts.main};
  font-size: ${props => props.theme.fontSizes.large};
  margin-bottom: ${props => props.theme.spacing.large};
`;

export const ButtonContainer = styled.div`
  display: flex;
  justify-content: space-between;
  gap: 12px;
  margin-top: ${props => props.theme.spacing.medium};
`;

export const PopupButton = styled.button`
  flex: 1;
  background-color: ${props => props.secondary ? props.theme.colors.background : props.theme.colors.primary};
  color: ${props => props.secondary ? props.theme.colors.primary : props.theme.colors.lightText};
  border: ${props => props.secondary ? `2px solid ${props.theme.colors.primary}` : 'none'};
  border-radius: 4px;
  padding: ${props => props.theme.spacing.small} ${props => props.theme.spacing.large};
  font-family: ${props => props.theme.fonts.main};
  font-size: ${props => props.theme.fontSizes.medium};
  font-weight: ${props => props.theme.fontWeights.semiBold};
  cursor: pointer;
  transition: all 0.2s ease;

  &:hover {
    background-color: ${props => props.secondary ? props.theme.colors.primary : props.theme.colors.secondary};
    color: ${props => props.secondary ? props.theme.colors.lightText : props.theme.colors.lightText};
  }
`;
