
// src/components/LoadingStyles.js
import styled, { keyframes } from 'styled-components';

const pulseAnimation = keyframes`
  0% {
    width: 50px;
    opacity: 0.4;
  }
  50% {
    width: 200px;
    opacity: 1;
  }
  100% {
    width: 50px;
    opacity: 0.4;
  }
`;

// In LoadingStyles.js, you could add:
export const LoadingContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: ${props => props.isMobile ?
    'calc(100vh - var(--bottom-menu-height, 60px))' :
    '100vh'
  };
  background-color: ${props => props.theme.colors?.background || '#f5f5f5'};
`;

export const LoadingBar = styled.div`
  height: 4px;
  background-color: ${props => props.theme.colors?.primary || '#2196f3'};
  border-radius: 2px;
  animation: ${pulseAnimation} 1.5s ease-in-out infinite;
`;